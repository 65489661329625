// import { http, CancelToken } from "@/libs/axios.js";
import Vue from "vue";
// import { resolve } from "core-js/fn/promise";

const wikiApi = {
  BrandHome: "wiki/cruise/home",
  CruiseHome: "wiki/community/home", // 频道首页
  RankList: "wiki/cruise/sort", // 排行榜
  CruiseSearch: "wiki/cruise/search", // 邮轮频道首页搜索
  CruiseCompanyDetail: "wiki/cruise/company", // 邮轮公司详情
  CruiseDetail: "wiki/cruise/info", // 邮轮详情
  Hot24List: "wiki/community/hot-24hour",
  HotSearchTop: "wiki/community/hot-search",
  InformationList: "wiki/community/information",
  SearchCommunity: "wiki/community/search",
  SearchCruise: "wiki/community/search-cruise",
  SearchRec: "wiki/community/search-rec",
  ArticleDetail: "wiki/community/detail",
  ArticleOriginalDetail: "wiki/community/original-detail",
  Config: "wiki/community/conf",
  recordNum: "wiki/community/record-num",
  aisInfo:"wiki/cruise/ais",

  // cruise map 公司列表
  companyList: "wiki/ais/company-list",
  baseCruiseList: 'wiki/ais/base-cruise-list',
  asiSearch: "wiki/ais/search",
  aisCruise: "wiki/ais/cruise",
  aisCruiseTrajectory: "wiki/ais/cruise-trajectory",


  // 邮轮列表
  aisCruiseList: 'wiki/ais/cruise-list',
  aisCruiseCollectionList: "wiki/ais/collection-list",
  aisCollectionStatus: 'wiki/ais/collection-status',
  cruiseFilter: 'wiki/ais/cruise-aggregate',
  cruiseShare: 'wiki/cruise/share',
  cruiseDecks: "/wiki/cruise/decks",
  cruiseIntroduce: '/wiki/cruise/cruise-introduce',
  cruiseImages: '/wiki/cruise/images',

  // /wiki/cruise/cruise-type
  cruiseType: '/wiki/cruise/cruise-type', // 邮轮获取邮轮分类
  rankingList: '/wiki/cruise/ranking-list', // 邮轮获取邮轮列表

  requirementsAgreements: "/wiki/cruise/company-travel-security-list", // 出行要求和安全协议
  requirementsAgreementsDetail: "/wiki/cruise/company-travel-security-info", // 出行要求和安全协议详情

  notice:'/wiki/ais/notice',//邮轮综合、品牌须知

  allCompany: '/pc/brand/company-home', // 所有邮轮公司
  companyDetail: '/pc/brand/company-detail',
  company_cruise: '/pc/company/list',

  informationIndex:'/pc/information/index',//邮轮资讯（新版）
  infoClassify:'/wiki/community/information-classify',//邮轮资讯分类

};

export function getBrandHome() {
  return Vue.$axios.$get(wikiApi.BrandHome);
}

// 获取邮轮公司数据 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000616
export function getCruiseHome() {
  return Vue.$axios.$get(wikiApi.CruiseHome);
}
// 获取排名数据 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000621
export function getCruiseRank(data) {
  return Vue.$axios.$post(wikiApi.RankList, {
    data
  });
}

// 获取排名数据 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000617
export function getCruiseByName(data) {
  getCruiseByName.source = Vue.$axios.CancelToken.source();
  return Vue.$axios
    .post(
      wikiApi.CruiseSearch, {
        data
      }, {
        cancelToken: getCruiseByName.source.token,
      }
    )
    .finally(() => {
      getCruiseByName.source = null;
    });
}

// 获取邮轮公司详情 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000618
export function getCruiseCompanyDetail(data = {}) {
  return Vue.$axios.$post(wikiApi.CruiseCompanyDetail, {
    data
  });
}

export function getCruiseDetailById(data = {}) {
  return Vue.$axios.$post(wikiApi.CruiseDetail, {
    data
  });
}

export function getHot24Top() {
  return Vue.$axios.$post(wikiApi.Hot24List);
}

export function getHotSearchTop() {
  return Vue.$axios.$post(wikiApi.HotSearchTop);
}

export function getInformationList(data) {
  return Vue.$axios.$post(wikiApi.InformationList, {
    data
  });
}

export function searchCommunity(data) {
  return Vue.$axios.$post(wikiApi.SearchCommunity, {
    data
  });
}

export function searchCruise(data) {
  return Vue.$axios.$post(wikiApi.SearchCruise, {
    data
  });
}

export function getSearchRec(data) {
  return Vue.$axios.$post(wikiApi.SearchRec, {
    data
  });
}

export function getArticleDetail(data) {
  return Vue.$axios.$get(wikiApi.ArticleDetail, {
    params: data
  });
}

export function getArticleOriginalDetail(data) {
  return Vue.$axios.$get(wikiApi.ArticleOriginalDetail, {
    params: data
  });
}

export function getConfig() {
  return Vue.$axios.$post(wikiApi.Config);
}

export function updateRecordNum(data) {
  return Vue.$axios.$post(wikiApi.recordNum, {
    data
  });
}

/**  @desc 获取邮轮公司数据(包含邮轮数据) @see http://yapi.shangchuanba.com/project/59/interface/api/5925 */
export function getCompanyList(data) {
  return Vue.$axios.$post(wikiApi.companyList, {
    data
  });
}

/** @desc 查询ais @see http://yapi.shangchuanba.com/project/59/interface/api/5927 */
export function searchAis(data) {
  return Vue.$axios.$post(wikiApi.asiSearch, {
    data
  });
}


/** @desc 邮轮详情ais @see http://yapi.shangchuanba.com/project/59/interface/api/5929  */
export function getAisCruise(data) {
  return Vue.$axios.$post(wikiApi.aisCruise, {
    data
  });
}


/** @desc 邮轮详情ais @see http://yapi.shangchuanba.com/project/59/interface/api/5929  */
export function getAisCruiseTrajectory(data) {
  return Vue.$axios.$post(wikiApi.aisCruiseTrajectory, {
    data
  });
}


/** @desc 邮轮筛选列表 @see  http://yapi.shangchuanba.com/project/59/interface/api/6227 */
export function getAisCruiseList(data) {
  return Vue.$axios.$post(wikiApi.aisCruiseList, {
    data
  });
}

/** @desc 过去邮轮收藏列表 @see http://yapi.shangchuanba.com/project/59/interface/api/6199 */
export function getAisCruiseCollectionList(data) {
  return Vue.$axios.$post(wikiApi.aisCruiseCollectionList, {
    data
  })
}

/** @desc 过去邮轮收藏列表 @see http://yapi.shangchuanba.com/project/59/interface/api/6191 */
export function updateAisCruiseCollectionStatus(data) {
  return Vue.$axios.$post(wikiApi.aisCollectionStatus, {
    data
  })
}

/**  @desc 带定位的邮轮 @see http://yapi.shangchuanba.com/project/59/interface/api/6279  */
export function getBaseCruiseList(data) {
  return Vue.$axios.$post(wikiApi.baseCruiseList, {
    data
  });
}


/** @desc cruiseFilter @see http://yapi.shangchuanba.com/project/59/interface/api/6179 */
export function getCruiseFilter() {
  return Vue.$axios.$post(wikiApi.cruiseFilter)
}

/** @desc getCruiseShare */
export function getCruiseShare(data) {
  return Vue.$axios.$post(wikiApi.cruiseShare, {
    data
  })
}
//邮轮获取甲板
export function getCruiseDecks(data) {
  return Vue.$axios.$post(wikiApi.cruiseDecks, {
    data
  });
}
//邮轮品牌获取
export function getCruiseIntroduce(data) {
  return Vue.$axios.$post(wikiApi.cruiseIntroduce, {
    data
  });
}
//邮轮获取图片库
export function getCruiseImages(data) {
  return Vue.$axios.$post(wikiApi.cruiseImages, {
    data
  });
}
//邮轮获取邮轮分类
export function getCruiseType(data) {
  return Vue.$axios.$post(wikiApi.cruiseType, {
    data
  });
}
//邮轮获取邮轮列表
export function getRankingList(data) {
  return Vue.$axios.$post(wikiApi.rankingList, {
    data
  });
}

//出行要求和安全协议
export function getRequirementsAgreements() {
  return Vue.$axios.$post(wikiApi.requirementsAgreements)
}
// 出行要求和安全协议详情
export function getRequirementsAgreementsDetail(data) {
  return Vue.$axios.$post(wikiApi.requirementsAgreementsDetail, {
    data
  })
}

export function getAllCompany() {
  return Vue.$axios.$get(wikiApi.allCompany)
}

export function getCompanyDetail(data) {
  return Vue.$axios.$post(wikiApi.companyDetail, {
    data
  })
}

export function getNotice(data){
  return Vue.$axios.$post(wikiApi.notice,{ data })
}

export function getCompanyAndProduct(){
  return Vue.$axios.$post(wikiApi.company_cruise)
}


/**
 * 
 * @param {*} data
 * @returns { Promise<import('~/types/axios').ResultData<import('./model/company').GetInformationIndexData>> } 
 */
export function informationIndex(){
  return Vue.$axios.$get(wikiApi.informationIndex)
}

export function getClassify(){
  return Vue.$axios.$post(wikiApi.infoClassify)
}
// 游轮详情页地图信息
export function getCruiseAisInfo(data = {}){
  return Vue.$axios.$post(wikiApi.aisInfo,{data})
}