<template>
  <div class="picture">
    <div class="picture__tip">
      <i class="ficon ficon-tanhaoshixin"></i>
      <!-- <div v-html="'单图：您可下载下来自己二次创作。<br/>店铺专属海报：海报上带有您店铺的专属二维码，客户扫码后可直接进入您的店铺进行查看产品详情、预订等操作。'"></div> -->
      <p>单图：您可下载下来自己二次创作</p>
    </div>
    <template v-if="productList.length">
      <div class="picture__wrapper">
        <ul>
          <li class="" v-for="(image, videoIndex) in productList" :key="videoIndex" @click.stop="showPreviewDialog(image.image_url)">
            <div class="picture__head">
              <el-image class="picture__cover" fit="cover" :src="image.image_url_thumbnail"></el-image>
              <div class="picture__btn__wrapper">
                <el-button class="btn btn__single" round type="primary" @click.stop="downloadByBlob(image.image_url, '单图' + (videoIndex + 1) + '.png')">单图下载</el-button>
                <el-button class="btn" round type="primary" v-if="image.is_poster" @click.stop="downloadQrcodePoster(image.image_url, image.id)">店铺专属海报下载</el-button>
                <!-- <el-button class="btn" round type="primary" @click="downloadQrcodePoster(image.image_url, image.id)">店铺专属海报下载</el-button> -->
              </div>
            </div>
            <p class="line-clamp-2 picture__name">{{image.name}}</p>
            <div class="picture__date">
              <i class="ficon ficon-shijian1" />
              <span>{{image.create_time}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="pagination__wrapper" v-if="pages.total_page > 1">
        <el-pagination :currentPage="pages.page_index" :total="pages.total_count" :pageSize="pages.page_size" @current-change="handleCurrentPageChange" />
      </div>
    </template>
    <el-empty v-if="!productLoading && !productList.length" description="暂无数据"></el-empty>
    <posterQrcode ref="posterQrcode"></posterQrcode>

    <!-- 详情两层遮罩   -->
    <el-dialog :modal="!productNo" width="500px" title="图片预览" :visible.sync="dialogVisible">
      <div class="preview__scroll">
        <el-image class="dialog_disabled" style="width:100%;" :src="previewImgUrl"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import posterQrcode from "./posterQrcode";
// apis
import { geMaterialImageList } from "@/services/material";
// const
import { REQUEST_CODES } from "@/constants/const-http";
// uses composables
import useRequest from "@/composables/useRequest";

import { downloadByBlob, downloadImg } from "@/utils/file-download.js";

function createDefaultPages() {
  return {
    total_count: 1,
    page_index: 1,
    page_size: 10,
    page_count: 1,
    total_page: 1,
  };
}

export default {
  name: "Materrialpicture",
  components: {
    posterQrcode,
  },
  // props: ["classifyId", "productNo", 'init'],
  props: {
    classifyId: {
      type: String,
      default: "",
    },
    productNo: {
      type: String,
      default: "",
    },
    fromMaterial: {
      // 初始化 请求数据
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      productList: [],
      searchData: {},
      pages: createDefaultPages(),
      productLoading: true,

      // 图片放大预览
      previewImgUrl: "",
      dialogVisible: false,
    };
  },
  mounted() {
    // if (this.init) {
    this.handleSearch();
    // }
  },
  //  素材中心
  watch: {
    classifyId(newValue, oldVal) {
      if (newValue) {
        this.handleSearch();
      }
    },
  },
  methods: {
    showPreviewDialog(url) {
      this.dialogVisible = true;
      this.previewImgUrl = url;
    },
    async downloadQrcodePoster(img, id) {
      this.$refs.posterQrcode.download({ img, id });
    },
    downloadByBlob,
    async handleSearch() {
      // 处理素材中心页码inn 切换问题 todo
      if (this.fromMaterial && !this.classifyId) {
        return;
      }

      this.productLoading = true;
      let searchResult = await this._getProductList();
      this.productLoading = false;

      if (!searchResult.list.length) {
        this.noSearch = true;
        // return
      } else {
        this.noSearch = false;
      }
      this.setSearchResult(searchResult);
    },
    async _getProductList() {
      let { code, data, msg } = await geMaterialImageList({
        classify_id: this.classifyId,
        product_no: this.productNo,
        page_index: this.pages.page_index,
        page_size: this.productNo ? 8 : this.pages.page_size,
      });

      if (code !== REQUEST_CODES.ERROR_OK) {
        this.$message.error(msg);
        return { list: [], ...createDefaultPages() };
      }
      return Promise.resolve(data);
    },
    async handleCurrentPageChange(page_index) {
      this.pages.page_index = page_index;
      this.productLoading = true;
      let result = await this._getProductList();
      this.productLoading = false;
      this.setSearchResult(result);
    },
    setSearchResult(data = {}) {
      let {
        page_count,
        page_index,
        page_size,
        total_count,
        total_page,
        list = [],
      } = data;
      Object.assign(this, {
        pages: {
          page_count,
          page_index,
          page_size,
          total_count,
          total_page,
        },
        productList: list,
        // productList: [...list, ...list, ...list, ...list],
      });
    },
  },
};
</script>
<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.picture {
  &__tip {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    background-color: #f6f6f6;
    color: #868e96;
    font-size: 14px;
    margin-bottom: 30px;
    .ficon {
      margin-right: 12px;
    }
  }
  &__wrapper {
    width: 100%;
    overflow: hidden;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 1100px;
    }
    li {
      width: 194px;
      margin-right: 18px;
      margin-bottom: 40px;
      &:hover {
        .picture__btn__wrapper {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
  &__head {
    // width: 194px;
    width: 100%;
    height: 242px;
    position: relative;
    margin-bottom: 14px;
    border-radius: 6px;
    overflow: hidden;
  }
  &__cover {
    // width: 193px;
    width: 100%;
    height: 242px;
    font-size: 0;
  }
  &__name {
    color: #212529;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0 2px;
  }
  &__date {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    color: #adb5bd;
  }
  &__btn {
    &__wrapper {
      position: absolute;
      left: 0;
      bottom: 20px;
      padding: 0 20px;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      transition: 0.2s all;
      .btn {
        width: 100%;
        margin: 0;
        margin-top: 7px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        &__single {
          background-color: #fff;
          color: #f57c62;
        }
      }
    }
  }
}
.dialog_disabled {
  pointer-events: none;
  user-select: none;
  cursor: default;
}
.preview__scroll {
  max-height: 60vh;
  overflow-y: scroll;
}

</style>
