<template>
    <el-dialog :visible.sync="visible" title="选择页面" width="430px">
        <el-form :model="formData">
            <el-radio-group v-model="formData.value">
                <ul>
                    <li v-for="option in options" :key="option.value" class="mb-3">
                        <el-radio :value="option.value" :label="option.value">{{ option.label }}</el-radio>
                    </li>
                </ul>
            </el-radio-group>
        </el-form>
        <div slot="footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button type="primary" @click="onConfirm">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { computed, defineComponent, reactive, ref } from '@nuxtjs/composition-api';
import useMessage from '~/composables/useMessage';
export default defineComponent({
    name: 'DialogSelectCustomization',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        defaultData: {
            type: Object,
            default: () => ({
                value: ''
            })
        }
    },
    emits: ['cancel', 'confirm'],
    setup(props, { emit }) {
        const visible = ref(false);
        const formData = reactive({
            ...props.defaultData
        })

        const currentOption = computed(() => props.options.find(e => e.value === formData.value));
        function onCancel() {
            visible.value = false;
            emit('cancel')
        }
        const message = useMessage();
        function onConfirm() {

            if (formData.value) {
                emit('confirm', {
                    ...formData,
                    label: currentOption.value?.label
                })
            } else {
                message.error('请选择一个跳转页')
            }

        }

        function show() {
            visible.value = true;
            Object.assign(formData, props.defaultData);
        }
        function hide() {
            visible.value = false;
        }

        return {
            visible,
            formData,

            show,
            hide,

            onCancel,
            onConfirm
        }
    }
})
</script>

<style lang="scss" scoped>



</style>