<template>
  <div class="file">
    <template v-if="productList.length">
      <div class="file__wrapper">
        <ul>
          <li class="" v-for="(file, fileIdx) in productList" :key="fileIdx">
            <el-image class="file__icon" fit="contain" :src="_getFileIcon(file.file_type)"></el-image>
            <div class="file__info">
              <p class="file__name">{{file.name}}</p>
              <span class="file__date">{{file.create_time}}</span>
            </div>
            <el-button plain round @click="downloadVideo(file.file_url)">下载文件</el-button>
          </li>
        </ul>
      </div>
      <div class="pagination__wrapper" v-if="pages.total_page > 1">
        <el-pagination :currentPage="pages.page_index" :total="pages.total_count" :pageSize="pages.page_size" @current-change="handleCurrentPageChange" />
      </div>
    </template>
    <el-empty v-if="!productLoading && !productList.length" description="暂无数据"></el-empty>
  </div>
</template>

<script>
// import VPlayer from "@/components/VPlayer/index.vue";
// apis
import { geMaterialFileList } from "@/services/material";
// const
import { REQUEST_CODES } from "@/constants/const-http";
// uses composables
import useRequest from "@/composables/useRequest";

// utils
import { downloadVideo } from "@/utils/file-download.js";

function createDefaultPages() {
  return {
    total_count: 1,
    page_index: 1,
    page_size: 10,
    page_count: 1,
    total_page: 1,
  };
}

export default {
  name: "MaterrialFile",
  components: {
    // VPlayer,
  },
  props: ["classifyId", 'productNo'],
  data() {
    return {
      productList: [],
      searchData: {},
      pages: createDefaultPages(),
      productLoading: true,
    };
  },
  mounted() {
    this.handleSearch();
  },
  watch: {
    classifyId(value) {
      this.handleSearch();
    },
    productNo(no) {
      no && this.handleSearch();
    },
  },
  methods: {
    downloadVideo,
    async handleSearch() {
      this.productLoading = true;
      let searchResult = await this._getProductList();
      this.productLoading = false;

      if (!searchResult.list.length) {
        this.noSearch = true;
        // return
      } else {
        this.noSearch = false;
      }
      this.setSearchResult(searchResult);
    },
    async _getProductList() {
      let { code, data, msg } = await geMaterialFileList({
        classify_id: this.classifyId,
        product_no: this.productNo,
        page_index: this.pages.page_index,
        page_size: this.pages.page_size,
      });

      if (code !== REQUEST_CODES.ERROR_OK) {
        this.$message.error(msg);
        return { list: [], ...createDefaultPages() };
      }
      return Promise.resolve(data);
    },
    async handleCurrentPageChange(page_index) {
      this.pages.page_index = page_index;
      this.productLoading = true;
      let result = await this._getProductList();
      this.productLoading = false;
      this.setSearchResult(result);
    },
    setSearchResult(data = {}) {
      console.log(data);
      let {
        page_count,
        page_index,
        page_size,
        total_count,
        total_page,
        list = [],
      } = data;
      Object.assign(this, {
        pages: {
          page_count,
          page_index,
          page_size,
          total_count,
          total_page,
        },
        productList: list,
      });
    },
    _getFileIcon(file_type) {
      // 文件类型 0-其他 1-pdf 2-word 3-excel 4-ppt
      return {
        1: require("@/assets/img/resource/icon_file_p.png"),
        2: require("@/assets/img/resource/icon_file_w.png"),
        3: require("@/assets/img/resource/icon_file_x.png"),
        4: require("@/assets/img/resource/icon_file_p.png"),
      }[file_type];
    },
  },
};
</script>
<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.file {
  &__wrapper {
    width: 100%;
    overflow: hidden;
    ul {
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      &:not(:last-child) {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 80%;
          border-bottom: 1px solid #f2f2f2;
          right: 20px;
          bottom: 0;
        }
      }
    }
  }
  &__icon {
    width: 30px;
    height: 30px;
  }
  &__info {
    margin-left: 20px;
    margin-right: 50px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  &__name {
    flex: 1;
    margin-right: 18px;
    color: #212529;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__date {
    color: #adb5bd;
    font-size: 14px;
  }
}

</style>
