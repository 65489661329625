<template>
  <div>
    <el-dialog title="行程美图" :visible.sync="dialogVisible" width="800px">
      <div class="thumb-example">
        <!-- swiper1 -->
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
          <swiper-slide v-for="img in imgs" :key="img.path">
            <el-image class="w-full h-full" fit="cover" :src="img.path" />
          </swiper-slide>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </swiper>
        <!-- swiper2 Thumbs -->
        <div class="py-[15px]">
          <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
            <swiper-slide v-for="img in imgs" :key="img.path_thumb">
              <img class="w-full h-full object-cover" fit="cover" :src="img.path_thumb" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'DetailTourImgDIalog',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    imgs: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 15,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        initialSlide: this.activeIndex
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 15,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        initialSlide: this.activeIndex
        // normalizeSlideIndex: false, 
        // centerInsufficientSlides: true
      },
      dialogVisible: false
    }
  },
  mounted() {
    setTimeout(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      console.log(swiperTop)
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    }, 30)
  },
  methods: {
    show() {
      this.dialogVisible = true
    }
  }
})
</script>

<style lang="scss" scoped>

.thumb-example {
  // height: 508px;
  // background-color: $black;
  // background-color: rgba(22, 22, 22, 0.5);
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;

    &.slide-1 {
      background-image: url('~assets/img/topic/2021/yangzi-explorer/banner/img.png');
    }

    &.slide-2 {
      background-image: url('~assets/img/topic/2021/yangzi-explorer/banner/code-btn.png');

    }

    &.slide-3 {
      background-image: url('~assets/img/topic/2021/yangzi-explorer/banner/btn.png');
    }

    &.slide-4 {
      background-image: url('https://static.shangchuanba.com/scb/202102/Wmpkc3hhWWh0cWYwSTcxQ3o1TkQxNjEyNzU1OTI0.jpg_104x104q100.jpg');
    }

    &.slide-5 {
      background-image: url('https://static.shangchuanba.com/scb/202102/Wmpkc3hhWWh0cWYwSTcxQ3o1TkQxNjEyNzU1OTI0.jpg_326x326q100.jpg');
    }
  }

  &.gallery-top {
    width: 100%;
    height: 400px;
  }

  &.gallery-thumbs {
    height: 120px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    // padding: 10px 0;
  }

  &.gallery-thumbs .swiper-slide {
    // width: 25%;
    width: 180px;
    height: 120px;
    opacity: 0.4;
  }

  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

</style>