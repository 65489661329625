<template>
  <VFloatButtonGroup>
    <client-only>
      <template v-if="isLoggedIn && isDistributor">
        <VFloatButton
          isFirst
          tag="nuxt-link"
          icon="ficon ficon-dianshangjiashangcheng-xianxing"
          title="商家中心"
          to="/resource"
          icon_font_size="text-[24px]"
        />
        <VFloatButton
          tag="nuxt-link"
          icon="ficon ficon-dingdan"
          title="订单管理"
          to="/resource/order/platform"
        />
      </template>
      <VFloatButton
        :isFirst="!(isLoggedIn && isDistributor)"
        tag="nuxt-link"
        icon="ficon ficon-dingzhi"
        title="定制团"
        to="/product/custom-travel"
      />
      <VFloatButton
        icon="ficon ficon-lianxiwomen"
        title="联系我们"
        @click="onClickContact"
      />
      <VFloatButton
        tag="nuxt-link"
        icon="ficon ficon-a-dingdanzhongxin1"
        title="帮助中心"
        to="/helpcenter"
      />
      <VFloatButton
        icon="ficon ficon-erweima2"
        title="二维码"
        ref="qrCodeElement"
        :popoverProps="{
          popperClass: $style.qrcode_popover,
          trigger: 'hover',
          placement: 'left',
        }"
      >
        <template #popover>
          <GQrcodeTabs />
        </template>
      </VFloatButton>
      <VFloatButtonBackTop />
    </client-only>
  </VFloatButtonGroup>
</template>

<script>
// composables
import { computed, defineComponent, useContext, ref, onMounted, nextTick } from "@nuxtjs/composition-api";
import { useContactStore } from "~/composables/useContactDialog";
import { useGQrcodeStore } from "~/composables/useGQrcode"

// GQrcodeTabs
import GQrcodeTabs from "~/components/GQrcodeTabs.vue";
import {
  VFloatButton,
  VFloatButtonGroup,
  VFloatButtonBackTop,
} from "~/components/VFloatButton";

import { trackEvent } from "@/utils/track-event";

export default defineComponent({
  name: "AppDock",
  components: {
    GQrcodeTabs,
    VFloatButtonGroup,
    VFloatButton,
    VFloatButtonBackTop,
  },
  setup() {
    const { store } = useContext();
    const isLoggedIn = computed(() => store.getters.loggedIn);
    const isDistributor = computed(() => store.getters.isDistributor);

    // ======================= 联系人 =======================
    const { open: openContact } = useContactStore();
    const { getElementTop } = useGQrcodeStore()

    const qrCodeElement = ref(null)

    const onClickContact = () => {
      trackEvent("3");

      openContact(true);
    };
    onMounted(() => {
      nextTick(() => {
        if(qrCodeElement.value) {
          const rect = qrCodeElement.value.getBoundingClientRect()
          getElementTop(rect.top)
        }
      })
    })
    return {
      isLoggedIn,
      isDistributor,
      openContact,
      onClickContact,
      qrCodeElement
    };
  },
});
</script>
<style module lang="scss">
.qrcode_popover {
  padding: 0px;
}
</style>
