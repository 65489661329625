<template>
  <div class="materialDialog">
    <el-dialog id="materialDialog" destroy-on-close title="产品素材" width="770px" :visible.sync="visible">
      <MaterrialFileMain :exists_file="exists_file" :exists_image="exists_image" :exists_video="exists_video" :productNo="productNo" />
    </el-dialog>
  </div>
</template>

<script>
import MaterrialFileMain from "views/material/MaterrialFileMain.vue";

export default {
  name: "MaterialDialog",
  components: {
    MaterrialFileMain,
  },
  props: {
    productNo: {
      type: String,
      default: "P21121616210191",
    },
    exists_file: {
      type: Boolean,
      default: false,
    },
    exists_image: {
      type: Boolean,
      default: false,
    },
    exists_video: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.visible = true;
    // }, 1000);
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.materialDialog {
  ::v-deep {
    .el-dialog {
      min-height: 432px;
    }
    .picture__wrapper ul {
      width: 800px;
      li {
        width: 168px;
      }
    }
    .video__wrapper ul {
      width: 800px;
      li {
        width: 229px;
      }
    }
  }
}

</style>
