<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors, classes }"
    v-bind="$attrs"
    slim
  >
    <div class="form-item" :class="classes">
      <label
        v-if="label || $slots.label"
        class="form-item-label"
        :style="labelStyle"
      >
        <slot name="label">{{ label }}</slot>
      </label>
      <div class="form-item-content" :style="contentStyle">
        <slot></slot>
      </div>
      <transition enter-active-class="animate__animated animate__fadeIn">
        <span
          v-if="errors.length"
          class="form-item-error"
          :style="contentStyle"
          >{{ errors[0] }}</span
        >
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
// import FormItem from "@/components/VForm/form-item.vue";
import { ValidationProvider } from "@/libs/veeValidate/index";

export default {
  name: "VFormItem",
  components: { ValidationProvider },
  props: {
    label: {
      type: String,
      default: ""
    },
    labelWidth: String
  },
  data() {
    return {
      $_provider: null
    };
  },
  computed: {
    isRequired() {
      return this.required;
    },
    // classes() {
    //   return {
    //     "is-required": this.isRequired
    //   };
    // },
    labelStyle() {
      return {
        width: this.labelWidth || "auto"
      };
    },
    contentStyle() {
      return {
        marginLeft: this.labelWidth || "auto"
      };
    }
  },
  created() {
    this.$on("on-validate", this.validate);
  },
  updated() {
    this.$_provider = this.$refs.provider;
  },
  methods: {
    validate() {
      this.$refs.provider.validate();
    }
  }
};
</script>

<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.form-item {
  position: relative;
  margin-bottom: 20px;
  $label-width: 50px;
  $line-height: 40px;
  @include clearfix;
  &-label {
    float: left;
    color: $important-text-color;
    line-height: $line-height;
    text-align: right;
    padding-right: 12px;
  }
  &-content {
    margin-left: $label-width;
    line-height: $line-height;
  }
  &-error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-left: $label-width;
    color: $theme-color;
    line-height: 1.4;
  }

  &.is-required {
    .form-item-label {
      &::before {
        content: "*";
        color: $theme-color;
        margin-right: 4px;
      }
    }
  }

  &.is-error {
    ::v-deep .v-input .v-input__inner {
      border-color: $theme-color !important;
    }
  }
}

</style>
