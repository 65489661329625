<template>
  <el-dialog title="选择产品" :visible.sync="dialogVisible" width="700px" @close="$emit('close')">
    <div class="">
      <div class="search">
        <div>
          <el-form inline>
            <el-form-item label="产品名称" label-width="5em">
              <el-input v-model="form.name" placeholder="请输入产品名称" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="产品分类" label-width="5em">
              <el-select v-model="form.product_type" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-button type="primary" @click="onSearch">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="tableData" style="width: 100%" @row-click="onRowClick">
            <el-table-column prop="name" label="产品名称"></el-table-column>
            <el-table-column prop="type_label" label="分类" width="100"></el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-radio-group v-model="currentIndex">
                  <el-radio :label="scope.row.product_no"><i></i></el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div></div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <el-pagination v-show="form.total_page > 1" background layout="prev, pager, next" :current-page.sync="form.page_index" :page-count="form.total_page" @current-change="_getShopProductList"></el-pagination>
      </div>
      <div>
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import usePagination from "@/composables/usePagination";
import { REQUEST_CODES } from "@/constants/const-http.js";

import { getShopProductList } from "@/services/user";

class ClassPrimise {
  constructor() {
    this.ret = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  resolve(data) {
    this.resolve(data);
  }
  reject(data) {
    this.reject(data);
  }
}

export default {
  name: "DialogSelectProduct",
  components: {},
  props: ["default"],
  computed: {},
  data() {
    return {
      form: {
        product_type: "",
        name: "",
        page_index: 1,
        total_page: 1,
      },
      options: [
        { value: "1", label: "邮轮" },
        { value: "2", label: "旅游度假" },
      ],
      tableData: [],
      dialogVisible: false,
      currentIndex: null,
      retPromise: "",
    };
  },
  watch: {
    "form.page_index": function () {
      this.currentIndex = null;
    },
  },
  created() {
    // this.retPromise = new ClassPrimise();
  },
  mounted() {
    this.currentIndex = this.default
    // this.selecteProduct().then((res) => {
    //   console.log(res, '测试');
    // });
    this._getShopProductList();
  },
  methods: {
    onRowClick(row) {
      this.currentIndex = row.product_no
    },
    show() {
      this.dialogVisible = true;
    },
    onSearch() {
      this._getShopProductList();
    },
    async _getShopProductList() {
      const { code, data, msg } = await getShopProductList({
        ...this.form,
      });
      if (code !== REQUEST_CODES.ERROR_OK) {
        throw new Error(msg);
      }
      this.tableData = data.list;
      this.form.total_page = data.total_page;
    },
    selecteProduct() {
      //   this.form = {
      //     product_type: "",
      //     name: "",
      //     page_index: 1,
      //     total_page: 1,
      //   };
      this.currentIndex = null;
      // 初始化

      this.retPromise = new ClassPrimise();
      this.dialogVisible = true;
      return this.retPromise.ret;
    },
    onConfirm() {
      const confirmData = this.tableData.find(item => item.product_no == this.currentIndex)
      if (!confirmData) {
        return this.$message.error("请选择一个产品");
      }
      this.$emit("confirm", confirmData);
      // this.retPromise.resolve(this.tableData[this.currentIndex]);
      this.dialogVisible = false;
      this.retPromise = null;
    },
    onCancel() {
      this.dialogVisible = false;
      this.retPromise && this.retPromise.reject();
      // this.$emit("close");
      this.$emit('cancel')
    },
  },
};
</script>
<style lang="scss" scoped>

.product {
  padding: 14px;
  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #2b2b36;
    margin-bottom: 12px;
  }
}
.placeholder {
  &__item {
    width: 50%;
    padding: 0 5px;
    display: inline-block;
    vertical-align: top;
    &__img {
      height: 164px;
      background-color: #e9ebf3;
      border-radius: 4px;
      margin-bottom: 13px;
    }
    &__name {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
    &__price {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>