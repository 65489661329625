import Vue from "vue"

const API = {
    catalog: 'member/qr-code/classify-list',
    list: "member/qr-code/list",
    catalogEdit: 'member/qr-code/add-classify',
    deleteCatalog: 'member/qr-code/del-classify',
    moveCatalog: "member/qr-code/move-classify",

    previewQrcode: 'member/qr-code/preview-qrcode',
    deleteQrcode: 'member/qr-code/del',
    moveQrcode: 'member/qr-code/qrcode-move-classify',
    qrcodeInfo: 'member/qr-code/info',
    qrcodeLogoList: "member/qr-code/logo-list",
    createQrcode: 'member/qr-code/save',


    materialProductqr: "member/material/product-qr", //二维码
}


export function getCatlog(data) {
    return Vue.$axios.$post(API.catalog, { data })
}


export function getList(data) {
    return Vue.$axios.$post(API.list, { data })
}



export function updateCatlog(data) {
    return Vue.$axios.$post(API.catalogEdit, { data })
}


export function removeCatalog(data) {
    return Vue.$axios.$post(API.deleteCatalog, { data })
}


export function removeQrcode(data) {
    return Vue.$axios.$post(API.deleteQrcode, { data });
}


export function moveQrcode(data) {
    return Vue.$axios.$post(API.moveQrcode, { data });
}

export function createQrcode(data) {
    return Vue.$axios.$post(API.createQrcode, { data })
}

export function moveCatalog(data) {
    return Vue.$axios.$post(API.moveCatalog, { data });
}


export function getPreviewQrcodeImg(data) {
    return Vue.$axios.$post(API.previewQrcode, { data });
}

export function getQrcodeInfo(data) {
    return Vue.$axios.$post(API.qrcodeInfo, { data });
}

export function getQrcodeLogos() {
    return Vue.$axios.$post(API.qrcodeLogoList)
}
export function getMaterialProductqr(data) {
    return Vue.$axios.$post(API.materialProductqr, {data})
}
