<template>
  <div class="materialMain">
    <ul class="materialMain__type">
      <li :class="{'active':material.code == material__type }" v-for="(material, index) in materials " :key="material.code" @click="material__type = material.code">
        <i class="ficon" :class="material.icon"></i>
        <span>{{material.name}}</span>
      </li>
    </ul>
    <div>
      <Materrialpicture :init="false" :productNo="productNo" :classifyId="classifyId" v-if="material__type == 'image'" />
      <MaterrialVideo :productNo="productNo" :classifyId="classifyId" v-if="material__type == 'video'" />
      <MaterrialFile :productNo="productNo" :classifyId="classifyId" v-if="material__type == 'file'" />
    </div>
  </div>
</template>

<script>
// !!! 该组件 用于 素材中心和 产品首页 素材展示

import MaterrialVideo from "views/material/MaterrialVideo.vue";
import Materrialpicture from "views/material/Materrialpicture.vue";
import MaterrialFile from "views/material/MaterrialFile.vue";

export default {
  name: "MaterrialFileMain",
  components: {
    MaterrialVideo,
    Materrialpicture,
    MaterrialFile,
  },
  props: {
    classifyId: {
      type: String,
      default: "",
    },
    productNo: {
      type: String,
      default: "",
    },
    exists_file: {
      type: Boolean,
      default: true,
    },
    exists_image: {
      type: Boolean,
      default: true,
    },
    exists_video: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    materials() {
      const ret = [];
      if (this.exists_image) {
        ret.push({
          code: "image",
          name: "图片",
          icon: "ficon-tupian",
        });
      }
      if (this.exists_video) {
        ret.push({
          code: "video",
          name: "视频",
          icon: "ficon-shipin",
        });
      }
      if (this.exists_file) {
        ret.push({
          code: "file",
          name: "产品文件",
          icon: "ficon-chanpinwenjian",
        });
      }
      return ret;
    },
  },
  data() {
    return {
      material__type: "image",
    };
  },
};
</script>
<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.materialMain {
  padding: 0;
  &__type {
    display: flex;
    align-items: center;
    padding: 12px 0;
    li {
      display: inline-flex;
      margin-right: 25px;
      color: #495057;
      font-size: 14px;
      user-select: none;
      cursor: pointer;
      .ficon {
        margin-right: 5px;
      }
      &.active {
        color: $color-primary;
        .ficon {
          color: $color-primary;
        }
      }
    }
  }
}

</style>
