<template>
  <el-dialog
    :visible.sync="visible"
    v-bind="$props"
    @close="close"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
  >
    <slot />
    <template v-if="showAction" #footer>
      <slot name="footer">
        <el-button size="small" @click="close">取消</el-button>
        <el-button
          :loading="loading"
          type="primary"
          size="small"
          @click="onConfirm"
          >确定</el-button
        >
      </slot>
    </template>
  </el-dialog>
</template>
<script>
import { Dialog } from "element-ui";
import { omit } from "@/utils/helpers";
export default {
  name: "Dialog",
  props: {
    ...omit(Dialog.props, ["visible"]),
    width: {
      type: String,
      default: "540px"
    },
    showAction: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },

    close() {
      this.visible = false;
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm");
    },

    setLoading(visible) {
      this.loading = visible;
    }
  }
};
</script>
