<template>
  <a class="app-logo" href="/">
    <!-- <v-svg-icon class="app-logo__icon !w-full !h-full" name="shangchuanba" /> -->
      <img id="logo" class="app-logo-img" src="~@/assets/img/global/logo.png" alt="上船吧logo">
      <!-- <img id="logo" class="logo logo-active" src="~@/assets/img/global/logo__active.png" alt="logo"> -->
  </a>
</template>
<script>
export default {};
</script>
<style lang="scss">
.app-logo {
  display: block;
  font-size: 1.625rem;
  width: 120px;
  height: 48px;
}
.app-logo-img {
  width: 120px;
}
.app-logo__icon {
  fill: var(--header-theme);
}
</style>
