<template>
  <div :class="classes">
    <input
      v-if="type !== 'textarea'"
      ref="input"
      class="v-input__inner"
      :type="type"
      v-bind="$attrs"
      :disabled="disabled"
      :readonly="readonly"
      @compositionstart="handleCompositionStart"
      @compositionupdate="handleCompositionUpdate"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    />

    <textarea
      v-else
      class="v-textarea__inner"
      ref="textarea"
      v-bind="$attrs"
      :disabled="disabled"
      :readonly="readonly"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    ></textarea>
  </div>
</template>

<script>
// import createFormMixins from "@/mixins/form.js";
export default {
  name: "VInput",
  // mixins: [createFormMixins()],
  props: {
    value: [String, Number],

    disabled: Boolean,
    readonly: Boolean,
    type: {
      type: String,
      default: "text"
    },
    label: String
  },
  data() {
    return {
      hovering: false,
      focused: false,
      isComposing: false
    };
  },
  computed: {
    classes() {
      return [
        this.type === "textarea" ? "v-textarea" : "v-input",
        {
          "is-disabled": this.disabled
        }
      ];
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined
        ? ""
        : String(this.value);
    }
  },
  watch: {
    nativeInputValue() {
      this.setNativeInputValue();
    }
  },
  mounted() {
    this.setNativeInputValue();
  },
  methods: {
    focus() {
      this.getInput().focus();
    },
    blur() {
      this.getInput().blur();
    },
    select() {
      this.getInput().select();
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    handleCompositionStart() {
      this.isComposing = true;
    },
    handleCompositionUpdate() {
      // const text = event.target.value;
      // const lastCharacter = text[text.length - 1] || '';
      this.isComposing = true;
    },
    handleCompositionEnd(event) {
      if (this.isComposing) {
        this.isComposing = false;
        this.handleInput(event);
      }
    },
    handleInput(e) {
      if (this.isComposing) return;
      if (e.target.value === this.nativeInputValue) return;

      this.$emit("input", e.target.value, e);
      this.$nextTick(this.setNativeInputValue);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit("focus", event);
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit("blur", event);

      // if (this.formItem) {
      //   this.formItem.$emit("on-validate", this.value);
      // }
    },
    handleChange(event) {
      this.$emit("change", event.target.value);
    }
  }
};
</script>

<style>

</style>
