<template>
  <el-dialog :class="$style.dialog" :visible.sync="visible" width="374px" v-bind="$attrs" v-on="$listeners">
    <login-swiper @login-success="onLoginSuccess" @register-success="onLoginSuccess" />
  </el-dialog>
</template>
<script>
import { defineComponent, ref } from "@nuxtjs/composition-api";
import LoginSwiper from "../Login/login-swiper.vue";
export default defineComponent({
	name: "LoginDialog",
	components: {
		LoginSwiper
	},
	emits: ["success"],
	data: () => ({ visible: false }),
	methods: {
		onLoginSuccess(data) {
			console.log('on-login-success')
			this.$emit("success", data);
		},
		show() {
			this.visible = true;
		},
		hide() {
			this.visible = false;
		}
	}
});
</script>
<style lang="scss" module>
.dialog :global(.el-dialog) {
  background-color: transparent;
}
.dialog :global(.el-dialog__header) {
  display: none;
}
.dialog :global(.el-dialog__body) {
  padding: 0px;
}
</style>