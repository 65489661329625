import Vue from "vue";
import type {GetAddressCountryData, GetAddressCountryParam} from "~/services/model/address-country";
import type {GetAddressCityData, GetAddressCityParam} from "~/services/model/address-city";
import { GetIndexKfListData } from "./model";
import type { PostClickStatistics } from "./model/index-click-statistics";
import type { ResultData } from "~/types/axios";

export function postOCR(data: any) {
    return Vue.$axios.$post('/order/submit/ocr-card ', {
        data
    })
}

export function getCruiseFilter(data: any) {
    return Vue.$axios.$post('/pc/index/filter', {
        data: data
    })
}

/**
 * 获取搜索建议
 */
export function getSearchSuggestions(data: any, requestConfig: any) {
    return Vue.$axios.$post('/pc/index/search', {
        data
    }, requestConfig)
}

// 搜索
export function searchByKeyword(data: any) {
    return Vue.$axios.$post('/wiki/community/search-cruise', {
        data: data
    })
}


// 获取导航
export function getAppNavs() {
    return Vue.$axios.$get('/pc/index/navigation')
}


// 首页数据
export function getHomeData() {
    return Vue.$axios.$post('/pc/index/index')
}



export function gePlatformAdvertBanner(data: any) {
    return Vue.$axios.$post('/pc/operate-configure/banner', {data});
}

export function gePlatformAdvert(data: any){
    return Vue.$axios.$post('/pc/operate-configure/search-right', {data});
}


export function getAddressCountry(data: GetAddressCountryParam = {}){
    return Vue.$axios.$post<ResultData<GetAddressCountryData>>('/address/country', {data})
}

export function getAddressCity(data: GetAddressCityParam){
    return Vue.$axios.$post<ResultData<GetAddressCityData>>('/address/search-city', {data})
}



export function getIndexKFList() {
    return Vue.$axios.$post<ResultData<GetIndexKfListData>>('/pc/index/kf-list')
}

export function postClickStatistics(data: PostClickStatistics) {
    return Vue.$axios.$post<void>('/pc/index/click-statistics', {
        data
    })
}
// 公司介绍
export function getIntroduce() {
  return Vue.$axios.$post('/pc/company/introduce')
}
