<template>
  <el-dialog width="900px" title="视频预览" :visible.sync="dialogTableVisible">
    <VPlayer class="video_player" :src="src"></VPlayer>
  </el-dialog>
</template>
<script>
import VPlayer from "@/components/VPlayer/index.vue";

export default {
  name: "VideoDialog",
  components: {
    VPlayer,
  },
  props: ["src"],
  data() {
    return {
      dialogTableVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogTableVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>

.video_player {
  pointer-events: none;
}

</style>