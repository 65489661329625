import { ResultData } from "./../types/axios.d";
import type {
  GetProductCruiseFilterData,
  GetProductCruiseFilterParam,
  GetProductCruiseListParam,
  GetProductCruiseListData,
  GetProductCruiseDateParam,
  GetProductCruiseDateData,
} from "./model";
// import { http } from "@/libs/axios.js";
import Vue from "vue";

import type {
  GetCalendarFilterData,
  GetCalendarFilterParam,
  GetCalendarCruiseListData,
  GetCalendarCruiseListParam,
  GetCalendarDateListParam,
  GetCalendarDateListData,
} from "./model/calendar";

export const productApi = {
  productList: "product/product/cruise-list",
  productFilter: "product/product/cruise-filter",
  productDetail: "product/product/info", // 详情
  productDate: "product/product/date", // 航期
  productAllianceStock: "/product/product/alliance-date-stock",
  exportAllianceExcel: "/product/product/alliance-excel",
  productHot: "product/product/hot",
  productStock: "product/product/date-stock",
  collectionDynamics: "product/dynamic/add", // 动态收集
  cancelFavorites: "product/dynamic/edit",
  shareTravel: "product/product/travel-share",
  sharePorduct: "product/product/share",
  dataReport: "/product/product/data-report", // 数据上报
  shareShopPorduct: "/shop/manage/product-share", // 分销店铺和产品
  addShopProduct: "/shop/manage/product-add", //店铺增加产品
  getShopCruiseCompany: "/shop/manage/cruise-company", // 店铺管理-邮轮公司
  customTravel: "product/personal/custom-apply",
  cruiseList: "product/product/cruise-list", //资源采购-邮轮产品列表
  cruiseFilter: "product/product/cruise-filter", //资源采购-顶部筛选
  cruiseProductDates: "product/product/cruise-date", // 有行程的日期 列表页使用

  // 直客
  productCustomerHomeFilter: "product/home/filter",
  productCustomerList: "product/home/list",

  rightRec: "/pc/product/right-recommend",

  // 最 产品
  homeMostProduct: "product/home/most-product",
  // 待办事项 & 关键指标
  homePki: "product/home/pki",
  // 广告位 和 右侧信息
  homeAdSpace: "/product/home/ad-space",
  // 左侧导航
  homeLeftMenu: "/product/home/left-menu",
  // 首页留下你的联系方式
  homeSaveContact: "/product/home/leave-contact",
  homeMenu: "/product/home/menu",

  // 产品banner 共用
  bannerList: "/product/banner/list",

  productTripTour: "/product/product/trip-tour",

  // 返利政策
  productPolicy: "/product/product/policy",

  // 右下角 客服信息
  exclusiveCustomerService: "/product/home/exclusive-customer-service",

  //假日航线
  holiday: "/pc/product/holiday-cruises",
  //假日航线筛选器
  holidayFilter: "/pc/product/holiday-cruises-filter",
  //尾单特惠前3产品
  threeProduct: "/pc/operate-configure/last-order",
  //尾单特惠产品列表
  endOrderList: "/pc/operate-configure/last-order-list",
  //邮轮产品查看团期
  CruiseProductDate: "/pc/product/date",
  //线上余额充值
  balanceRecharge: "/product/personal/balance-recharge",
  //余额明细
  balanceRecord: "/product/personal/balance-record",
  //充值明细
  balanceRechargeRecord: "/product/personal/recharge-record",
  //充值详情
  rechargeDetail: "/product/personal/recharge-info",
  //营销素材
  material: "/product/product/material-friend",
  //学习专区
  study: "/product/personal/study",
  //学习专区分类
  studyProductType: "/product/personal/study-classify",
  //学习专区播放统计
  studyStatistics: "/product/personal/study-statistics",
  //分享数据上报
  shareReport : "/share/report"
};

/**
 * 数据上报 @see https://wiki.shangchuanba.com/pages/viewpage.action?pageId=2916516
 * @param {Object} data - 提交数据
 * @param {String} data.data_type - 数据上报类型
 * @returns {Promise}
 */
export function postDataReport(data: any) {
  return Vue.$axios.$post(productApi.dataReport, { data });
}

export function getProductList(data = {}) {
  return Vue.$axios.$post(productApi.productList, { data });
}
// 热门产品 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000733
export function getHotProduct(data = {}) {
  return Vue.$axios.$post(productApi.productHot, { data });
}

//
export const getProductBanner = (data = {}) => Vue.$axios.$post("", { data });

// 产品中心--首页
export const getProductHome = (data = {}) => {
  // console.log(Vue.$axios)
  return Vue.$axios.$post("product/home", { data });
};

// 产品中心--获取动态
export const getProductDynamicAllList = (data = {}) =>
  Vue.$axios.$post("product/dynamic/all-list", { data });

// 产品中心--获取我的浏览记录
export const getProductMyHistoryList = (data = {}) =>
  Vue.$axios.$post("product/dynamic/my-list", { data });

// 产品中心--删除浏览记录
export const DeleteproductMyHistory = (data = {}) =>
  Vue.$axios.$post("product/dynamic/del", { data });

// 产品中心--首页-筛选器
export const getProductHomeFilter = (data = {}) =>
  Vue.$axios.$post("product/home/home-filter", { data });

// 产品中心--Banner列表接口
export const getProductBannerList = (data = {}) =>
  Vue.$axios.$post("product/home/banner", { data });

export function getProductHomeBillboard(data = {}) {
  return Vue.$axios.$post("product/home/right", { data });
}

// 产品详情 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000661
export function getProductDetailById(data = {}) {
  return Vue.$axios.$post(productApi.productDetail, { data });
}

// 获取航期 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000662
export function getProductDateById(data = {}) {
  return Vue.$axios.$post(productApi.productDate, { data });
}

// 获取库存
export function getProductStock(data = {}) {
  return Vue.$axios.$post(productApi.productStock, { data });
}

// 获取联盟库存详细
export function getProductAllianceStock(data = {}) {
  return Vue.$axios.$post(productApi.productAllianceStock, { data });
}
// 导出联盟库存美女恭喜
export function exportProductAllianceStock(data = {}) {
  return Vue.$axios.$post(productApi.exportAllianceExcel, { data });
}

// 收集动态 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000632
export function collectionDynamicsById(data = {}) {
  return Vue.$axios.$post(productApi.collectionDynamics, { data });
}

export function addFavorites(product_no: string) {
  return collectionDynamicsById({
    product_no: product_no,
    record_type: 2,
  });
}
// 取消收藏 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000635
export function cancelFavorites(product_no: string) {
  return Vue.$axios.$post(productApi.cancelFavorites, { data: { product_no } });
}
// 行程图片分享 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000664
export function getTravelShareImgById(data = {}) {
  return Vue.$axios.$post(productApi.shareTravel, { data });
}
// 分享产品二维码 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000660
export function getProductShareImgById(data = {}) {
  return Vue.$axios.$post(productApi.sharePorduct, { data });
}
// 获取产品列表筛选器 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000658
export function getProductListFilter(data = {}) {
  return Vue.$axios.$post(productApi.productFilter, { data });
}

// 分享产品二维码
export function getProductShare(data = {}) {
  return Vue.$axios.$post(productApi.shareShopPorduct, { data });
}
// 添加产品
export function addShopProduct(data = {}) {
  return Vue.$axios.$post(productApi.addShopProduct, { data });
}

// get
export function submitCustomTravel(data = {}) {
  return Vue.$axios.$post(productApi.customTravel, { data });
}

// get
export function getShopCruiseCompany(data = {}) {
  return Vue.$axios.$post(productApi.getShopCruiseCompany, { data });
}

// S  直客
// http://yapi.shangchuanba.com/project/59/interface/api/5673
export function getProductCustomerFilter(data = {}) {
  return Vue.$axios.$post(productApi.productCustomerHomeFilter, { data });
}
export function getProductCustomerList(data = {}) {
  return Vue.$axios.$post(productApi.productCustomerList, { data });
}

//  最 产品
export function getHomeMostProduct(data = {}) {
  return Vue.$axios.$post(productApi.homeMostProduct, { data });
}
//
export function getHomePki(data = {}) {
  return Vue.$axios.$post(productApi.homePki, { data });
}
// / 广告位 和 右侧信息
export function geHomeAdSpace(data = {}) {
  return Vue.$axios.$post(productApi.homeAdSpace, { data });
}
// 获取左侧 导航
export function geHomeLeftMenu(data = {}) {
  return Vue.$axios.$post(productApi.homeLeftMenu, { data });
}
export function getHomeMenu(data = {}) {
  return Vue.$axios.$post(productApi.homeMenu, { data });
}
// 首页留下你的联系方式
export function homeSaveContact(data = {}) {
  return Vue.$axios.$post(productApi.homeSaveContact, { data });
}
//  产品banner 共用
export function getBannerList(data = {}) {
  return Vue.$axios.$post(productApi.bannerList, { data });
}
//  返利政策
export function getProductPolicy(data = {}) {
  return Vue.$axios.$post(productApi.productPolicy, { data });
}

//  右下角 客服信息
export function getExclusiveCustomerService() {
  return Vue.$axios.$post(productApi.exclusiveCustomerService, { data: {} });
}

export function getCruiseList(data: GetProductCruiseListParam) {
  return Vue.$axios.$post<ResultData<GetProductCruiseListData>>(
    productApi.cruiseList,
    { data }
  );
}

export function getCruiseFilter(data: GetProductCruiseFilterParam = {}) {
  return Vue.$axios.$post<ResultData<GetProductCruiseFilterData>>(
    productApi.cruiseFilter,
    { data }
  );
}

export function getProductCruiseDates(params: GetProductCruiseDateParam) {
  return Vue.$axios.$post<ResultData<GetProductCruiseDateData>>(
    productApi.cruiseProductDates
  );
}

export function getHolidayProducts(data = {}) {
  return Vue.$axios.$post(productApi.holiday, { data });
}

export function getHolidayFilter() {
  return Vue.$axios.$get(productApi.holidayFilter);
}

/**
 * @desc 右侧推荐产品获取
 * @param {object} data
 * @param { “22” | “23” | “24” } type - 22：邮轮搜索结果页 23: 线路搜索结果页 24: 全站搜索结果页
 * @returns
 */
export function getProductRightRec(data: any) {
  return Vue.$axios.$post(productApi.rightRec, { data });
}

export function getProductTripTour(data: any) {
  return Vue.$axios.$post(productApi.productTripTour, { data });
}

export function getThreeProduct(data: any) {
  return Vue.$axios.$post(productApi.threeProduct, { data });
}

export function getEndOrderList(data: any) {
  return Vue.$axios.$post(productApi.endOrderList, { data });
}

export function getCruiseProductDate(data: any) {
  return Vue.$axios.$post(productApi.CruiseProductDate, { data });
}

export function balanceRecharge(data: any) {
  return Vue.$axios.$post(productApi.balanceRecharge, { data });
}

export function balanceRecord(data: any) {
  return Vue.$axios.$post(productApi.balanceRecord, { data });
}

export function balanceRechargeRecord(data: any) {
  return Vue.$axios.$post(productApi.balanceRechargeRecord, { data });
}

export function rechargeDetail(data: any) {
  return Vue.$axios.$post(productApi.rechargeDetail, { data });
}

export function getMaterial(data: any) {
  return Vue.$axios.$post(productApi.material, { data });
}
export function studyInfo(data: any) {
  return Vue.$axios.$post(productApi.study, { data });
}

export function getStudyType() {
  return Vue.$axios.$post(productApi.studyProductType);
}

export function studyStatistics(data: any) {
  return Vue.$axios.$post(productApi.studyStatistics, { data });
}

export function getCalendarFilter(data: GetCalendarFilterParam) {
  return Vue.$axios.$post<ResultData<GetCalendarFilterData>>(
    "pc/calendar/filter",
    { data }
  );
}

export function getCalendarCruiseList(data: GetCalendarCruiseListParam) {
  return Vue.$axios.$post<ResultData<GetCalendarCruiseListData>>(
    "pc/calendar/cruise-list",
    { data }
  );
}

export function getCalendarDateList(data: GetCalendarDateListParam) {
  return Vue.$axios.$post<ResultData<GetCalendarDateListData>>(
    "pc/calendar/date-list",
    { data }
  );
}

export function shareReportData(data:{type:number;report_type:4|1;rel_id:string;sharing_code:string}){
  return Vue.$axios.$post(
    productApi.shareReport,
    {data}
  )
}