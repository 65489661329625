import { MESSAGE_TYPES, NOTIFY_GROUPS } from "@/constants/const-http";
import { omit } from "@/utils/helpers";
import Msg from "./main.vue";
import "./msg.scss";

/**
 * main.js(入口文件) 引入 Notifications
 *
 * App.vue 引入当前组件
 *
 * @example:
 * this.$notify({
 *      group: "msg",
 *      duration: -1, // 时长 负数表示永久展示  @eg: 1000
 *      type: 'warn', // warn success error info
 *      text: '测试'
 * })
 *
 * this.$message.error('错了错了')
 * this.$message.success('操作成功')
 * this.$message.info('信息提示')
 * this.$message.warn('警告')
 */

class Message {
  constructor(notify) {
    this.$notify = notify;
    this.group = NOTIFY_GROUPS.MSG;
  }
  show(config) {
    this.$notify({ group: this.group, ...omit(config, ["group"]) });
  }
  closeAll() {
    this.$notify({ group: this.group, clean: true });
  }
}

export function installMessage(Vue, notify) {
  const msgIntance = new Message(notify);
  function message(config = {}) {
    msgIntance.show(config);
  }
  Object.values(MESSAGE_TYPES).forEach(key => {
    message[key] = function(text) {
      msgIntance.show({
        type: key,
        text
      });
    };
  });
  Vue.prototype.$message = message;
}

export default Msg;
