import { getCompanyList, getBaseCruiseList, getCruiseFilter } from "@/services/company";
import { omit } from "lodash";
import { REQUEST_CODES } from "~/constants/const-http";
export const state = () => ({
    companyData: {
        cruise_num: [],
        cruise_company: [],
        cruise_class: [],
        total: 0
    },
    baseCruiseData: {
        cruise_class: [],
        cruise_list: []
    },

    filterData: {
        company: {
            hot: [],
            list: {}
        },
        cruise_class: [],
        continent: [],
        navistatus: [],
        tonnage: [],
        cruise_company: []
    },

    country: {
        continent_id:"3",
        continent_key:"3__东亚",
        id:"18",
        lat:"35.861660",
        lng:"104.195397",
        name:"中国"
    }
    // // 带定位 的邮轮
    // cruises: [],
    // company: [],
})


export const getters = {
    //  带定位邮轮
    cruises(state) {
        return state.baseCruiseData.cruise_list;
    },
    companys(state) {
        return state.companyData.cruise_company.map(item => omit(item, 'cruise'))
    },
    cruiseClasses(state) {
        return state.baseCruiseData.cruise_class
    }
}

export const mutations = {
    setCompanyData(state, data = {}) {
        state.companyData = data;
    },
    setBaseCruiseData(state, data) {
        state.baseCruiseData = data;
    },

    setFilterData(state, data) {
        state.filterData = data;
    },

    setCountry(state, country) {
        state.country = country;
    }
}

export const actions = {
    async fetchCompany({commit}) { 
        const { code, data, msg } = await getCompanyList();

        if(code !== REQUEST_CODES.ERROR_OK){
            throw new Error(msg);
        }

        commit('setCompanyData', data);
    },
    // 获取带定位的邮轮
    async fetchAisCruises({commit}, params) {
        const {code, data, msg} = await getBaseCruiseList(params);
        if(code !== REQUEST_CODES.ERROR_OK){
            throw new Error(msg);
        }

        commit('setBaseCruiseData', data);
    },

    async fetchFilterData({commit}) {
        const {code, data, msg} = await getCruiseFilter();
        if(code !== REQUEST_CODES.ERROR_OK){
            throw new Error(msg)
        }

        commit('setFilterData', data)
    }
}