<template>
  <div>
    <el-dialog width="900px" title="舱房详情" :visible.sync="dialogTableVisible">
      <div class="cabin">
        <div class="cabin__swiper">
          <el-carousel indicator-position="none" :interval="5000" arrow="always">
            <el-carousel-item v-for="imgItem in data.cabin.image_list" :key="imgItem">
              <el-image class="cabin__img" fit="cover" :src="imgItem"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="cabin__content">
          <h2 class="cabin__name">{{ data.cabin_name }}</h2>
          <ul class="cabin__info">
            <li class="cabin__info__item">
              <p class="cabin__info__label">舱房面积</p>
              <p class="cabin__info__value">{{ data.cabin.room_area }}</p>
            </li>
            <li class="cabin__info__item">
              <p class="cabin__info__label">必须入驻</p>
              <p class="cabin__info__value">{{ data.min_people }}人</p>
            </li>
            <li class="cabin__info__item">
              <p class="cabin__info__label">甲板层数</p>
              <p class="cabin__info__value">{{ data.cabin.floor }}层</p>
            </li>
            <li class="cabin__info__item">
              <p class="cabin__info__label">阳台</p>
              <p class="cabin__info__value">{{ data.cabin.window_label }}</p>
            </li>
          </ul>
          <p class="cabin__detail">{{ data.cabin.detail }}</p>
          <el-button class="cabin__btn" type="primary" @click="dialogTableVisible = false">舱房选择</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CabinDialog",
  props: ['data'],
  data() {
    return {
      dialogTableVisible: true,
      // data: {
      //   spd_id: 1433,
      //   cabin_id: 5252,
      //   cabin_name: "行政套房-6楼",
      //   min_people: 2,
      //   max_people: 4,
      //   bed_num: 20,
      //   room34_num: 20,
      //   last_stock: 20,
      //   best_adult: 2,
      //   max_adult: 2,
      //   is_share: 1,
      //   prices: {
      //     th: {
      //       sps_id: 4704,
      //       type: 2,
      //       adult_price: 1300,
      //       child_price: 1300,
      //       adult34_price: 0,
      //       child34_price: 0,
      //       adult_bed_price: 0,
      //       child_bed_price: 0,
      //       child_no_bed_price: 650,
      //       diff_room_price: 0,
      //     },
      //     sale: {
      //       sps_id: 4704,
      //       type: 3,
      //       adult_price: 1500,
      //       child_price: 1500,
      //       adult34_price: 0,
      //       child34_price: 0,
      //       adult_bed_price: 0,
      //       child_bed_price: 0,
      //       child_no_bed_price: 750,
      //       diff_room_price: 0,
      //     },
      //   },
      //   id: 4704,
      //   cabin: {
      //     id: 5252,
      //     room_type: "套房",
      //     scc_name: "套房",
      //     room_area: "33㎡",
      //     window: "4",
      //     window_area: "",
      //     window_label: "有阳台",
      //     images:
      //       "https://beta.static.shangchuanba.com/wiki/resources/202105/D58208F773F02310C30F6953336FD3AB.jpeg_652x652q100.jpg",
      //     floor: "6",
      //     floor_label: ["6"],
      //     desc: "房间配有：两张单人床（可合并成大床）、折叠沙发床（展开后相当于大床，可睡一人）、带淋浴的独立卫生间、梳妆台与吹风机、液晶电视、冰箱、保险箱、电热水壶、毛巾和私人阳台。",
      //     detail:
      //       "房间配有：两张单人床（可合并成大床）、折叠沙发床（展开后相当于大床，可睡一人）、带淋浴的独立卫生间、梳妆台与吹风机、液晶电视、冰箱、保险箱、电热水壶、毛巾和私人阳台。",
      //   },
      //   cabin_class_id: 27,
      //   hasDiffRoomPrice: false,
      //   canChildNoBed: true,
      //   canChildBed: false,
      //   _adultNum: 1,
      //   _adultBedNum: 0,
      //   _childNum: 0,
      //   _childBedNum: 0,
      //   _maxChildBedNum: 0,
      //   _roomNum: 0.5,
      //   _maxRoomNum: 0.5,
      //   _maxChildNum: 1,
      //   validResult: {
      //     valid: true,
      //     required: "__vue_devtool_undefined__",
      //     errors: [],
      //     failedRules: {},
      //     regenerateMap: {},
      //   },
      //   _roomNumStep: 0.5,
      //   canShareRoom: true,
      //   _totalRoomBedNum: 0,
      // },
    };
  },
  methods: {
    show() {
      this.dialogTableVisible = true
    },
  },
};
</script>

<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";

.cabin {
  display: flex;
  align-items: flex-start;

  ::v-deep {
    .el-carousel__arrow {
      background-color: rgba(0, 0, 0, .4);
    }
    .el-carousel__arrow:hover {
      background-color: rgba(0, 0, 0, .6);
    }
  }

  &__swiper {
    width: 400px;
    height: 300px;
    margin-right: 30px;

    .cabin__img {
      width: 400px;
      height: 300px;
    }
  }

  &__content {
    flex: 1;
  }

  &__name {
    color: #2b2b36;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__info {
    margin-bottom: 22px;

    &__item {
      width: 125px;
      margin-right: 50px;
      margin-bottom: 16px;
      display: inline-block;
      vertical-align: top;
    }

    &__label {
      color: #868e96;
      font-size: 15px;
    }

    &__value {
      color: #495057;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__detail {
    color: #495057;
    font-size: 14px;
    white-space: pre-wrap;
    max-height: 200px;
    overflow-y: auto;
  }

  &__btn {
    margin-top: 1em;
  }
}

</style>
