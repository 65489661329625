<template>
    <div :class="$style.qrcode">
          <ul :class="$style.qrcode__nav">
            <li :class="[$style.qrcode__nav__item, { [$style.qrcode__nav__item__active]: qrcodeNav == 'official'}]"  @click="qrcodeNav = 'official'">公众号</li>
            <li :class="[$style.qrcode__nav__item, { [$style.qrcode__nav__item__active]: qrcodeNav == 'miniapp'}]" @click="qrcodeNav = 'miniapp'">小程序</li>
            <li :class="[$style.qrcode__nav__item, { [$style.qrcode__nav__item__active]: qrcodeNav == 'video'}]" @click="qrcodeNav = 'video'">视频号</li>
          </ul>
          <div :class="$style.qrcode__code__wrapper">
            <transition enter-active-class="animate__animated animate__fadeIn">
              <el-image v-if="qrcodeNav == 'official'" :class="$style.qrcode__code" fit="contain" src="~@/assets/img/global/official_qrcode.jpg" />
            </transition>
            <transition enter-active-class="animate__animated animate__fadeIn">
              <el-image v-if="qrcodeNav == 'miniapp'" :class="$style.qrcode__code" fit="contain" src="~@/assets/img/global/mini_qrcode.jpg" />
            </transition>
            <transition enter-active-class="animate__animated animate__fadeIn">
              <el-image v-if="qrcodeNav == 'video'" :class="$style.qrcode__code" fit="contain" src="~@/assets/img/global/video_qrcode.jpg" />
            </transition>
          </div>
          <div :class="$style.qrcode__desc">{{ qrcodeNav == 'miniapp' ? '微信扫码查看' : '微信扫码关注' }}</div>
        </div>
</template>

<script>
import { defineComponent, ref } from "@nuxtjs/composition-api";

export default defineComponent({
    name: 'GQrcodeTabs',
    setup() {
        const qrcodeNav = ref('official');
        return {
            qrcodeNav
        }
    }
})
</script>

<style lang="scss" module>

.qrcode {
  width: 258px;
  padding-bottom: 38px;
  &__nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 28px;
    &__item {
      height: 42px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px 4px 0 0;
      transition: 0.2s all ease;
      user-select: none;
      cursor: pointer;
      color: #868e96;
      font-size: 16px;
      font-weight: 600;
      &__active {
        color: #fff;
        background: $color-primary;
      }
    }
  }
  &__code {
    width: 190px;
    height: 190px;
    display: block;
    &__wrapper {
      width: 190px;
      height: 190px;
      overflow: hidden;
      margin: 0 auto 16px;
    }
  }
  &__desc {
    color: #868e96;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
}

</style>