<template>
  <component
    class="v-btn"
    :class="classes"
    :is="tag"
    :disabled="disabled"
    :autofocus="autofocus"
    :type="nativeType"
    @click="handleClick"
  >
    <slot v-if="$slots.icon" name="icon" />
    <span v-if="$slots.default" class="v-btn__content"><slot></slot></span>
  </component>
</template>

<script>
export default {
  name: "v-btn",
  props: {
    type: {
      type: String, // 'info' | 'default' | 'primary'
      default: "default"
    },
    block: Boolean,
    disabled: Boolean,
    outlined: Boolean,
    plain: Boolean,
    tag: {
      type: String,
      default: "button"
    },
    text: Boolean,
    nativeType: {
      type: String,
      default: "button"
    },
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean,
    size: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    buttonSize() {
      return this.size;
    },
    /** @return {Object} */
    classes() {
      return [
        {
          "is-disabled": this.disabled,
          "is-block": this.block,
          "is-text": this.text,
          "is-round": this.round,
          "is-circle": this.circle,
          "is-outlined": this.outlined
        },
        [
          this.type ? `v-btn--${this.type}` : "",
          this.buttonSize ? `v-btn--${this.size}` : ""
        ]
      ];
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    }
  }
};
</script>
