<template>
    <div class="app-language">
        <img class="app-language-icon" src="~@/assets/img/china-flag.png" alt="国旗">
        <span class="app-language-name">CN</span>
    </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({})
</script>

<style lang="scss">
.app-language {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.app-language-icon {
  height: 1.125rem;
  width: 1.75rem;
}
.app-language-name {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
</style>
