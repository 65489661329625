import Vue from "vue";
import Scroll from "@/directives/scroll/";
import Resize from "@/directives/resize/";
// import { VTooltip, VClosePopover } from "v-tooltip";
// import vClickOutside from "v-click-outside";

Vue.directive(Scroll.name, Scroll);
Vue.directive(Resize.name, Resize);

// Vue.directive("tooltip", VTooltip);
// Vue.directive("close-popover", VClosePopover);

// Vue.use(vClickOutside);
