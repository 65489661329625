<template>
  <nav class="top-nav">
    <nuxt-link class="top-nav-item" active-class="is-active" to="/" exact>
      <span>首页</span>
    </nuxt-link>
    <nuxt-link v-if="userInfo && isDistributor" class="top-nav-item"
      active-class="is-active" to="/resource">
      <span>商家中心</span>
    </nuxt-link>
    <nuxt-link v-else class="top-nav-item" active-class="is-active" to="/merchant-center">
      <span>商家中心</span>
    </nuxt-link>
    <nuxt-link class="top-nav-item" active-class="is-active" to="/product">
      <span>资源采购</span>
    </nuxt-link>
    <nuxt-link class="top-nav-item" active-class="is-active" to="/company">
      <span>邮轮专区</span>
    </nuxt-link>
    <nuxt-link class="top-nav-item" active-class="is-active" to="/cruise-map">
      <span>邮轮地图</span>
    </nuxt-link>
  </nav>
</template>
<script>
export default {
	name: "TopBarMenu",
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
		isLogin() {
			return !!this.userInfo;
		},
		auth() {
			return this.$auth.$state;
		},
		isDistributor() {
			if (!this.auth.loggedIn) return false;
			return !!this.auth.user.distributor;
		},
		resourcesProcurementActive() {
			const whiteListPath = [
				"/resource/product/list/cruise",
				"/resource/product/list/vacation"
			];
			return whiteListPath.includes(this.$route.path);
		}
	}
};
</script>
<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";

.top-nav {
	display: flex;
	flex: 1;
	justify-content: center;

	&-item {
		display: inline-block;
		padding: 24px 16px;
		font-size: 16px;
		color: var(--header-text-color);
		font-weight: bold;

		span {
			display: inline-block;
			padding: 2px 0;
			border-bottom: 3px solid transparent;
		}

		&.is-active {
			span {
				border-bottom-color: var(--header-text-color-active);
			}

			color: var(--header-text-color-active);
		}

		&-default {
			color: var(--header-text-color) !important;

			span {
				border-bottom: 3px solid transparent !important;
			}
		}
	}
}

</style>
