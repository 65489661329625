<template>
  <span class="v-tag" :class="classes">
    <slot />
  </span>
</template>

<script>
export default {
  name: "VTag",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    effect: {
      type: String,
      default: "default"
    }
  },

  computed: {
    classes() {
      return [`v-tag--${this.type}`, `v-tag--${this.effect}`];
    }
  },
  methods: {}
};
</script>

<style>

</style>
