<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutLeft"
      @after-leave="showThumbnail = true"
    >
      <div
        class="app-banner bg-[#000] bg-opacity-74 fixed bottom-0 w-full h-[126px] z-50"
        v-if="showOpen"
        key="open"
      >
        <div class="main-width relative h-full">
          <div class="flex items-center h-full">
            <el-image
              class="absolute bottom-0 w-[747px]"
              fit="cover"
              src="~@/assets/img/global/appbanner/mini-app-spring.png"
            />
            <el-image
              class="block w-[90px] h-[90px] ml-auto mt-"
              src="~@/assets/img/global/appbanner/mini-app-qrcode.png"
            ></el-image>
            <div class="close ml-20 block" @click="showOpen = false">
              <i class="el-icon-close icon" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInLeft animate__faster"
      leave-active-class="animate__animated animate__slideOutLeft animate__faster"
      @after-leave="showOpen = true"
    >
      <div
        v-if="showThumbnail"
        key="close"
        class="app-banner-close fixed bottom-0 z-50 cursor-pointer"
        @click="showThumbnail = false"
      >
        <el-image
          class="block w-[184px]"
          fit="cover"
          src="~@/assets/img/global/appbanner/app-banner-close.png"
        ></el-image>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "AppBanner",
  data() {
    return {
      showOpen: false,
      showThumbnail: false
    };
  },
  mounted() {
    this.showOpen = true;
  }
};
</script>
<style lang="scss" scoped>
.close {
  cursor: pointer;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.close .icon {
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}
.close:hover .icon {
  transform: rotate(90deg);
  color: rgba($color: #fff, $alpha: 0.6);
}
</style>
