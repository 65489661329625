
import { memoize } from "lodash";
import defineAsyncComponent from "~/utils/defineAsyncComponent";

import loadBMapApi from "./load-baidu-maps";
export default function install(Vue, options) {

  const BMap = defineAsyncComponent(() => import("./components/Map.vue"))
  const BMarker = defineAsyncComponent(() => import("./components/Marker.vue"))
  const BCustomOverlay = defineAsyncComponent(() => import('./components/CustomOverlay.vue'))
  const BMarkerClusterer = defineAsyncComponent(() => import('./components/MarkerClusterer.vue'))
  const BMapVglView = defineAsyncComponent(() => import('./components/VglView.vue'))
  const BIconLayer = defineAsyncComponent(() => import('./components/IconLayer.vue'))
  const BLineLayer = defineAsyncComponent(() => import('./components/LineLayer.vue'))
  const ResetControl = defineAsyncComponent(() => import('./controls/ResetControl.vue'))
  const Navigation = defineAsyncComponent(() => import('./controls/Navigation.vue'))
  const ZoomControl = defineAsyncComponent(() => import('./controls/ZoomControl.vue'))
  const MapLayer = defineAsyncComponent(() => import('./components/MapLayer.vue'))
  const LabelLayer = defineAsyncComponent(() => import('./components/LabelLayer.vue'))


  const bmapApiPromiseLazy = makeGMapApiPromiseLazy(options);

  Vue.mixin({
    created() {
      this.$bmapApiPromiseLazy = bmapApiPromiseLazy;
    },
  });
  Vue.component("n-b-map", BMap);
  Vue.component("n-b-map-marker", BMarker);
  Vue.component('n-b-map-marker-clusterer', BMarkerClusterer)
  Vue.component('n-b-map-vgl', BMapVglView)
  Vue.component('n-b-map-icon-layer', BIconLayer);
  Vue.component('n-b-map-line-layer', BLineLayer);
  Vue.component('n-b-map-navigation', Navigation)
  Vue.component('n-b-map-custom-overlay', BCustomOverlay)
  Vue.component('n-b-map-reset', ResetControl);
  Vue.component('n-b-map-zoom', ZoomControl);
  Vue.component('n-b-map-layer', MapLayer)
  Vue.component('n-b-map-label-layer', LabelLayer)
}

function makeGMapApiPromiseLazy(options = {}) {
  function onApiLoaded() {
    console.log("onApiLoaded");
    return window.BMap;
  }

  if (options.load) {
    return memoize(() => {
      return new Promise((resolve) => {
        window["_initBaiduMap"] = resolve;
        loadBMapApi(options.load);
      }).then(onApiLoaded);
    });
  } else {
    const promise = new Promise((resolve) => {
      window["_initBaiduMap"] = resolve;
    }).then(onApiLoaded);

    return memoize(() => promise);
  }
}
