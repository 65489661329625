import Vue from "vue";
// import "@/assets/scss/element/element.scss";
import {defineNuxtPlugin} from '@nuxtjs/composition-api'
import {
  Container,
  Main,
  Aside,
  Header,
  Footer,
  Menu,
  MenuItem,
  Submenu,
  MenuItemGroup,
  Table,
  TableColumn,
  Pagination,
  Form,
  Button,
  ButtonGroup,
  FormItem,
  Input,
  Autocomplete,
  InputNumber,
  DatePicker,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  Switch,
  Row,
  Col,
  Descriptions,
  DescriptionsItem,
  MessageBox,
  Dialog,
  Empty,
  Card,
  Image,
  Tabs,
  TabPane,
  Upload,
  Cascader,
  Carousel,
  CarouselItem,
  Link,
  Popover,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tag,
  Skeleton,
  SkeletonItem,
  Radio,
  RadioGroup,
  Timeline,
  TimelineItem,
  Alert,
  Scrollbar,
  Tooltip,
  Collapse,
  CollapseItem,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  PageHeader,
  Progress,
  ColorPicker,
  Tree,
  Popconfirm,
  Drawer,
  Calendar
} from "element-ui";

export default defineNuxtPlugin(() => {

  Vue.use(Popconfirm)

  Vue.use(ColorPicker)
  Vue.use(Tree)

  Vue.use(Container);
  Vue.use(Header);
  Vue.use(Aside);
  Vue.use(Main);
  Vue.use(Footer);

  Vue.use(Menu);
  Vue.use(MenuItem);
  Vue.use(Submenu);
  Vue.use(MenuItemGroup);

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Pagination);

// forms
  Vue.use(Form);
  Vue.use(FormItem);
  Vue.use(Input);
  Vue.use(Autocomplete);
  Vue.use(DatePicker);
  Vue.use(Select);
  Vue.use(Option);
  Vue.use(Checkbox);
  Vue.use(CheckboxGroup);
  Vue.use(InputNumber);
  Vue.use(Button);
  Vue.use(ButtonGroup);
  Vue.use(Switch);

  Vue.use(Row);
  Vue.use(Col);

  Vue.use(Descriptions);
  Vue.use(DescriptionsItem);

  Vue.use(Dialog);
  Vue.use(Empty);
  Vue.use(Card);
  Vue.use(Image);
  Vue.use(Tabs);
  Vue.use(TabPane);
  Vue.use(Upload);
  Vue.use(Cascader);

  Vue.use(Carousel);
  Vue.use(CarouselItem);

  Vue.use(Link);

  Vue.use(Popover);
  Vue.use(Divider);

  Vue.use(Dropdown);
  Vue.use(DropdownItem);
  Vue.use(DropdownMenu);
  Vue.use(Tag);

  Vue.use(Skeleton);
  Vue.use(SkeletonItem);

  Vue.use(Radio);
  Vue.use(RadioGroup);
  Vue.use(Timeline);
  Vue.use(TimelineItem);
  Vue.use(Alert);

  Vue.use(Scrollbar);
  Vue.use(Tooltip);

  Vue.use(Collapse);
  Vue.use(CollapseItem);

  Vue.use(Badge)
  Vue.use(Breadcrumb)
  Vue.use(BreadcrumbItem)

  Vue.use(Avatar)
  Vue.use(PageHeader)

  Vue.use(Tree)
  Vue.use(Popconfirm)
  Vue.use(Progress)

  Vue.use(Drawer);

  // Vue.use(Calendar);

  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;

})