<template>
  <div :class="$style.wrapper">
    <div :class="$style.login_wraper">
      <div :class="$style.login__switchTab">
        <span :class="[$style.login__switchTab__tab, { [$style.on]: activeIndex == 1 }]"
          @click="changeNav(1)">账号登录</span>
        <!-- <span class="login__switchTab__tab__hr"></span> -->
        <span :class="[$style.login__switchTab__tab, { [$style.on]: activeIndex == 2 }]"
          @click="changeNav(2)">手机号登录</span>
      </div>
      <LoginAccount ref="refAccount" @success="onSuccess" @navigateToSettle="$emit('navigateToSettle')"
        v-show="activeIndex == 1"></LoginAccount>
      <LoginPhone ref="refphone" @success="onSuccess" @navigateToSettle="$emit('navigateToSettle')"
        v-show="activeIndex == 2"></LoginPhone>
    </div>
  </div>
</template>
<script>
import LoginAccount from "./LoginAccount.vue";
import LoginPhone from "./LoginPhone.vue";

import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: "Login",
  layout: "Auth",
  auth: "guest",
  components: {
    LoginAccount,
    LoginPhone,
  },
  data() {
    return {
      activeIndex: 1, // 1 账号登录 ; 2手机号登录
    };
  },
  methods: {
    onSuccess(event) {
      this.$emit('success', event)
    },
    changeNav(activeIndex) {
      this.resetFields();
      this.activeIndex = activeIndex;
    },
    resetFields() {
      this.$refs.refAccount.resetFields();
      this.$refs.refphone.resetFields();
    },
  },
});
</script>
<style lang="scss" module>
.login_wraper {
  background: #fff;
  border-radius: 12px;
  padding: 35px;
}
.login__switchTab {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__switchTab__tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(178, 178, 189, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  cursor: pointer;
}
.login__switchTab__tab.on {
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.login__switchTab span:last-child {
  margin-left: 20px;
}
</style>