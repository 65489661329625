<template>
  <el-dialog title="选择邮轮" :visible.sync="dialogVisible" @close="$emit('confirm', checkedKeys)">
    <el-tree :data="data" default-expand-all show-checkbox node-key="cruise_id" :default-checked-keys="checkedKeys" :props="defaultProps" @check="onCheck">
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.name }}</span>
        <span>共{{ data.num }}个产品</span>
      </div>
    </el-tree>
    <div slot="footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// import usePagination from "@/composables/usePagination";
import { REQUEST_CODES } from "@/constants/const-http.js";

import { getShopCruiseCompany } from "~/services/product";

class ClassPrimise {
  constructor() {
    this.ret = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  resolve(data) {
    this.resolve(data);
  }
  reject(data) {
    this.reject(data);
  }
}

export default {
  name: "DialogSelectCruise",
  props: ["default"],
  data() {
    return {
      dialogVisible: false,
      data: [],
      defaultProps: {
        children: "cruise",
        label: "name",
      },
      checkedKeys: [],
    };
  },
  mounted() {
    this.checkedKeys = this.default
    this._getShopCruiseCompany()
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    onCheck(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys) {
      console.log(checkedKeys)
      this.checkedKeys = checkedKeys.checkedKeys.filter(item => !!item)
    },
    selecteProduct(checkedKeys) {
      this.checkedKeys = checkedKeys || []
      this.retPromise = new ClassPrimise();
      this.dialogVisible = true;
      return this.retPromise.ret;
    },
    onConfirm() {
      if (!this.checkedKeys.length) {
        return this.$message.error("请选择邮轮");
      }
      this.$emit('confirm', this.checkedKeys)
      // this.retPromise.resolve(this.checkedKeys);
      this.dialogVisible = false;
      this.retPromise = null;
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit('cancel');
      // this.$emit('close', this.checkedKeys)
      // this.retPromise && this.retPromise.reject();
    },
    async _getShopCruiseCompany() {
      const { code, data, msg } = await getShopCruiseCompany()
      if (code !== REQUEST_CODES.ERROR_OK) {
        this.$message.error(msg)
        // throw new Error(msg)
      }
      this.data = data
    },
  },
};
</script>
<style lang="scss" scoped>

.product {
  padding: 14px;
  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #2b2b36;
    margin-bottom: 12px;
  }
}
.placeholder {
  &__item {
    width: 50%;
    padding: 0 5px;
    display: inline-block;
    vertical-align: top;
    &__img {
      height: 164px;
      background-color: #e9ebf3;
      border-radius: 4px;
      margin-bottom: 13px;
    }
    &__name {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
    &__price {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

</style>