<template>
  <el-dialog title="选择分类" :visible.sync="dialogVisible" @close="$emit('close')">
    <el-radio-group v-model="productType">
      <el-radio :label="item.value" v-for="(item, index) in options" :key="index">{{item.label}}</el-radio>
    </el-radio-group>
    <div slot="footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// import usePagination from "@/composables/usePagination";
import { REQUEST_CODES } from "@/constants/const-http.js";

import { getShopProductList } from "@/services/user";

class ClassPrimise {
  constructor() {
    this.ret = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  resolve(data) {
    this.resolve(data);
  }
  reject(data) {
    this.reject(data);
  }
}

export default {
  name: "DialogSelectClass",
  props: ["default"],
  data() {
    return {
      dialogVisible: false,
      productType: null,
      options: [
        { value: "1", label: "邮轮" },
        { value: "2", label: "旅游度假" },
      ],
    };
  },
  mounted() {
    this.productType = this.default;
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    selecteProduct(productType) {
      this.productType = productType || null;
      this.retPromise = new ClassPrimise();
      this.dialogVisible = true;
      return this.retPromise.ret;
    },
    onConfirm() {
      if (this.productType == null) {
        return this.$message.error("请选择产品类型");
      }
      this.$emit("confirm", this.productType);
      // this.retPromise.resolve(this.productType);
      this.dialogVisible = false;
      this.retPromise = null;
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit('cancel');
      // this.$emit("close");
      // this.retPromise && this.retPromise.reject();
    },
  },
};
</script>
<style lang="scss" scoped>

.product {
  padding: 14px;
  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #2b2b36;
    margin-bottom: 12px;
  }
}
.placeholder {
  &__item {
    width: 50%;
    padding: 0 5px;
    display: inline-block;
    vertical-align: top;
    &__img {
      height: 164px;
      background-color: #e9ebf3;
      border-radius: 4px;
      margin-bottom: 13px;
    }
    &__name {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
    &__price {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

</style>