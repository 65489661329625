import { REQUEST_CODES } from "@/constants/const-http";
import { getUserLogin, saveUserRegister, getUserInfo } from "@/services/login";
import { distributorInfo as  getDistributorInfo} from "@/services/settled.js";
export const state = () => ({
  token: null, //获取token
  userInfo: null, //用户信息
  isOperation: true, //分销商入驻 按钮状态 是否显示注册和登录按钮
  status: null, // 0 未编辑  1 2  完善入驻信息 3 查看审核信息
  distributorInfo: null
});
export const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_OPERATION: (state, value) => {
    state.isOperation = value;
  },
  SET_USERSETTLEDSTATUS: (state, value) => {
    state.status = value;
  },
  SET_DISTRIBUTOR_INFO(state, distributorInfo = {}) {
    state.distributorInfo = distributorInfo;
  }
};
export const actions = {
  // user login
  async login({ commit }, userInfo) {
    const res= await this.$auth.loginWith('cookie', {
      data: { data: userInfo },
    });

    const {code, data, msg} = res.data;
    if (code !== REQUEST_CODES.ERROR_OK) {
      // console.log(msg);
      throw new Error(msg);
    }

    commit('SET_TOKEN', data.token, {
      root: true
    })

    console.log(data)
    this.$auth.setUser(data.admin);

    // 分销商跳转到 商家中心

    return data
  },
  async fetchDistributorInfo({commit}) {
    const {code, data, msg} = await getDistributorInfo();
    if(code !== REQUEST_CODES.ERROR_OK){
      throw new Error(msg);
    }
    commit('SET_DISTRIBUTOR_INFO', data);
  },
  async fetchUser() {
    const res = await this.$auth.fetchUser();
    console.log('fetchUser', res);
    return res;
  },
  register({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      saveUserRegister(userInfo).then((res) => {
        if (res.code && res.code == 10000) {
          commit("SET_TOKEN", res.data.token);
          commit("SET_USERINFO", res.data.admin);
          localStorage.setItem("userInfo", JSON.stringify(res.data.admin));
          localStorage.setItem("token", res.data.token);
          resolve(res);
        } else {
          reject(res.msg);
        }
      });
    });
  },
  //设置是否显示登录和注册按钮
  set_operation_btn({ commit }, value) {
    commit("SET_OPERATION", value);
  },

  async getUserInfo({ commit }) {
    let { code, data, msg } = await getUserInfo();
    if (code !== REQUEST_CODES.ERROR_OK) {
      return Promise.reject(msg);
    }
    commit("SET_USERINFO", data.info);
    return data;
  },
};

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions
// };
