import { REQUEST_CODES } from "~/constants/const-http";
import { getConfig } from "~/services/company"

export const state = () => ({
    hot_keywords: [],
    group_code: {},
    settled: {}
}) 
export const mutations = {
    setConfig(state, data = {}){
        Object.assign(state, data);
    }
}
export const actions = {
    async initConfig({commit}){
       let {code, data, msg} =  await getConfig();
       if(code !== REQUEST_CODES.ERROR_OK){
            throw new Error(msg)
       }
       commit('setConfig', data);
    }
}