import {isClient, isObject, isDefined} from "@vueuse/core";
import {isString, isFunction, isElement, isUndefined} from "lodash";
import type {Entries} from 'type-fest';

export  {
    isObject,
    isString,
    isFunction,
    isElement as isHtmlElement,
    isUndefined,
    isDefined
}

/**
 * Tests to see if the object is "thennable".
 * @param value the object to test
 */
export function isPromise(value: any): value is PromiseLike<any> {
  return isFunction(value?.then);
}



export const mutable = <T extends readonly any[] | Record<string, unknown>>(
  val: T
) => val as Mutable<typeof val>
export type Mutable<T> = { -readonly [P in keyof T]: T[P] }


export const keysOf = <T extends object>(arr: T) => Object.keys(arr) as Array<keyof T>;
export const entriesOf = <T extends object>(arr: T) => Object.entries(arr) as Entries<T>;
