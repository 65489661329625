
<template>
  <el-dialog custom-class="coupon_dialog" title="" :visible.sync="dialogTableVisible" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" width="480px">
    <div slot="title">
      <div class="title pr-[20px] pb-[10px] flex items-center justify-between">
        <span class="title__label text-[18px] font-600">优惠券</span>
        <div class="flex items-center">
          <!-- <div class="title__desc inline-flex items-center">
            <span class="text-sm">使用说明</span>
            <span class="ficon ficon-wenhao"></span>
          </div> -->

          <el-tooltip placement="bottom-end" :offset="44">
            <div class="w-[400px]" slot="content">
              <pre class="tooltip-desc break-words break-all whitespace-pre-wrap">
1、每笔订单仅能使用一张优惠券，不可叠加使用；
2、优惠券须在有效期内使用，过期无效；
3、优惠券仅限在官网下单时使用，小程序暂不支持；
4、使用优惠券的订单发票金额以实际支付的金额为准；
5、每张优惠券仅可在下单时使用，若订单失败或者免费取消（无损/无罚金）情况下，优惠券会退回至原账户；
6、使用了优惠券的订单，如果产生了退换改等情况，以实际支付金额为准；
7、若订单取消产生损失或罚金时优惠券不予退回；
              </pre>
            </div>
            <div class="title__desc inline-flex items-center cursor-pointer">
              <span class="text-sm">使用说明</span>
              <span class="ficon ficon-wenhao ml-[5px]"></span>
            </div>
          </el-tooltip>
          <span class="ficon ficon-guanbi1 ml-[20px] cursor-pointer select-none text-[20px]" @click="dialogTableVisible = false"></span>
        </div>
      </div>
    </div>
    <div class="coupon relative">
      <div class="tab">
        <div class="tab__item" :class="{'tab__item--active': tabCode == 'available'}" @click="changeTab('available')">可使用（{{list.available.length}}）</div>
        <div class="tab__item" :class="{'tab__item--active': tabCode == 'unavailable'}" @click="changeTab('unavailable')">不可用（{{list.unavailable.length}}）</div>
      </div>
      <div class="coupon__content w-[427px] mx-auto bg-white rounded-[7px]">
        <template v-if="list[tabCode].length">
          <ul v-if="tabCode == 'available'">
            <li v-for="(coupon, couponIndex) in list[tabCode]" :key="couponIndex" @click="selectCoupon(coupon.coupon_code, couponIndex)">
              <el-radio class="flex items-center" :value="couponCode" :label="coupon.coupon_code">
                <div class="coupon__li flex text-white">
                  <div class="coupon__li__left flex-1">
                    <p class="coupon__li__name text-[18px]">{{coupon.coupon_name}}</p>
                    <p class="coupon__li__date text-xs font-600">使用时间 {{coupon.validity_start_time}} — {{coupon.validity_end_time}}</p>
                    <div class="coupon__li__left__btn" v-if="coupon.coupon_rule" @click.capture="toggleShow(couponIndex)">使用说明 <span class="ficon" :class="coupon.show  && coupon.coupon_rule ? 'ficon-arrow-up' : 'ficon-arrow-bottom'"></span></div>
                  </div>
                  <div class="coupon__li__right w-[100px]">
                    <div class="coupon__li__price">
                      <span class="coupon__li__price__union">￥</span>
                      <span class="coupon__li__price__num">{{coupon.amount}}</span>
                    </div>
                    <p class="coupon__li__price__desc">满{{coupon.satisfy_amount}}可用</p>
                    <!-- <div class="coupon__li__btn">立即使用</div> -->
                  </div>
                </div>
              </el-radio>
              <div class="popover" v-if="coupon.show && coupon.coupon_rule">
                <div>
                  <pre class="break-all whitespace-pre-line">
{{coupon.coupon_rule}}
               </pre>
                </div>
                <div class="popover__arrow"></div>
              </div>
            </li>
          </ul>
          <ul v-else>
            <li v-for="(coupon, couponIndex) in list[tabCode]" :key="couponIndex" @click="selectCoupon(coupon.coupon_code, couponIndex)">
              <div class="coupon__li coupon__li--invalid flex text-white">
                <div class="coupon__li__left flex-1">
                  <p class="coupon__li__name text-[18px]">{{coupon.coupon_name}}</p>
                  <p class="coupon__li__date text-xs font-600">使用时间 {{coupon.validity_start_time}} — {{coupon.validity_end_time}}</p>
                  <div class="coupon__li__left__btn" @click="toggleShow(couponIndex)">使用说明 <span class="ficon" :class="coupon.show  && coupon.label ? 'ficon-arrow-up' : 'ficon-arrow-bottom'"></span></div>
                </div>
                <div class="coupon__li__right w-[100px]">
                  <div class="coupon__li__price">
                    <span class="coupon__li__price__union">￥</span>
                    <span class="coupon__li__price__num">{{coupon.amount}}</span>
                  </div>
                  <p class="coupon__li__price__desc">满{{coupon.satisfy_amount}}可用</p>
                  <!-- <div class="coupon__li__btn">立即使用</div> -->
                </div>
              </div>
              <div class="popover" style="left: 0" v-if="coupon.show  && coupon.label">
                <div>
                  <pre class="break-all whitespace-pre-line">
{{coupon.coupon_rule}}
               </pre>
                </div>
                <div class="popover__arrow"></div>
              </div>
              <div class="popover" style="left: 0" v-if="coupon.label">
                <div>
                  <pre class="break-all whitespace-pre-line" style="color: $highlight-color">
{{coupon.label}}
               </pre>
                </div>
                <!-- <div class="popover__arrow"></div> -->
              </div>
            </li>
          </ul>
        </template>
        <el-empty description="暂无数据" v-if="!list[tabCode].length"></el-empty>
      </div>
    </div>
    <div class="text-center btn">
      <el-button style="width: 100px;" type="primary" @click="onConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { defineComponent } from "@nuxtjs/composition-api";
import { useStorage } from "@vueuse/core";
import { getCouponList } from "@/services/user";
import useRequest from "@/composables/useRequest";
import Dayjs from "dayjs";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "DialogCouponOrder",
  props: ["coupon_list", "coupon_code"],
  setup() {
    const dialogStatus = useStorage("DIALOG_COUPON", {
      count: 0, // 3 弹框次数
      date: "",
    });
    const { execute, state } = useRequest(
      async () => getCouponList({ status: "1" }),
      {
        list: [],
      },
      { immediate: false }
    );

    return {
      dialogStatus,
      state,
      execute,
    };
  },
  computed: {
    auth() {
      return this.$auth.$state;
    },
    loggedIn() {
      return this.auth.loggedIn;
    },
  },
  data() {
    return {
      tabCode: "available",
      dialogTableVisible: false,
      couponCode: this.coupon_code,
      list: [],
    };
  },
  async created() {
    let couponList = cloneDeep(this.coupon_list);
    for (let i in couponList) {
      couponList[i] = couponList[i].map((item) => ({
        ...item,
        show: false, // 展示说明文档
      }));
    }

    this.list = couponList;

    if (
      this.loggedIn &&
      this.dialogStatus.count < 3 &&
      this.dialogStatus.date != Dayjs().format("YYYY-MM-DD")
    ) {
      await this.execute();
      if (this.state.total_count) {
        this.dialogTableVisible = true;

        //  更新弹框数据
        this.dialogStatus = {
          count: this.dialogStatus.count + 1,
          date: Dayjs().format("YYYY-MM-DD"),
        };
      }
    }
  },
  methods: {
    changeTab(tabCode) {
      this.tabCode = tabCode;
    },
    onConfirm() {
      this.dialogTableVisible = false;
      this.$emit("onConfirm", this.couponCode);
    },
    selectCoupon(code, index) {
      this.couponCode = code;
    },
    show() {
      this.dialogTableVisible = true;
    },
    toggleShow(index) {
      let item = cloneDeep(this.list[this.tabCode][index]);
      item.show = !item.show;
      this.list[this.tabCode].splice(index, 1, item);
    },
  },
});
</script>
<style lang="scss" scoped>

.tooltip-desc {
  font-size: 14px;
}
.title {
  border-bottom: 1px solid #ededed;
  &__label {
    color: #212529;
  }
  &__desc {
    color: $highlight-color;
  }
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  &__item {
    font-size: 18px;
    font-weight: 500;
    margin: 0 20px;
    cursor: pointer;
    user-select: none;
    &--active {
      color: $highlight-color;
    }
  }
}
.btn {
  padding-top: 20px;
}
// ::v-deep {
//   .popover__coupon {
//   }
//   .coupon_dialog {
//     box-shadow: none;
//     background: transparent;
//     .el-dialog__header {
//       display: none;
//     }
//     .el-dialog__body {
//       padding: 0;
//     }
//   }
// }
.coupon {
  .close {
    border: 1px solid white;
    color: white;
  }
  &__content {
    height: 400px;
    overflow: hidden;
  }
  &__title {
    background: url(~@/assets/img/coupon/coupon_title_bg.png) no-repeat center;
    background-size: cover;
    padding: 0 35px;
    height: 62px;
  }
  ul {
    padding: 10px 0;
    height: 400px;
    overflow: auto;
  }
  li {
    .popover {
      position: relative;
      left: 5px;
    }
  }
  &__li {
    width: 395px;
    margin: 0 auto 10px;
    height: 104px;
    background: url(~@/assets/img/coupon/coupon_item_bg.png) no-repeat center;
    background-size: cover;

    &__left {
      padding-top: 14px;
      // padding-left: 25px;
      &__btn {
        height: 22px;
        line-height: 22px;
        display: inline-flex;
        padding: 0 10px;
        font-size: 13px;
        border-radius: 22px;
        border: 1px solid white;
        user-select: none;
        cursor: pointer;
        margin-left: 25px;
        &__wrapper {
        }
        .ficon {
          margin-left: 5px;
        }
      }
    }
    &__right {
      width: 110px;
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__price {
      font-weight: 600;
      line-height: 40px;
      height: 40px;
      overflow: hidden;
      &__union {
        text-shadow: 0px 0px 4px #d93c09;
        font-size: 16px;
        margin-right: -4px;
      }
      &__num {
        text-shadow: 0px 0px 4px #d93c09;
        font-size: 40px;
      }
    }
    &__name {
      font-size: 18px;
      margin-bottom: 7px;
      margin-left: 25px;
      font-weight: 900;
    }
    &__date {
      font-size: 13px;
      margin-bottom: 10px;
      margin-left: 25px;
    }
    &__btn {
      background: url(~@/assets/img/coupon/btn_bg.png) no-repeat center;
      background-size: cover;
      width: 81px;
      height: 33px;
      color: #e9213a;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      line-height: 30px;
    }
    &--invalid {
      // background: url(~@/assets/img/coupon/coupon_item_used_bg.png) no-repeat
      background: url(~@/assets/img/coupon/coupon_order_item_used_bg.png)
        no-repeat center;
      background-size: cover;
      // width: 465px;
      // height: 120px;
      span {
        text-shadow: none !important;
      }
      // pre {
      // color: ;
      // }
      .coupon__li__btn {
        color: #6d6d6d !important;
        background-color: #e5e5e5 !important;
        border-radius: 50px;
        background-image: none;

        &::after {
          content: "";
          position: absolute;
          width: 107px;
          height: 107px;
          background: url(~@/assets/img/coupon/coupon_invalid.png) no-repeat
            center;
          background-size: cover;
          top: 6px;
          right: 105px;
        }
      }
    }
  }
}

.popover {
  width: 395px;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  word-break: break-all;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background: #eeeeee;
  position: relative;
  margin: 0 auto 20px;
  // padding: 20px 30px;
  padding: 5px 10px;
  &__arrow {
    border-width: 6px;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: -6px;
    left: 63.5px;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #eeeeee;

    &::after {
      content: " ";
      border-width: 6px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: 1px;
      margin-left: -6px;
      border-top-width: 0;
      border-bottom-color: #eeeeee;
    }
  }
}

</style>
