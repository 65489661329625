<template>
    <div>
        <div class="app-menu">
            <AppNavItem v-for="(nav, navIndex) in navs" :key="navIndex" v-bind="nav" />
        </div>
    </div>
</template>

<script>
import { defineComponent, useFetch } from "@nuxtjs/composition-api";

import AppNavItem from "./AppNavItem.vue";
export default defineComponent({
    title: 'AppNav',
    components: { AppNavItem },
    props: {
        navs: {
            type: Array,
            default: () => []
        }
    }
    // setup() {

    //     return { navs }
    // }
})
</script>

<style lang="scss">
.app-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.app-menu-item {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
.app-menu-item::after {
  content: '';
  --tw-bg-opacity: 1;
  background-color: rgba(31, 33, 41, var(--tw-bg-opacity));
  display: none;
  height: 0.125rem;
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 0px;
}
.app-menu-item-active::after {
  display: block;
}
.app-menu-item-active .app-menu-item-link, .app-menu-item-active .app-menu-item-title {
  font-weight: 500;
}
.app-menu-item-link, .app-menu-item-title {
  display: block;
  height: 3.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  line-height: 3.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.app-menu-item:not(.app-menu-itemactive):hover:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.app-menu-submenu {
  position: relative;
}
.app-menu-submenu-pop {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  min-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  top: 100%;
  left: 50%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-translate-x: -50%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.app-menu-submenu-item-link {
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  white-space: nowrap;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.app-menu-submenu-item-link-active, .app-menu-submenu-item-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(22, 53, 144, var(--tw-text-opacity));
}
.app-menu-scroller {
  max-height: 22.5rem;
}
</style>
