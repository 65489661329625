import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95875770 = () => interopDefault(import('../pages/banner/index.vue' /* webpackChunkName: "pages/banner/index" */))
const _98e5d312 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _d58c6386 = () => interopDefault(import('../pages/cruise-map.vue' /* webpackChunkName: "pages/cruise-map" */))
const _e6de48e0 = () => interopDefault(import('../pages/cruise-map/composables/useCompanyList.js' /* webpackChunkName: "pages/cruise-map/composables/useCompanyList" */))
const _724b8cf8 = () => interopDefault(import('../pages/designer.vue' /* webpackChunkName: "pages/designer" */))
const _590e8d07 = () => interopDefault(import('../pages/designer/index.vue' /* webpackChunkName: "pages/designer/index" */))
const _6da2708e = () => interopDefault(import('../pages/designer/customer.vue' /* webpackChunkName: "pages/designer/customer" */))
const _406292f0 = () => interopDefault(import('../pages/designer/customization.vue' /* webpackChunkName: "pages/designer/customization" */))
const _1e71c32e = () => interopDefault(import('../pages/designer/mine.vue' /* webpackChunkName: "pages/designer/mine" */))
const _1b431bf0 = () => interopDefault(import('../pages/designer/offiaccount.vue' /* webpackChunkName: "pages/designer/offiaccount" */))
const _2e5a54f9 = () => interopDefault(import('../pages/designer/tabbar.vue' /* webpackChunkName: "pages/designer/tabbar" */))
const _06bff548 = () => interopDefault(import('../pages/destination/index.vue' /* webpackChunkName: "pages/destination/index" */))
const _234ea921 = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _b62a54b0 = () => interopDefault(import('../pages/harbor/index.vue' /* webpackChunkName: "pages/harbor/index" */))
const _43b9ffaf = () => interopDefault(import('../pages/helpcenter.vue' /* webpackChunkName: "pages/helpcenter" */))
const _672dc6f2 = () => interopDefault(import('../pages/helpcenter/index.vue' /* webpackChunkName: "pages/helpcenter/index" */))
const _a12b044c = () => interopDefault(import('../pages/helpcenter/help-info.vue' /* webpackChunkName: "pages/helpcenter/help-info" */))
const _88ab3bb6 = () => interopDefault(import('../pages/helpcenter/question-answer.vue' /* webpackChunkName: "pages/helpcenter/question-answer" */))
const _4dab34d9 = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _a25a3e1e = () => interopDefault(import('../pages/merchant-center.vue' /* webpackChunkName: "pages/merchant-center" */))
const _08f51d05 = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _2391e870 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _1f847440 = () => interopDefault(import('../pages/order/confirmationsheet.vue' /* webpackChunkName: "pages/order/confirmationsheet" */))
const _5dbdf7b4 = () => interopDefault(import('../pages/order/touristinformation.vue' /* webpackChunkName: "pages/order/touristinformation" */))
const _888b3eba = () => interopDefault(import('../pages/order/platform/cruiseship.vue' /* webpackChunkName: "pages/order/platform/cruiseship" */))
const _66bfbe06 = () => interopDefault(import('../pages/order/platform/line.vue' /* webpackChunkName: "pages/order/platform/line" */))
const _c2e3ad0e = () => interopDefault(import('../pages/order/store/cruiseship.vue' /* webpackChunkName: "pages/order/store/cruiseship" */))
const _7a0f4148 = () => interopDefault(import('../pages/order/store/line.vue' /* webpackChunkName: "pages/order/store/line" */))
const _29f45d27 = () => interopDefault(import('../pages/resource.vue' /* webpackChunkName: "pages/resource" */))
const _ad4bab2c = () => interopDefault(import('../pages/resource/index.vue' /* webpackChunkName: "pages/resource/index" */))
const _76f89f45 = () => interopDefault(import('../pages/resource/invoice.vue' /* webpackChunkName: "pages/resource/invoice" */))
const _9cc043f0 = () => interopDefault(import('../pages/resource/invoice/index.vue' /* webpackChunkName: "pages/resource/invoice/index" */))
const _66d443f8 = () => interopDefault(import('../pages/resource/invoice/invoice-apply.vue' /* webpackChunkName: "pages/resource/invoice/invoice-apply" */))
const _f4a5e9aa = () => interopDefault(import('../pages/resource/invoice/invoice-detail.vue' /* webpackChunkName: "pages/resource/invoice/invoice-detail" */))
const _21af8ee3 = () => interopDefault(import('../pages/resource/invoice/invoice-payable-list.vue' /* webpackChunkName: "pages/resource/invoice/invoice-payable-list" */))
const _5f046fea = () => interopDefault(import('../pages/resource/invoice/invoice-uninvoiced-order.vue' /* webpackChunkName: "pages/resource/invoice/invoice-uninvoiced-order" */))
const _cd5580fc = () => interopDefault(import('../pages/resource/material/index.vue' /* webpackChunkName: "pages/resource/material/index" */))
const _2a8f9d4a = () => interopDefault(import('../pages/resource/product/index.vue' /* webpackChunkName: "pages/resource/product/index" */))
const _afe7cae4 = () => interopDefault(import('../pages/resource/shop.vue' /* webpackChunkName: "pages/resource/shop" */))
const _3c91fe91 = () => interopDefault(import('../pages/resource/shop/index.vue' /* webpackChunkName: "pages/resource/shop/index" */))
const _074fc744 = () => interopDefault(import('../pages/resource/shop/agent.vue' /* webpackChunkName: "pages/resource/shop/agent" */))
const _2912cac7 = () => interopDefault(import('../pages/resource/shop/agent/index.vue' /* webpackChunkName: "pages/resource/shop/agent/index" */))
const _60c79dcc = () => interopDefault(import('../pages/resource/shop/agent/pending.vue' /* webpackChunkName: "pages/resource/shop/agent/pending" */))
const _2227ff0c = () => interopDefault(import('../pages/resource/shop/income.vue' /* webpackChunkName: "pages/resource/shop/income" */))
const _a3b9ac9e = () => interopDefault(import('../pages/resource/shop/productmanagement.vue' /* webpackChunkName: "pages/resource/shop/productmanagement" */))
const _1c2af1ca = () => interopDefault(import('../pages/resource/shop/withdraw.vue' /* webpackChunkName: "pages/resource/shop/withdraw" */))
const _9f41d4b8 = () => interopDefault(import('../pages/resource/study/index.vue' /* webpackChunkName: "pages/resource/study/index" */))
const _38ddee76 = () => interopDefault(import('../pages/resource/transaction.vue' /* webpackChunkName: "pages/resource/transaction" */))
const _45e47772 = () => interopDefault(import('../pages/resource/transaction/list.vue' /* webpackChunkName: "pages/resource/transaction/list" */))
const _74961560 = () => interopDefault(import('../pages/resource/transaction/recharge.vue' /* webpackChunkName: "pages/resource/transaction/recharge" */))
const _4e999c1e = () => interopDefault(import('../pages/resource/transaction/withdrawal.vue' /* webpackChunkName: "pages/resource/transaction/withdrawal" */))
const _cb54309a = () => interopDefault(import('../pages/resource/messagecenter/order.vue' /* webpackChunkName: "pages/resource/messagecenter/order" */))
const _168657c6 = () => interopDefault(import('../pages/resource/messagecenter/store.vue' /* webpackChunkName: "pages/resource/messagecenter/store" */))
const _357486d9 = () => interopDefault(import('../pages/resource/official-accounts/left-config.vue' /* webpackChunkName: "pages/resource/official-accounts/left-config" */))
const _3fcb4b25 = () => interopDefault(import('../pages/resource/official-accounts/messageEditor.vue' /* webpackChunkName: "pages/resource/official-accounts/messageEditor" */))
const _8b74725c = () => interopDefault(import('../pages/resource/official-accounts/officialaccountmenu.vue' /* webpackChunkName: "pages/resource/official-accounts/officialaccountmenu" */))
const _5f4be3d2 = () => interopDefault(import('../pages/resource/official-accounts/right-config.vue' /* webpackChunkName: "pages/resource/official-accounts/right-config" */))
const _00141aac = () => interopDefault(import('../pages/resource/order/platform.vue' /* webpackChunkName: "pages/resource/order/platform" */))
const _38ee5d78 = () => interopDefault(import('../pages/resource/order/store.vue' /* webpackChunkName: "pages/resource/order/store" */))
const _48b1d5f4 = () => interopDefault(import('../pages/resource/product/collectionlist.vue' /* webpackChunkName: "pages/resource/product/collectionlist" */))
const _154e94eb = () => interopDefault(import('../pages/resource/tools/message.vue' /* webpackChunkName: "pages/resource/tools/message" */))
const _20347f2e = () => interopDefault(import('../pages/resource/tools/message/index.vue' /* webpackChunkName: "pages/resource/tools/message/index" */))
const _5717e3e1 = () => interopDefault(import('../pages/resource/tools/message/create-temp.vue' /* webpackChunkName: "pages/resource/tools/message/create-temp" */))
const _3dacd1a0 = () => interopDefault(import('../pages/resource/tools/message/log.vue' /* webpackChunkName: "pages/resource/tools/message/log" */))
const _9719cc06 = () => interopDefault(import('../pages/resource/tools/message/masstexting.vue' /* webpackChunkName: "pages/resource/tools/message/masstexting" */))
const _2c2fc27b = () => interopDefault(import('../pages/resource/tools/message/recharge.vue' /* webpackChunkName: "pages/resource/tools/message/recharge" */))
const _3e2592dd = () => interopDefault(import('../pages/resource/tools/qrcode/index.vue' /* webpackChunkName: "pages/resource/tools/qrcode/index" */))
const _717430d8 = () => interopDefault(import('../pages/resource/usercenter/companyinfo.vue' /* webpackChunkName: "pages/resource/usercenter/companyinfo" */))
const _adc17d66 = () => interopDefault(import('../pages/resource/usercenter/coupon.vue' /* webpackChunkName: "pages/resource/usercenter/coupon" */))
const _88acb8e8 = () => interopDefault(import('../pages/resource/usercenter/staffmanagement.vue' /* webpackChunkName: "pages/resource/usercenter/staffmanagement" */))
const _dfc7da40 = () => interopDefault(import('../pages/resource/usercenter/userinfo.vue' /* webpackChunkName: "pages/resource/usercenter/userinfo" */))
const _3b070051 = () => interopDefault(import('../pages/resource/product/-list/cruise.vue' /* webpackChunkName: "pages/resource/product/-list/cruise" */))
const _a37dd8fe = () => interopDefault(import('../pages/resource/tools/qrcode/create.vue' /* webpackChunkName: "pages/resource/tools/qrcode/create" */))
const _7e3fe3fe = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a2e1b11a = () => interopDefault(import('../pages/settled/index.vue' /* webpackChunkName: "pages/settled/index" */))
const _286c36fe = () => interopDefault(import('../pages/ShopQrFlow.vue' /* webpackChunkName: "pages/ShopQrFlow" */))
const _b73c5d16 = () => interopDefault(import('../pages/theme-cruise/index.vue' /* webpackChunkName: "pages/theme-cruise/index" */))
const _37728a66 = () => interopDefault(import('../pages/topic.vue' /* webpackChunkName: "pages/topic" */))
const _69df7733 = () => interopDefault(import('../pages/topic/ct-jsrcdj.vue' /* webpackChunkName: "pages/topic/ct-jsrcdj" */))
const _1707c39a = () => interopDefault(import('../pages/topic/rebate-policy.vue' /* webpackChunkName: "pages/topic/rebate-policy" */))
const _35b9c0b4 = () => interopDefault(import('../pages/topic/shop-tool.vue' /* webpackChunkName: "pages/topic/shop-tool" */))
const _ff7536aa = () => interopDefault(import('../pages/topic/topic-temp.vue' /* webpackChunkName: "pages/topic/topic-temp" */))
const _a99c8a58 = () => interopDefault(import('../pages/topic/2021/CenturyLegend-explorer.vue' /* webpackChunkName: "pages/topic/2021/CenturyLegend-explorer" */))
const _90afbf3a = () => interopDefault(import('../pages/topic/2021/yangzi-explorer.vue' /* webpackChunkName: "pages/topic/2021/yangzi-explorer" */))
const _06b526d2 = () => interopDefault(import('../pages/topic/2022/aden/index.vue' /* webpackChunkName: "pages/topic/2022/aden/index" */))
const _9cf70868 = () => interopDefault(import('../pages/topic/202208/aden.vue' /* webpackChunkName: "pages/topic/202208/aden" */))
const _aaf419f8 = () => interopDefault(import('../pages/topic/202208/hulunbuir.vue' /* webpackChunkName: "pages/topic/202208/hulunbuir" */))
const _d3607a0a = () => interopDefault(import('../pages/topic/202208/secretlandscape.vue' /* webpackChunkName: "pages/topic/202208/secretlandscape" */))
const _0850ddf6 = () => interopDefault(import('../pages/topic/2023/antarctica.vue' /* webpackChunkName: "pages/topic/2023/antarctica" */))
const _7250d1b7 = () => interopDefault(import('../pages/topic/2023/blue-dream.vue' /* webpackChunkName: "pages/topic/2023/blue-dream" */))
const _2810e8b2 = () => interopDefault(import('../pages/topic/2023/jsrcdj.vue' /* webpackChunkName: "pages/topic/2023/jsrcdj" */))
const _4b6f8241 = () => interopDefault(import('../pages/topic/2023/spectrum-of-the-seas.vue' /* webpackChunkName: "pages/topic/2023/spectrum-of-the-seas" */))
const _122046e4 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _967c52a8 = () => interopDefault(import('../pages/company/brand.vue' /* webpackChunkName: "pages/company/brand" */))
const _0cd79ba0 = () => interopDefault(import('../pages/company/cruise-company.vue' /* webpackChunkName: "pages/company/cruise-company" */))
const _1ae7f87b = () => interopDefault(import('../pages/company/cruise-list.vue' /* webpackChunkName: "pages/company/cruise-list" */))
const _43519716 = () => interopDefault(import('../pages/company/cruise-notice.vue' /* webpackChunkName: "pages/company/cruise-notice" */))
const _4cc745f0 = () => interopDefault(import('../pages/company/info-recommend.vue' /* webpackChunkName: "pages/company/info-recommend" */))
const _4640a811 = () => interopDefault(import('../pages/company/information.vue' /* webpackChunkName: "pages/company/information" */))
const _5fbeba89 = () => interopDefault(import('../pages/company/list.vue' /* webpackChunkName: "pages/company/list" */))
const _2ef2249f = () => interopDefault(import('../pages/company/new-ranking-basis.vue' /* webpackChunkName: "pages/company/new-ranking-basis" */))
const _1f4157f4 = () => interopDefault(import('../pages/company/old-brand.vue' /* webpackChunkName: "pages/company/old-brand" */))
const _2c8e2b80 = () => interopDefault(import('../pages/company/original-home.vue' /* webpackChunkName: "pages/company/original-home" */))
const _06ba8e53 = () => interopDefault(import('../pages/company/trade-home.vue' /* webpackChunkName: "pages/company/trade-home" */))
const _ad632b34 = () => interopDefault(import('../pages/customer/list.vue' /* webpackChunkName: "pages/customer/list" */))
const _c78784d0 = () => interopDefault(import('../pages/destination/list.vue' /* webpackChunkName: "pages/destination/list" */))
const _9bd6e8f6 = () => interopDefault(import('../pages/harbor/city.vue' /* webpackChunkName: "pages/harbor/city" */))
const _a8f76876 = () => interopDefault(import('../pages/harbor/product.vue' /* webpackChunkName: "pages/harbor/product" */))
const _8527a7e6 = () => interopDefault(import('../pages/product/cruise-calendar.vue' /* webpackChunkName: "pages/product/cruise-calendar" */))
const _6fedc38d = () => interopDefault(import('../pages/product/custom-travel.vue' /* webpackChunkName: "pages/product/custom-travel" */))
const _010dee0b = () => interopDefault(import('../pages/product/end-order-discount.vue' /* webpackChunkName: "pages/product/end-order-discount" */))
const _0b0377aa = () => interopDefault(import('../pages/product/holiday-route.vue' /* webpackChunkName: "pages/product/holiday-route" */))
const _77a70a99 = () => interopDefault(import('../pages/product/vacation/index.vue' /* webpackChunkName: "pages/product/vacation/index" */))
const _4255e850 = () => interopDefault(import('../pages/settled/process.vue' /* webpackChunkName: "pages/settled/process" */))
const _4a9b9076 = () => interopDefault(import('../pages/company/cruise/century.vue' /* webpackChunkName: "pages/company/cruise/century" */))
const _30f4b91c = () => interopDefault(import('../pages/company/cruise/decknavigation.vue' /* webpackChunkName: "pages/company/cruise/decknavigation" */))
const _b1ad757a = () => interopDefault(import('../pages/company/cruise/gallery.vue' /* webpackChunkName: "pages/company/cruise/gallery" */))
const _803c32d8 = () => interopDefault(import('../pages/company/cruise/location.vue' /* webpackChunkName: "pages/company/cruise/location" */))
const _7f3eadf4 = () => interopDefault(import('../pages/feedback/components/list.vue' /* webpackChunkName: "pages/feedback/components/list" */))
const _5693ed68 = () => interopDefault(import('../pages/feedback/components/suggest.vue' /* webpackChunkName: "pages/feedback/components/suggest" */))
const _3f6e6b28 = () => interopDefault(import('../pages/product/automation/foreign-cruise-search.vue' /* webpackChunkName: "pages/product/automation/foreign-cruise-search" */))
const _2d183db6 = () => interopDefault(import('../pages/product/automation/placeorder.vue' /* webpackChunkName: "pages/product/automation/placeorder" */))
const _f29db88e = () => interopDefault(import('../pages/product/automation/placeorder/index.vue' /* webpackChunkName: "pages/product/automation/placeorder/index" */))
const _d08a9638 = () => interopDefault(import('../pages/product/automation/placeorder/select-floor.vue' /* webpackChunkName: "pages/product/automation/placeorder/select-floor" */))
const _f854fc78 = () => interopDefault(import('../pages/product/automation/placeorder/select-room-type.vue' /* webpackChunkName: "pages/product/automation/placeorder/select-room-type" */))
const _47be50eb = () => interopDefault(import('../pages/product/automation/placeorder/visitor-fill.vue' /* webpackChunkName: "pages/product/automation/placeorder/visitor-fill" */))
const _d36b3bf2 = () => interopDefault(import('../pages/product/automation/requirements-agreements.vue' /* webpackChunkName: "pages/product/automation/requirements-agreements" */))
const _612a3dd0 = () => interopDefault(import('../pages/product/cruise/booking.vue' /* webpackChunkName: "pages/product/cruise/booking" */))
const _36477a22 = () => interopDefault(import('../pages/product/cruise/list.vue' /* webpackChunkName: "pages/product/cruise/list" */))
const _593d1d6e = () => interopDefault(import('../pages/product/cruise/pre-booking.vue' /* webpackChunkName: "pages/product/cruise/pre-booking" */))
const _9f368bb2 = () => interopDefault(import('../pages/product/vacation/list.vue' /* webpackChunkName: "pages/product/vacation/list" */))
const _655a0f7a = () => interopDefault(import('../pages/product/vacation/placeholder.vue' /* webpackChunkName: "pages/product/vacation/placeholder" */))
const _69d29736 = () => interopDefault(import('../pages/product/vacation/pre-booking.vue' /* webpackChunkName: "pages/product/vacation/pre-booking" */))
const _1fab46ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ef852fd4 = () => interopDefault(import('../pages/company/article/_articleId.vue' /* webpackChunkName: "pages/company/article/_articleId" */))
const _15d67940 = () => interopDefault(import('../pages/company/cruise/_cruiseId.vue' /* webpackChunkName: "pages/company/cruise/_cruiseId" */))
const _614046ae = () => interopDefault(import('../pages/partner/content/_index.vue' /* webpackChunkName: "pages/partner/content/_index" */))
const _09f060c0 = () => interopDefault(import('../pages/product/automation/_productNo.vue' /* webpackChunkName: "pages/product/automation/_productNo" */))
const _1528f8bc = () => interopDefault(import('../pages/product/cruise/_productNo.vue' /* webpackChunkName: "pages/product/cruise/_productNo" */))
const _da489e4c = () => interopDefault(import('../pages/product/vacation/_productNo.vue' /* webpackChunkName: "pages/product/vacation/_productNo" */))
const _64bfc6ac = () => interopDefault(import('../pages/approval/_approvalId.vue' /* webpackChunkName: "pages/approval/_approvalId" */))
const _0d742f24 = () => interopDefault(import('../pages/company/_companyId.vue' /* webpackChunkName: "pages/company/_companyId" */))
const _0cddbde7 = () => interopDefault(import('../pages/cruise-ranking/_basis.vue' /* webpackChunkName: "pages/cruise-ranking/_basis" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/banner",
    component: _95875770,
    name: "banner"
  }, {
    path: "/company",
    component: _98e5d312,
    name: "company"
  }, {
    path: "/cruise-map",
    component: _d58c6386,
    name: "cruise-map",
    children: [{
      path: "composables/useCompanyList",
      component: _e6de48e0,
      name: "cruise-map-composables-useCompanyList"
    }]
  }, {
    path: "/designer",
    component: _724b8cf8,
    children: [{
      path: "",
      component: _590e8d07,
      name: "designer"
    }, {
      path: "customer",
      component: _6da2708e,
      name: "designer-customer"
    }, {
      path: "customization",
      component: _406292f0,
      name: "designer-customization"
    }, {
      path: "mine",
      component: _1e71c32e,
      name: "designer-mine"
    }, {
      path: "offiaccount",
      component: _1b431bf0,
      name: "designer-offiaccount"
    }, {
      path: "tabbar",
      component: _2e5a54f9,
      name: "designer-tabbar"
    }]
  }, {
    path: "/destination",
    component: _06bff548,
    name: "destination"
  }, {
    path: "/feedback",
    component: _234ea921,
    name: "feedback"
  }, {
    path: "/harbor",
    component: _b62a54b0,
    name: "harbor"
  }, {
    path: "/helpcenter",
    component: _43b9ffaf,
    children: [{
      path: "",
      component: _672dc6f2,
      name: "helpcenter"
    }, {
      path: "help-info",
      component: _a12b044c,
      name: "helpcenter-help-info"
    }, {
      path: "question-answer",
      component: _88ab3bb6,
      name: "helpcenter-question-answer"
    }]
  }, {
    path: "/introduce",
    component: _4dab34d9,
    name: "introduce"
  }, {
    path: "/merchant-center",
    component: _a25a3e1e,
    name: "merchant-center"
  }, {
    path: "/order",
    component: _08f51d05,
    children: [{
      path: "",
      component: _2391e870,
      name: "order"
    }, {
      path: "confirmationsheet",
      component: _1f847440,
      name: "order-confirmationsheet"
    }, {
      path: "touristinformation",
      component: _5dbdf7b4,
      name: "order-touristinformation"
    }, {
      path: "platform/cruiseship",
      component: _888b3eba,
      name: "order-platform-cruiseship"
    }, {
      path: "platform/line",
      component: _66bfbe06,
      name: "order-platform-line"
    }, {
      path: "store/cruiseship",
      component: _c2e3ad0e,
      name: "order-store-cruiseship"
    }, {
      path: "store/line",
      component: _7a0f4148,
      name: "order-store-line"
    }]
  }, {
    path: "/resource",
    component: _29f45d27,
    children: [{
      path: "",
      component: _ad4bab2c,
      name: "resource"
    }, {
      path: "invoice",
      component: _76f89f45,
      children: [{
        path: "",
        component: _9cc043f0,
        name: "resource-invoice"
      }, {
        path: "invoice-apply",
        component: _66d443f8,
        name: "resource-invoice-invoice-apply"
      }, {
        path: "invoice-detail",
        component: _f4a5e9aa,
        name: "resource-invoice-invoice-detail"
      }, {
        path: "invoice-payable-list",
        component: _21af8ee3,
        name: "resource-invoice-invoice-payable-list"
      }, {
        path: "invoice-uninvoiced-order",
        component: _5f046fea,
        name: "resource-invoice-invoice-uninvoiced-order"
      }]
    }, {
      path: "material",
      component: _cd5580fc,
      name: "resource-material"
    }, {
      path: "product",
      component: _2a8f9d4a,
      name: "resource-product"
    }, {
      path: "shop",
      component: _afe7cae4,
      children: [{
        path: "",
        component: _3c91fe91,
        name: "resource-shop"
      }, {
        path: "agent",
        component: _074fc744,
        children: [{
          path: "",
          component: _2912cac7,
          name: "resource-shop-agent"
        }, {
          path: "pending",
          component: _60c79dcc,
          name: "resource-shop-agent-pending"
        }]
      }, {
        path: "income",
        component: _2227ff0c,
        name: "resource-shop-income"
      }, {
        path: "productmanagement",
        component: _a3b9ac9e,
        name: "resource-shop-productmanagement"
      }, {
        path: "withdraw",
        component: _1c2af1ca,
        name: "resource-shop-withdraw"
      }]
    }, {
      path: "study",
      component: _9f41d4b8,
      name: "resource-study"
    }, {
      path: "transaction",
      component: _38ddee76,
      name: "resource-transaction",
      children: [{
        path: "list",
        component: _45e47772,
        name: "resource-transaction-list"
      }, {
        path: "recharge",
        component: _74961560,
        name: "resource-transaction-recharge"
      }, {
        path: "withdrawal",
        component: _4e999c1e,
        name: "resource-transaction-withdrawal"
      }]
    }, {
      path: "messagecenter/order",
      component: _cb54309a,
      name: "resource-messagecenter-order"
    }, {
      path: "messagecenter/store",
      component: _168657c6,
      name: "resource-messagecenter-store"
    }, {
      path: "official-accounts/left-config",
      component: _357486d9,
      name: "resource-official-accounts-left-config"
    }, {
      path: "official-accounts/messageEditor",
      component: _3fcb4b25,
      name: "resource-official-accounts-messageEditor"
    }, {
      path: "official-accounts/officialaccountmenu",
      component: _8b74725c,
      name: "resource-official-accounts-officialaccountmenu"
    }, {
      path: "official-accounts/right-config",
      component: _5f4be3d2,
      name: "resource-official-accounts-right-config"
    }, {
      path: "order/platform",
      component: _00141aac,
      name: "resource-order-platform"
    }, {
      path: "order/store",
      component: _38ee5d78,
      name: "resource-order-store"
    }, {
      path: "product/collectionlist",
      component: _48b1d5f4,
      name: "resource-product-collectionlist"
    }, {
      path: "tools/message",
      component: _154e94eb,
      children: [{
        path: "",
        component: _20347f2e,
        name: "resource-tools-message"
      }, {
        path: "create-temp",
        component: _5717e3e1,
        name: "resource-tools-message-create-temp"
      }, {
        path: "log",
        component: _3dacd1a0,
        name: "resource-tools-message-log"
      }, {
        path: "masstexting",
        component: _9719cc06,
        name: "resource-tools-message-masstexting"
      }, {
        path: "recharge",
        component: _2c2fc27b,
        name: "resource-tools-message-recharge"
      }]
    }, {
      path: "tools/qrcode",
      component: _3e2592dd,
      name: "resource-tools-qrcode"
    }, {
      path: "usercenter/companyinfo",
      component: _717430d8,
      name: "resource-usercenter-companyinfo"
    }, {
      path: "usercenter/coupon",
      component: _adc17d66,
      name: "resource-usercenter-coupon"
    }, {
      path: "usercenter/staffmanagement",
      component: _88acb8e8,
      name: "resource-usercenter-staffmanagement"
    }, {
      path: "usercenter/userinfo",
      component: _dfc7da40,
      name: "resource-usercenter-userinfo"
    }, {
      path: "product/-list/cruise",
      component: _3b070051,
      name: "resource-product--list-cruise"
    }, {
      path: "tools/qrcode/create",
      component: _a37dd8fe,
      name: "resource-tools-qrcode-create"
    }]
  }, {
    path: "/search",
    component: _7e3fe3fe,
    name: "search"
  }, {
    path: "/settled",
    component: _a2e1b11a,
    name: "settled"
  }, {
    path: "/ShopQrFlow",
    component: _286c36fe,
    name: "ShopQrFlow"
  }, {
    path: "/theme-cruise",
    component: _b73c5d16,
    name: "theme-cruise"
  }, {
    path: "/topic",
    component: _37728a66,
    name: "topic",
    children: [{
      path: "ct-jsrcdj",
      component: _69df7733,
      name: "topic-ct-jsrcdj"
    }, {
      path: "rebate-policy",
      component: _1707c39a,
      name: "topic-rebate-policy"
    }, {
      path: "shop-tool",
      component: _35b9c0b4,
      name: "topic-shop-tool"
    }, {
      path: "topic-temp",
      component: _ff7536aa,
      name: "topic-topic-temp"
    }, {
      path: "2021/CenturyLegend-explorer",
      component: _a99c8a58,
      name: "topic-2021-CenturyLegend-explorer"
    }, {
      path: "2021/yangzi-explorer",
      component: _90afbf3a,
      name: "topic-2021-yangzi-explorer"
    }, {
      path: "2022/aden",
      component: _06b526d2,
      name: "topic-2022-aden"
    }, {
      path: "202208/aden",
      component: _9cf70868,
      name: "topic-202208-aden"
    }, {
      path: "202208/hulunbuir",
      component: _aaf419f8,
      name: "topic-202208-hulunbuir"
    }, {
      path: "202208/secretlandscape",
      component: _d3607a0a,
      name: "topic-202208-secretlandscape"
    }, {
      path: "2023/antarctica",
      component: _0850ddf6,
      name: "topic-2023-antarctica"
    }, {
      path: "2023/blue-dream",
      component: _7250d1b7,
      name: "topic-2023-blue-dream"
    }, {
      path: "2023/jsrcdj",
      component: _2810e8b2,
      name: "topic-2023-jsrcdj"
    }, {
      path: "2023/spectrum-of-the-seas",
      component: _4b6f8241,
      name: "topic-2023-spectrum-of-the-seas"
    }]
  }, {
    path: "/user",
    component: _122046e4,
    name: "user"
  }, {
    path: "/company/brand",
    component: _967c52a8,
    name: "company-brand"
  }, {
    path: "/company/cruise-company",
    component: _0cd79ba0,
    name: "company-cruise-company"
  }, {
    path: "/company/cruise-list",
    component: _1ae7f87b,
    name: "company-cruise-list"
  }, {
    path: "/company/cruise-notice",
    component: _43519716,
    name: "company-cruise-notice"
  }, {
    path: "/company/info-recommend",
    component: _4cc745f0,
    name: "company-info-recommend"
  }, {
    path: "/company/information",
    component: _4640a811,
    name: "company-information"
  }, {
    path: "/company/list",
    component: _5fbeba89,
    name: "company-list"
  }, {
    path: "/company/new-ranking-basis",
    component: _2ef2249f,
    name: "company-new-ranking-basis"
  }, {
    path: "/company/old-brand",
    component: _1f4157f4,
    name: "company-old-brand"
  }, {
    path: "/company/original-home",
    component: _2c8e2b80,
    name: "company-original-home"
  }, {
    path: "/company/trade-home",
    component: _06ba8e53,
    name: "company-trade-home"
  }, {
    path: "/customer/list",
    component: _ad632b34,
    name: "customer-list"
  }, {
    path: "/destination/list",
    component: _c78784d0,
    name: "destination-list"
  }, {
    path: "/harbor/city",
    component: _9bd6e8f6,
    name: "harbor-city"
  }, {
    path: "/harbor/product",
    component: _a8f76876,
    name: "harbor-product"
  }, {
    path: "/product/cruise-calendar",
    component: _8527a7e6,
    name: "product-cruise-calendar"
  }, {
    path: "/product/custom-travel",
    component: _6fedc38d,
    name: "product-custom-travel"
  }, {
    path: "/product/end-order-discount",
    component: _010dee0b,
    name: "product-end-order-discount"
  }, {
    path: "/product/holiday-route",
    component: _0b0377aa,
    name: "product-holiday-route"
  }, {
    path: "/product/vacation",
    component: _77a70a99,
    name: "product-vacation"
  }, {
    path: "/settled/process",
    component: _4255e850,
    name: "settled-process"
  }, {
    path: "/company/cruise/century",
    component: _4a9b9076,
    name: "company-cruise-century"
  }, {
    path: "/company/cruise/decknavigation",
    component: _30f4b91c,
    name: "company-cruise-decknavigation"
  }, {
    path: "/company/cruise/gallery",
    component: _b1ad757a,
    name: "company-cruise-gallery"
  }, {
    path: "/company/cruise/location",
    component: _803c32d8,
    name: "company-cruise-location"
  }, {
    path: "/feedback/components/list",
    component: _7f3eadf4,
    name: "feedback-components-list"
  }, {
    path: "/feedback/components/suggest",
    component: _5693ed68,
    name: "feedback-components-suggest"
  }, {
    path: "/product/automation/foreign-cruise-search",
    component: _3f6e6b28,
    name: "product-automation-foreign-cruise-search"
  }, {
    path: "/product/automation/placeorder",
    component: _2d183db6,
    children: [{
      path: "",
      component: _f29db88e,
      name: "product-automation-placeorder"
    }, {
      path: "select-floor",
      component: _d08a9638,
      name: "product-automation-placeorder-select-floor"
    }, {
      path: "select-room-type",
      component: _f854fc78,
      name: "product-automation-placeorder-select-room-type"
    }, {
      path: "visitor-fill",
      component: _47be50eb,
      name: "product-automation-placeorder-visitor-fill"
    }]
  }, {
    path: "/product/automation/requirements-agreements",
    component: _d36b3bf2,
    name: "product-automation-requirements-agreements"
  }, {
    path: "/product/cruise/booking",
    component: _612a3dd0,
    name: "product-cruise-booking"
  }, {
    path: "/product/cruise/list",
    component: _36477a22,
    name: "product-cruise-list"
  }, {
    path: "/product/cruise/pre-booking",
    component: _593d1d6e,
    name: "product-cruise-pre-booking"
  }, {
    path: "/product/vacation/list",
    component: _9f368bb2,
    name: "product-vacation-list"
  }, {
    path: "/product/vacation/placeholder",
    component: _655a0f7a,
    name: "product-vacation-placeholder"
  }, {
    path: "/product/vacation/pre-booking",
    component: _69d29736,
    name: "product-vacation-pre-booking"
  }, {
    path: "/",
    component: _1fab46ee,
    name: "index"
  }, {
    path: "/company/article/:articleId?",
    component: _ef852fd4,
    name: "company-article-articleId"
  }, {
    path: "/company/cruise/:cruiseId?",
    component: _15d67940,
    name: "company-cruise-cruiseId"
  }, {
    path: "/partner/content/:index",
    component: _614046ae,
    name: "partner-content"
  }, {
    path: "/product/automation/:productNo?",
    component: _09f060c0,
    name: "product-automation-productNo"
  }, {
    path: "/product/cruise/:productNo?",
    component: _1528f8bc,
    name: "product-cruise-productNo"
  }, {
    path: "/product/vacation/:productNo",
    component: _da489e4c,
    name: "product-vacation-productNo"
  }, {
    path: "/approval/:approvalId?",
    component: _64bfc6ac,
    name: "approval-approvalId"
  }, {
    path: "/company/:companyId",
    component: _0d742f24,
    name: "company-companyId"
  }, {
    path: "/cruise-ranking/:basis?",
    component: _0cddbde7,
    name: "cruise-ranking-basis"
  }, {
    path: "*",
    redirect: "/"
  }, {
    path: "/company/rank/:rankingBasis?",
    redirect: (to) => {
            const obj = {
              service: 'service_ratio',
              tonnage: 'tonnage',
              capacity: 'passenger_capacity',
              newest: 'first_flight_time'
            };
            return `/cruise-ranking/${obj[to.params.rankingBasis] || ''}`;
          },
    name: "company-rank-rankingBasis"
  }],

  parseQuery: function(...rest) {
  return require("qs").parse(...rest);
},
  stringifyQuery: function(query) {
  return require("qs").stringify(query, { addQueryPrefix: true });
},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
