<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div
      tabindex="0"
      class="v-loading-overlay is-active"
      :class="{ 'is-full-page': isFullPage }"
      v-show="isActive"
      :aria-busy="isActive"
      aria-label="Loading"
      :style="{ zIndex }"
    >
      <div
        class="v-loading-background"
        @click.prevent="cancel"
        :style="bgStyle"
      ></div>
      <div class="v-loading-icon">
        <slot name="before" />
        <div class="v-loading-loader">
          <v-svg-icon class="v-loading-ship" name="ship" />
          <v-svg-icon class="v-loading-spinner" name="loading-spinner" />
        </div>
        <slot name="after" />
      </div>
    </div>
  </transition>
</template>

<script>
import {defineComponent} from 'vue'
// import {removeElement} from './helpers.js'
import trapFocusMixin from "./trapFocusMixin.js";

const removeElement = el => {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else {
    el.parentNode.removeChild(el);
  }
};
export default defineComponent({
  name: "v-loading",
  mixins: [trapFocusMixin],
  props: {
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, window.HTMLElement || {}],
    isFullPage: {
      type: Boolean,
      default: true
    },
    enforceFocus: {
      type: Boolean,
      default: true
    },
    lockScroll: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: "fade"
    },
    /**
     * Allow user to hide the loader
     */
    canCancel: Boolean,
    /**
     * Do something on cancel
     */
    onCancel: {
      type: Function,
      default: () => {}
    },
    color: String,
    backgroundColor: String,
    blur: {
      type: String,
      default: "2px"
    },
    opacity: Number,
    width: Number,
    height: Number,
    zIndex: Number
  },
  data() {
    return {
      // Don't mutate the prop
      isActive: this.active
    };
  },
  beforeMount() {
    // Insert the component in DOM when called programmatically
    if (this.programmatic) {
      if (this.container) {
        this.isFullPage = false;
        this.container.appendChild(this.$el);
      } else {
        document.body.appendChild(this.$el);
      }
    }
  },
  mounted() {
    // Activate immediately when called programmatically
    if (this.programmatic) {
      this.isActive = true;
    }
    document.addEventListener("keyup", this.keyPress);
  },
  methods: {
    /**
     * Proxy to hide() method.
     * Gets called by ESC button or when click outside
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return;
      this.hide();
      this.onCancel.apply(null, arguments);
    },
    /**
     * Hide and destroy component if it's programmatic.
     */
    hide() {
      this.$emit("hide");
      this.$emit("update:active", false);
      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },
    disableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.add("vld-shown");
      }
    },
    enableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.remove("vld-shown");
      }
    },
    /**
     * Key press event to hide on ESC.
     *
     * @param event
     */
    keyPress(event) {
      // todo keyCode is deprecated
      if (event.keyCode === 27) this.cancel();
    }
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
    isActive(value) {
      if (value) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    }
  },
  computed: {
    bgStyle() {
      return {
        background: this.backgroundColor,
        opacity: this.opacity,
        backdropFilter: `blur(${this.blur})`
      };
    }
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.keyPress);
  }
});
</script>
