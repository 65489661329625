<template>
  <modal :classes="['v-dialog']" :name="name" :height="height" v-bind="$attrs">
    <div class="v-dialog__header">
      <slot name="title">
        <span class="v-dialog__title">{{ title }}</span>
      </slot>
      <button v-if="showClose" class="v-dialog__headerbtn" @click="handleClose">
        <i class="ficon ficon-guanbi"></i>
      </button>
    </div>
    <div class="v-dialog__body">
      <slot></slot>
    </div>
    <div class="v-dialog__footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </modal>
</template>

<script>
import { generateId } from "@/utils/helpers.js";
export default {
  name: "VDialog",
  props: {
    name: {
      type: String,
      default: generateId()
    },
    height: {
      type: [String, Number],
      default: "auto"
    },
    title: {
      type: String,
      default: ""
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // this.$modal.show(this.name)
  },
  methods: {
    handleClose() {
      this.$modal.hide(this.name);
    }
  }
};
</script>

<style>

</style>
