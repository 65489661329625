<template>
  <div class="pannel animate__animated animate__fadeIn">
    <div class="form form--base">
      <el-form ref="refForm" :model="formData" :rules="rulesBase">
        <el-form-item class="form__item" prop="mobile">
          <div class="flex items-center form__item__phone">
            <div class="relative form__item__phone__select">
              <el-select v-model="formData.area_code" class="form__item__phone__common w-full" filterable placeholder="请选择" :collapse-tags="false" @change="changeAreaCode">
                <el-option
                  v-for="item in areaCodeList"
                  :key="item.value+item.label"
                  :label="item.label"
                  :value="item.value">
                  <div class="form__item__phone__options">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                  </div>
                </el-option>
              </el-select>
              <div class="absolute top-0 right-28px">{{areaCodeValue}}</div>
            </div>
            <div class="bg-[#BFBFBF] h-24px w-1px"></div>
            <el-input maxlength="11" class="form__item__phone__common form__item__phone__input" style="width: 39%" placeholder="手机号" v-model="formData.mobile"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="form__item" prop="verifyCode">
          <el-input placeholder="计算结果" maxlength="2" v-model="formData.verifyCode">
            <div class="form__varifyimg" slot="suffix">
              <el-image class="form__varifyimg__img" fit="contain" alt="图形验证码" :src="captchaUrl" @click="refreshCode">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item class="form__item" prop="code">
          <el-input placeholder="验证码" maxlength="6" v-model="formData.code">
            <div class="form__varifycode" slot="suffix">
              <span @click.stop="sendCode" v-show="!showVerCode" class="text-dark">发送验证码</span>
              <span v-show="showVerCode">{{verificationText}}</span>
            </div>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form__tip">
      没有账号？
      <span @click="toSettle">马上入驻</span>
    </div>
    <el-button class="form__btn" type="primary" @click="login">登录</el-button>
  </div>
</template>
<script>
import { getUserSendCode, getAreaCode } from "@/services/login";
let Timer = null;

export default {
  auth: "guest",
  components: {},
  data() {
    return {
      formData: {
        mobile: "", //手机号
        verifyCode: "", //图形验证码
        code: "", //短信验证码
        loginType: "2",
        area_code: "+86",
        label: 'CN',
        mobile_prefix: 'CN'
      },
      captchaUrl: "/api/user/captcha",
      verificationText: "获取验证码",
      verificationVal: 60,
      showVerCode: false, //是否倒计时
      rulesBase: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不对",
          //   trigger: "blur",
          // },
        ],
        verifyCode: [
          { required: true, message: "请输入计算结果", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      areaCodeList: [],
      areaCodeValue: '+86'
    };
  },
  created() {
    this._getAreaCode()
  },
  methods: {
    // 图形验证码
    refreshCode() {
      let tempUrl = this.captchaUrl.split("?");
      this.captchaUrl = tempUrl[0] + "?time=" + new Date().getTime();
      this.formData.verifyCode = "";
    },
    login() {
      console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.formData)
            .then((res) => this.$emit("success", res))
            .catch((err) => {
              console.error(err);
              this.$message({
                type: "error",
                text: err,
              });
            });
        }
      });
    },
    // 获取短信验证码
    async sendCode() {
      this._apisendCode = false;
      let validate = this.$refs.refForm.validateField(
        ["mobile", "verifyCode"],
        async (err) => {
          console.log("1err", err);
          if (!err) {
            if (this._apisendCode) return;
            this._apisendCode = true;
            const that = this;
            this.formData.captcha = this.formData.verifyCode; //图形验证码

            that.showVerCode = true;
            that.verificationText = `发送中...`;
            let { data, code, msg } = await getUserSendCode(this.formData);
            this._apisendCode = false;
            if (code === 10000 || code == 200) {
              console.log(data);
              Timer = setInterval(() => {
                that.showVerCode = true;
                that.verificationText = `重新发送(${that.verificationVal})`;
                that.verificationVal--;
                if (that.verificationVal <= 0) {
                  that.showVerCode = false;
                  clearInterval(Timer);
                  Timer = null;
                  that.verificationVal = 60;
                  that.verificationText = `重新发送(60)`;
                }
              }, 1000);
            } else {
              that.showVerCode = false;
              that.verificationVal = 60;
              that.verificationText = `重新发送(60)`;
              this.$message({
                type: "error",
                text: msg,
              });
              this.refreshCode();
            }
          }
        }
      );
    },
    resetFields() {
      this.$refs.refForm.resetFields();
    },
    toSettle() {
      this.resetFields();
      this.$emit("navigateToSettle");
    },

    async _getAreaCode() {
      const {code, data, msg} = await getAreaCode()
      if(code !== 10000) {

      }
      this.areaCodeList = data.map(item => {return {value: item.format_code, label: item.name, label_en: item.label}})
    },
    changeAreaCode(value) {
      this.areaCodeValue = value
      this.areaCodeList.forEach(item => {
        if(item.value === value) {
          this.formData.label = item.label_en
          this.formData.mobile_prefix = item.label_en
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>

::v-deep .form__item__phone__common {
  .el-input__inner {
    border: none !important;
  }
  .el-select-dropdown .el-popper {
    left: 538px !important;
  }
  .el-popper {
    left: 538px !important;
  }
  .el-select-dropdown {
    left: 538px !important;
  }
}
::v-deep .el-select-dropdown .el-popper {
  left: 538px !important;
}

::v-deep {
  .el-popper {
    left: 538px !important;
  }
  .el-select-dropdown {
    left: 538px !important;
  }
}

.form {
  &--base {
    padding-top: 20px;
  }
  &__item {
    margin-bottom: 22px;
    &__phone {
      border: 1px solid #dcdfe6;
      &__select {
        width: 60%;
      }
      &__input {
        width: 39%;
      }
    }
  }
  &__tip {
    padding-top: 12px;
    color: #495057;
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
    span {
      color: $color-primary;
      cursor: pointer;
      user-select: none;
    }
  }
  &__btn {
    width: 100%;
    font-size: 18px;
  }
  &__varifycode {
    color: #adb5bd;
    font-size: 16px;
    padding: 0 10px;
    user-select: none;
    cursor: pointer;
    &__img {
      width: 180px;
      height: 35px;
    }
  }
  &__varifyimg {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    padding-top: 1px;
    &__img {
      width: 180px;
      height: 38px;
    }
  }
}
.el-select-dropdown__item {

  .form__item__phone__options {
    border-bottom: 1px solid #dcdfe6;
  }
  &:first-child {
    .form__item__phone__options {
      border-bottom: none;
    }
  }
}
.el-select-dropdown {
  left: 538px !important;
  background: pink !important;
}
.el-popup-parent--hidden {

  .el-select-dropdown .el-popper {
    left: 538px !important;
    background: pink !important;
  }
}
.form__item__phone__select {}

</style>
