<template>
    <div class="app-topbar">
        <AppLogo />
        <AppSearch class="ml-auto" />
        <el-divider direction="vertical" />
        <AppLanguage />
        <el-divider direction="vertical" />
        <AppUser v-show="loggedIn" />
        <el-button v-show="!loggedIn" class="app-topbar-btn" type="primary" size="small" @click="onLogin">登录</el-button>
        <el-button v-show="!isDistributor" class="app-topbar-btn settled" type="warning" size="small"
            @click="onSettled">成为合作伙伴</el-button>
</div>
</template>

<script>
import { defineComponent, useRouter, useStore, computed } from "@nuxtjs/composition-api";
import AppLogo from "@/components/layouts/AppLogo.vue";
import AppSearch from "./AppSearch.vue";
import AppUser from './AppUser.vue';
import AppLanguage from './AppLanguage.vue';

// ======================= composables =======================
import useLoginDialog from '~/composables/useLoginDialog';

export default defineComponent({
    name: 'AppTopbar',
    components: {
        AppLogo,
        AppSearch,
        AppUser,
        AppLanguage,
    },
    setup() {
        const { showLogin } = useLoginDialog()
        function onLogin() {
            showLogin();
        }

        const router = useRouter();
        function onSettled() {
            router.push('/settled')
        }
        const store = useStore();
        const loggedIn = computed(() => store.getters.loggedIn);
        const isDistributor = computed(() => store.getters.isDistributor);
        return {
            loggedIn,
            isDistributor,

            onLogin,
            onSettled
        }
    }
})
</script>

<style lang="scss">
.app-topbar {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 4.375rem;
  position: relative;
}
.app-topbar-btn {
  width: 7.125rem;
}
.app-topbar-btn.settled {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 178, 3, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(242, 178, 3, var(--tw-border-opacity));
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.app-topbar-btn.settled:hover {
  --tw-bg-opacity: 0.8;
}
</style>