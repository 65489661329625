<template functional>
    <a :href="props.path" :target="props.target" :class="[data.staticClass, data.class]" v-bind="data.attrs">
        <slot></slot>
    </a>
</template>

<script>
import { computed, defineComponent, ref, useRouter, useRoute } from "@nuxtjs/composition-api";
import { isExternal } from "~/utils/helpers";

export default defineComponent({
    props: {
        path: {
            type: String,
            default: ''
        },
        target: String
    },
    // setup(props) {
    //     const tag = computed(() => isExternal(props.path) ? 'a' : 'nuxt-link')
    //     const linkProps = computed(() => isExternal(props.path) ? ({
    //         href: props.path,
    //         target: props.target
    //     }) : ({
    //         // to: props.path,
    //         href: props.path,
    //         target: props.target
    //     }))
    //     return {
    //         tag,
    //         linkProps
    //     }
    // }
})
</script>
