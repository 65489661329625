<template>
    <app-root class="main-layout">
    <AppHeader />
    <!-- 解决 子元素  parentNode 为 null 的问题 -->
    <div>
      <nuxt />
    </div>
    <app-footer v-show="showAppFooter" />

    <LoginDialog />
    <ContactDialog  />
  </app-root>
</template>

<script>
import AppHeader from "~/components/layouts/AppHeader.vue";
import AppLogo from "~/components/layouts/AppLogo.vue";
import AppFooter from "~/components/layouts/AppFooter.vue";
import AppMenu from "~/components/layouts/AppMenu.vue";
import AppBanner from "~/components/layouts/AppBanner.vue";
import AppWechat from "~/components/layouts/AppWechat.vue";
import AppUser from "~/components/layouts/AppUser.vue";
import AppRoot from "~/components/layouts/AppRoot.vue";
import AppDock from "~/components/layouts/AppDock.vue";
import LoginDialog from "~/components/Login/index.vue";

import ContactDialog from "~/components/contact-dialog/index.vue";

import { defineComponent, useCssVars } from "@nuxtjs/composition-api";
import { useContactStore } from "~/composables/useContactDialog";
import useLayout from "~/composables/useLayout";
export default defineComponent({
  name: "MainLayout",
  components: {
    AppHeader,
    AppLogo,
    AppRoot,
    AppMenu,
    AppFooter,
    AppBanner,
    AppUser,
    AppWechat,
    AppDock,
    LoginDialog,
    ContactDialog,
  },
  setup() {
    const { headerSize, showAppFooter } = useLayout();

    useCssVars(() => ({
      "app-header-height": `${headerSize.height}px`,
    }));


    return {
      showAppFooter,
    };
  },
});
</script>
