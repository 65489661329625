import Vue from "vue";
import createAPI from "@/libs/create-api.js";

import Dialog from "@/components/Dialog.vue";
// 店铺装修业务组件
import DialogSelectProduct from "@/views/store-designer/components/DialogSelectProduct.vue";
import DialogSelectProductMultiple from "@/views/store-designer/components/DialogSelectProductMultiple.vue";
import DialogSelectClass from "@/views/store-designer/components/DialogSelectClass.vue";
import DialogSelectCruise from "@/views/store-designer/components/DialogSelectCruise.vue";
import DialogSelectCustomization from '@/views/store-designer/components/DialogSelectCustomization.vue'
// 舱房 详情 dialog
import CabinDialog from "@/views/product/CabinDialog.vue";
import MaterialDialog from "@/views/product/MaterialDialog.vue";

// 视频弹框组件
import VideoDialog from "@/components/VideoDialog.vue";

// 下单优惠券
import DialogCouponOrder from "@/views/product/components/DialogCouponOrder.vue";
import DetailTourImgDIalog from "@/views/product/DetailTourImgDIalog.vue";

// 登录
// import LoginDialog from '~/components/LoginDialog/index.vue'



import VBtn from "@/components/VBtn";
// import VImg from "@/components/VImg";
import VInput from "@/components/VInput";
import VCheckbox from "@/components/VCheckbox";
import VRadio from "@/components/VRadio";
import VTag from "@/components/VTag";
// import { VRow, VCol } from "@/components/VRow";
import VDivider from "@/components/VDivider";
import VInputNumber from "@/components/VInputNumber";
import VDialog from "@/components/VDialog";

import { VForm, VFormItem } from "@/components/VForm/index";
// import VPagination from "@/components/VPagination";
import VTable from "@/components/VTable";
// import { VTabs, VTab } from "@/components/VTabs";
import VNoData from "@/components/VNoData";

// import LoginModal from '@/components/Login'
[
  VBtn,
  VInput,
  // VImg,
  // VRow,
  // VCol,
  VCheckbox,
  VTag,
  VDivider,
  VInputNumber,
  VForm,
  VFormItem,
  VRadio,
  // VPagination,
  VTable,
  // VTabs,
  // VTab,
  VNoData,
  VDialog,
  // LoginModal
].forEach((component) => {
  // console.log(component.name)
  Vue.component(component.name, component);
});
// Vue.component('Login-Modal', LoginModal);

createAPI(Vue, Dialog, ["open", "opened", "close", "closed", "confirm"], true);

createAPI(Vue, DialogSelectProduct, ["close", "closed", 'cancel', "confirm"], false);

createAPI(Vue, DialogSelectProductMultiple, ["close", 'cancel', "closed", "confirm"], false);

createAPI(Vue, DialogSelectClass, ["close", "closed", 'cancel', "confirm"], false);
createAPI(Vue, DialogSelectCruise, ["close", "closed", 'cancel', "confirm"], false);

// 选择自定义页面
createAPI(Vue, DialogSelectCustomization, ["close", "closed", 'cancel', "confirm"], true);

createAPI(Vue, CabinDialog, ['show'], true);
createAPI(Vue, MaterialDialog, ['show'], true);
createAPI(Vue, VideoDialog, ['show'], true);
createAPI(Vue, DialogCouponOrder, ['show'], true);
createAPI(Vue, DetailTourImgDIalog, ['show'], true);


// createAPI(Vue, LoginDialog, ['show', 'success', 'close'], true)
