// import Vue from "vue";
// import Vuex from "vuex";
// import getters from "./getters";
// // Vue.use(Vuex);
// const modulesFiles = require.context("./modules", true, /\.js$/);
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
//   const value = modulesFiles(modulePath);
//   modules[moduleName] = value.default;
//   return modules;
// }, {});

// export default new Vuex.Store({
//   modules,
//   getters
// });

export const state = () => ({
  token: "",

  // 入驻弹窗
  visibleRegisterModal: false,
});

export const getters = {
  userInfo(state) {
    return state.auth.user;
  },

  token(state) {
    return state.token;
  },
  loggedIn(state) {
    return state.auth.loggedIn;
  },
  isAdmin(state, getters) {
    return !!getters.userInfo.is_admin;
  },
  isDistributor(state, getters) {
    return (
      getters.loggedIn && getters.userInfo && !!getters.userInfo.distributor
    );
  },
  isOpenShop(state, getters) {
    return getters.userInfo ? getters.userInfo.shop_open_status > 0 : false;
  },
  isFollow(state, getters) {
    return getters.userInfo ? getters.userInfo.is_follow > 0 : false;
  },
};

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  setVisibleRegisterModal(state, value = !state.visibleRegisterModal) {
    state.visibleRegisterModal = value;
  },

};

export const actions = {
  async nuxtServerInit({ state, dispatch, commit }, context) {
    try {
      await dispatch("layout/nuxtServerInit");
      if (state.auth.loggedIn) {
        commit("SET_TOKEN", this.$auth.strategy.token.get());
      }
      await dispatch("company/initConfig");
    } catch (error) {
      console.error(error);
    }
  },

  async fetchUser() {
    const res = this.$auth.fetchUser();
    console.log("fetchUser", res);
    return this.$auth.fetchUser();
  },
};

// export {
//   modules,
//   getters
// }
