// import { http } from "@/libs/axios.js";
import Vue from 'vue'

const settledApi = {
  screenCondition: "/member/distributor/screen-condition", // 分销商入驻参数获取
  distributorInfo: "/member/distributor/info", // 分销商入驻详细信息获取
  distributorSave: "/member/distributor/save", //入驻分销商信息填写
  city: "/address/search-city" //入驻分销商信息填写
};

// let config = {
//   baseURL: "/api",
//   headers: {
//     "Content-Type": "application/json",
//     token: localStorage.getItem("token")
//   }
// };

// 获取分销商查询条件
export function getScreenCondition(data) {
  return Vue.$axios.$post(settledApi.screenCondition, { data });
}
// 获取分销商信息
export function distributorInfo(data) {
  return Vue.$axios.$post(settledApi.distributorInfo, { data });
}

// 保存分销商信息
export function distributorSave(data = {}) {
  return Vue.$axios.$post(settledApi.distributorSave, { data });
}
// 获取城市
export function getSearchCity(data) {
  return Vue.$axios.$post(settledApi.city, { data });
}
