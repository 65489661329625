<template>
  <label class="v-checkbox" :class="classes">
    <span class="v-checkbox__input" :class="inputClasses">
      <span class="v-checkbox__inner ficon" :class="innerClasses" />
      <input class="v-checkbox__original" type="checkbox" :name="name" :disabled="disabled" :true-value="trueValue"
        :false-value="falseValue" v-model="inputValue" @change="handleChange" @focus="focus = true"
        @blur="focus = false" />
    </span>
    <span class="v-checkbox__label">
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: "VCheckbox",
  props: {
    name: String,
    value: {
      required: true,
      default: null
    },
    val: {},
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    label: [String, Number],
    disabled: Boolean
  },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    isTrue() {
      return this.modelIsArray
        ? this.index > -1
        : this.value === this.trueValue;
    },
    isFalse() {
      return this.modelIsArray
        ? this.index === -1
        : this.value === this.falseValue;
    },
    index() {
      if (this.modelIsArray) {
        return this.value.indexOf(this.val);
      }
      return -1;
    },
    modelIsArray() {
      return this.val !== undefined && Array.isArray(this.value);
    },
    classes() {
      return {
        "is-disabled": this.disabled,
        "is-checkbed": this.isTrue
      };
    },
    inputClasses() {
      return {
        "is-disabled": this.disabled,
        "is-checked": this.isTrue,
        "is-focus": this.focus
      };
    },
    innerClasses() {
      return {
        "ficon-checkbox-active": this.isTrue,
        "ficon-checkbox": this.isFalse
      };
    },
    inputValue: {
      get() {
        return this.modelIsArray ? this.val : this.trueValue;
      },
      set() {
        this.$emit("input", this.$_getNextVlaue());
      }
    }
  },
  created() { },
  methods: {
    $_getNextVlaue() {
      if (this.modelIsArray) {
        if (this.isTrue === true) {
          const val = [...this.value];
          val.splice(this.index, 1);
          return val;
        }
        return this.value.concat([this.val]);
      }

      if (this.isTrue) return this.falseValue;
      if (this.isFalse) return this.trueValue;

      return this.falseValue;
    },
    handleChange(event) {
      let value;
      if (event.target.checked) {
        value = this.trueValue === "undefined" ? true : this.trueValue;
      } else {
        value = this.falseValue === "undefined" ? false : this.falseValue;
      }

      this.$emit("change", value, event);
    }
  }
});
</script>

<style lang="scss">



:root {
  //   $checkbox-text-color: $important-text-color;
  // $checkbox-font-weight: 400;
  // $font-size-base: 14px;
  // // $checkbox-checked-background-color: 
  // $checkbox-checked-text-color: $theme-color;
  // // $checkbox-checked-input-fill: $theme-color;
  // // $checkbox-checked-input-fill: $theme-color;

  // $checkbox-disabled-input-fill: $color-grey-6;
  // $checkbox-disabled-checked-input-fill: $color-grey-6;
  // $checkbox-disabled-checked-input-border-color:$color-grey-6;

  --v-checkbox-text-color: #1F2129;

  --v-checkbox-font-weight: 400;
  --v-checkbox-font-size-base: 16px;
  // $checkbox-checked-background-color: 
  --v-checkbox-checked-text-color: #{ $theme-color };
  // $checkbox-checked-input-fill: $theme-color;
  // $checkbox-checked-input-fill: $theme-color;

  --v-checkbox-disabled-input-fill: #{ $color-grey-6 };
  --v-checkbox-disabled-checked-input-fill: #{ $color-grey-6 };
  --v-checkbox-disabled-checked-input-border-color: #{$color-grey-6};
}
.v-checkbox {
  color: var(--v-checkbox-text-color);
  font-weight: var(--v-checkbox-font-weight);
  font-size: var(--v-checkbox-font-size-base);
  
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  margin-right: 30px;
  &-input {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    // vertical-align: middle;
    &.is-disabled{
      .v-checkbox__inner {
        // background-color: $checkbox-disabled-input-fill;
        color: $color-grey-7;
        cursor: not-allowed;
      }

      &::after {
        cursor: not-allowed;
      }

      &+span.v-checkbox__label {
        cursor: not-allowed;
        color: var(--v-checkbox-disabled-input-fill);
      }

      &.is-checked {
        .v-checkbox__inner {
          color: $color-grey-7;
          // background-color: $checkbox-disabled-checked-input-fill;
          // border-color: $checkbox-disabled-checked-input-border-color;
        }
      }

      &+span.v-checkbox__label {
        // color: ;
        cursor: not-allowed;
      }
    }

    &.is-checked {

      &+.v-checkbox__label,
      .v-checkbox__inner {
        color:  var(--v-checkbox-disabled-input-fill);
      }
    }
  }

  &--inner {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    // font-size: 1em;
    width: 15px;
    height: 15px;
    // border: $default-border;
    // padding: 3px;
    color: #BBBBC9;
  }

  &__original {
    opacity: 0;
    outline: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -99;
  }
  &__label{
    display: inline-block;
    padding-left: 10px;
    line-height: 20px;
    font-size: var(--v-checkbox-font-size-base);
  }

  &:last-of-type {
    margin-right: 0;
  }

}

</style>
