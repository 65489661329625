<template functional>
  <svg
    class="svg-icon"
    :class="[data.staticClass, props.customClass]"
    v-bind="data.attrs"
    aria-hidden="true"
    v-on="$listeners"
  >
    <use :xlink:href="`#icon-${props.name}`" />
  </svg>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      default: "",
      required: true
    },
    customClass: String
  }
});
</script>

<style scoped>

.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

</style>
