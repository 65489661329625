<template>
  <div class="video">
    <template v-if="productList.length">
      <div class="video__wrapper">
        <ul>
          <li class="" v-for="(video, videoIndex) in productList" :key="videoIndex" @click.stop="showVideoDialog(video.video)">
            <div class="video__head">
              <el-image class="video__cover" fit="cover" :src="video.video_cover"></el-image>
              <div class="video__date">
                <i class="ficon ficon-shijian1" />
                <span>{{video.create_time}}</span>
              </div>
              <div class="video__head__icon">
                <i class="ficon ficon-video-play"></i>
              </div>
            </div>
            <p class="line-clamp-2 video__name">{{video.title}}</p>
            <!-- is_download 是否可下载 1可下载 2不可下载 -->
            <div class="video__btn" v-if="video.is_download == '1'" @click.stop="downloadVideo(video.video, 'test.mp4')">视频下载</div>
            <div class="video__btn video__btn--disabled" v-else>视频不可下载</div>
            <!-- <VPlayer :src="video.video"></VPlayer> -->
          </li>
        </ul>
      </div>
      <div class="pagination__wrapper" v-if="pages.total_page > 1">
        <el-pagination :currentPage="pages.page_index" :total="pages.total_count" :pageSize="pages.page_size" @current-change="handleCurrentPageChange" />
      </div>
    </template>
    <el-empty v-if="!productLoading && !productList.length" description="暂无数据"></el-empty>
  </div>
</template>

<script>
// import VPlayer from "@/components/VPlayer/index.vue";
// apis
import { geMaterialVideoList } from "@/services/material";
// const
import { REQUEST_CODES } from "@/constants/const-http";
// uses composables
import useRequest from "@/composables/useRequest";
import { downloadVideo } from "@/utils/file-download.js";

function createDefaultPages() {
  return {
    total_count: 1,
    page_index: 1,
    page_size: 10,
    page_count: 1,
    total_page: 1,
  };
}

export default {
  name: "MaterrialVideo",
  props: ["classifyId", "productNo"],
  components: {
    // VPlayer,
  },
  data() {
    return {
      productList: [],
      searchData: {},
      pages: createDefaultPages(),
      productLoading: true,
    };
  },
  mounted() {
    this.handleSearch();
  },
  watch: {
    classifyId() {
      this.handleSearch();
    },
    productNo(no) {
      no && this.handleSearch();
    },
  },
  methods: {
    downloadVideo,
    async handleSearch() {
      this.productLoading = true;
      let searchResult = await this._getProductList();
      this.productLoading = false;

      if (!searchResult.list.length) {
        this.noSearch = true;
        // return
      } else {
        this.noSearch = false;
      }
      this.setSearchResult(searchResult);
    },
    async _getProductList() {
      let { code, data, msg } = await geMaterialVideoList({
        classify_id: this.classifyId,
        product_no: this.productNo,
        page_index: this.pages.page_index,
        page_size: this.productNo ? 6 : this.pages.page_size,
      });

      if (code !== REQUEST_CODES.ERROR_OK) {
        this.$message.error(msg);
        return { list: [], ...createDefaultPages() };
      }
      return Promise.resolve(data);
    },
    async handleCurrentPageChange(page_index) {
      this.pages.page_index = page_index;
      this.productLoading = true;
      let result = await this._getProductList();
      this.productLoading = false;
      this.setSearchResult(result);
    },
    setSearchResult(data = {}) {
      console.log(data);
      let {
        page_count,
        page_index,
        page_size,
        total_count,
        total_page,
        list = [],
      } = data;
      Object.assign(this, {
        pages: {
          page_count,
          page_index,
          page_size,
          total_count,
          total_page,
        },
        productList: list,
      });
    },
    showVideoDialog(src) {
      this.$createVideoDialog({
        $props: { src },
      }).show();
    },
  },
};
</script>
<style lang="scss" scoped>

// @import "~@/assets/scss/base/public.scss";
.video {
  &__wrapper {
    width: 100%;
    overflow: hidden;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 1100px;
    }
    li {
      width: 248px;
      margin-right: 17px;
      margin-bottom: 40px;
    }
  }
  &__head {
    // width: 248px;
    width: 100%;
    height: 183px;
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      .ficon {
        color: rgba(0, 0, 0, 0.6);
        font-size: 30px;
      }
    }
  }
  &__cover {
    // width: 248px;
    width: 100%;
    height: 183px;
    // background-color: red;
    font-size: 0;
  }
  &__name {
    color: #212529;
    font-size: 14px;
    margin-bottom: 8px;
    min-height: 42px;
    padding: 0 2px;
  }
  &__date {
    display: flex;
    align-items: center;
    position: absolute;
    height: 20px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 12px;
    padding: 0 10px;
    left: 7px;
    bottom: 7px;
    .ficon {
      margin-right: 5px;
    }
  }
  &__btn {
    display: inline-flex;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    color: #495057;
    font-size: 12px;
    border-radius: 30px;
    border: 1px solid #ced4da;
    cursor: pointer;
    user-select: none;
    margin-left: 2px;
    padding: 5px 10px;
    &--disabled {
      color: #ced4da;
      border-color: #dce1e6;
    }
  }
}

</style>
