<template>
  <div class="poster" ref="poster">
    <img class="poster__img" :src="img">
    <img class="poster__qrcode" :src="qrcode">
  </div>
</template>

<script>
import { Loading } from "element-ui";
import Html2canvas from "@/libs/html2canvas.js";
import { downloadImg } from "@/utils/file-download.js";
import { getMaterialProductqr } from "@/services/qrcode.js";

export default {
  name: "PosterQrcode",
  data() {
    return {
      img: "",
      qrcode: "",
    };
  },
  methods: {
    async download(data = {}) {
      if (this._lock) return;
      let loadingInstance = Loading.service({
        lock: true,
      });
    //   const res = await getMaterialProductqr({ id: data.id });
      const res = await getMaterialProductqr({ id: data.id });
      if (res.code != 10000) {
        loadingInstance.close();
        this.$message.error(res.msg);
        return;
      }

      this._lock = true;
      this.img = data.img;
      this.qrcode = res.data;
      this.$nextTick(async () => {
        const canvas = await Html2canvas(this.$refs.poster, {
          allowTaint: true,
          useCORS: true,
          scrollY: 0,
          scrollX: 0,
          scale: 2,
        }).catch((err) => {
          this._lock = false;
          loadingInstance.close();
        });
        const radom = Math.random();

        const imgData = canvas.toDataURL("image/jpeg");
        downloadImg(imgData, `poster_${radom}.jpeg`);

        this._lock = false;
        loadingInstance.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.poster,
.poster__img {
  width: 750px;
  height: 1334px;
}
.poster {
  position: fixed;
  left: -100vw;
  top: -10vh;
  &__qrcode {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 28px;
    bottom: 28px;
  }
}

</style>