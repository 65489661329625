import type { ResultData } from "~/types/axios";
import { useAsyncState } from "@vueuse/core";
// import type { UseAsyncStateOptions } from "@vueuse/core";

import { REQUEST_CODES } from "@/constants/const-http.js";
import { noop } from "~/utils/helpers";

class RequestError extends Error {
  code: number
  constructor(message = "", code = 10001) {
    super(message);
    this.code = code;
    this.name = "RequestError";
  }
}


interface UseRequestStateOptions<Shallow extends boolean, D = any> {
  /**
   * Delay for executing the promise. In milliseconds.
   *
   * @default 0
   */
  delay?: number;
  /**
   * Execute the promise right after the function is invoked.
   * Will apply the delay if any.
   *
   * When set to false, you will need to execute it manually.
   *
   * @default true
   */
  immediate?: boolean;
  /**
   * Callback when error is caught.
   */
  onError?: (e: unknown) => void;
  /**
   * Callback when success is caught.
   * @param {D} data
   */
  onSuccess?: (data: D) => void;
  /**
   * Sets the state to initialState before executing the promise.
   *
   * This can be useful when calling the execute function more than once (for
   * example, to refresh data). When set to false, the current state remains
   * unchanged until the promise resolves.
   *
   * @default true
   */
  resetOnExecute?: boolean;
  /**
   * Use shallowRef.
   *
   * @default true
   */
  shallow?: Shallow;
  /**
   *
   * An error is thrown when executing the execute function
   *
   * @default false
   */
  throwError?: boolean;
}

export type UseRequestService<TData, TParams extends unknown[]> = (
  ...args: TParams
) => Promise<TData>;


export default function useRequest<
  Data,
  Params extends any[],
  Shallow extends boolean = true
>(
  service: UseRequestService<ResultData<Data>, Params>,
  initialState: Data,
  options: UseRequestStateOptions<Shallow, Data> = {}
) {
  const {
    shallow = true,
    immediate = true,
    resetOnExecute = true,
    throwError = false,
    onError = (err) => console.error(err),
    onSuccess = noop
  } = options
  return useAsyncState(
    async (...rest: Params) => {
      let { code, data, msg } = await service(...rest);
      if (code !== REQUEST_CODES.ERROR_OK) {
        throw new Error(msg);
      }
      return data;
    },
    initialState,
    {
      shallow,
      immediate,
      resetOnExecute,
      onError,
      onSuccess,
      throwError,
    }
  );
}
