// import { http } from "@/libs/axios.js";
import Vue from "vue";

export const API = {
  // 公司信息
  editCompanyInfo: "/product/personal/company-edit", //编辑公司信息
  companyInfo: "/product/personal/company-info", //获取公司信息
  // 账号信息
  userBaseInfo: "/product/personal/account-info", //获取用户基本信息
  editUserInfo: "/product/personal/account-edit", //修改账号和手机号
  editPssword: "/product/personal/account-edit-pass", //修改密码
  wxCode: "/product/personal/account-qrcode", //关联微信二维码
  fldyQrcode: "/product/personal/fldy-qrcode", // 返利政策  提醒二维码

  // 员工管理
  staffList: "/product/personal/staff-list", //获取员工管理列表
  addStaff: "/product/personal/staff-add", //添加员工
  contactStatus: "/product/personal/contact-status", // 是否暴漏员工手机号
  editStaffPassword: "/product/personal/staff-edit-pass", //员工修改密码
  editStaff: "/product/personal/staff-edit", //员工信息修改
  disableStaff: "/product/personal/staff-enable", //员工禁用-开启
  // 我的收藏
  favoritesList: "/product/personal/favorites-list", //我的收藏列表
  deleteFavorites: "/product/personal/favorites-del", //删除收藏
  productList: "/product/personal/favorites-recommend", //查看相似产品

  // 审批单
  approvalList: "/product/personal/approval-list", //审批单列表
  exportApprovalList: "/member/shop/agent-list-export",
  withdrawApproval: "/product/personal/approval-cancel", //审批单撤回
  approvalInfo: "product/personal/approval-info", // 审批单-审批详情

  // 消息中心
  messageList: "member/message/list", // 消息列表
  messageScreenCondition: "member/message/screen-condition", // 筛选条件
  messageRead: "member/message/read", // 消息 读取
  messageReadAll: "member/message/read-all", // 消息 读取 all
  messageReadDelete: "member/message/delete", // 消息 删除

  //店铺管理
  shopInfo: "/shop/manage/shop-info", //店铺详情
  shopSave: "/shop/manage/shop-save", //店铺保存

  shopFitmentJumpType: '/shop/manage/fitment-jump-type',

  shopProductList: "/shop/manage/product-list", //店铺产品列表
  shopProductShelves: "shop/manage/product-change",
  shopProductTop: "/member/shop/product-top",
  shopProductRemove: "/shop/manage/product-remove", //移除店铺产品
  shopProductView: "/shop/manage/product-view-list", //产品访问记录
  shopProductAdjust: "/member/shop/product-proportion",
  shareProduct: "/shop/manage/product-share", //分享产品
  cityAddress: "/address/search-city", //获取地址
  shopRecorded: "/member/shop/recorded",
  shopAgent: "/member/shop/agent-list",
  agentApproval: "/member/shop/agent-approval",
  agentSaleList: "/member/shop/sale-list",
  shopUnFreeze: "member/shop/release-commission",
  shopFreeze: "member/shop/frozen-commission",
  shopOrderCommissionLog: "member/shop/order-commission-log", // 返佣详情记录
  withdrawalList: "member/shop/withdrawal-list",
  withdrawalProcess: "member/shop/withdrawal-process",
  withdrawal: '/member/distributor/withdraw',
  disWithdrawalList:'/member/distributor/withdraw-list',

  adjustCommission:"/member/shop/adjust-commission",//调整收益
  upload:"/upload",//店铺装修--自定义页面编辑上传接口
  customPageList:"/shop/manage/fitment-custom-page-list",//店铺装修--自定义页面列表
  customPageInfo:"/shop/manage/fitment-custom-page-info",//店铺装修--自定义页面信息
  customPageDelete:"/shop/manage/fitment-custom-page-del",//店铺装修--自定义页面删除
  customPageBase:"/shop/manage/fitment-custom-page-base",//店铺装修--自定义页面保存信息
  MineNavInfo:"/shop/manage/fitment-mine-nav-info",//店铺装修--我的 二级页面入口
  MineNavSave:"/shop/manage/fitment-mine-nav-save",//店铺装修--我的 二级页面保存
  ShopIcon: '/shop/manage/shop-icon', //店铺装修--店铺图标

  // S优惠券
  couponList: "member/coupon/list",

  // 公众号菜单
  wechatMenuCreate: "/product/personal/wechat-menu-create",
  wechatMenu: "/product/personal/wechat-menu",
  wechatMaterial: "/product/personal/wechat-material",
  getMediaByUrl: "/product/personal/wechat-upload-img",
  getUrlByMedia: "/product/personal/wechat-get-material",
  getArticleByid: "/product/personal/wechat-get-article",

  // 帮助中心
  getHelpCenter: "/product/product/help-center",
};

const config = {
  // baseURL: "/api",
  // headers: {
  //   "Content-Type": "application/json",
  //   token: localStorage.getItem("token")
  // }
};

// 基本信息 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000689
const userBaseInfo = (data = {}) => {
  return Vue.$axios.$post(API.userBaseInfo, {
    data
  }, config);
};
// 修改账号和手机号 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000690
const editUserInfo = (data = {}) => {
  return Vue.$axios.$post(API.editUserInfo, {
    data
  }, config);
};
// 修改密码 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000691
const editPssword = (data = {}) => {
  return Vue.$axios.$post(API.editPssword, {
    data
  }, config);
};
// 获取微信二维码 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000692
const getWXCode = (data = {}) => {
  return Vue.$axios.$post(API.wxCode, {
    data
  }, config);
};

const getfldyQrcode = () => Vue.$axios.$post(API.fldyQrcode)

// 获取公司信息 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000704
const getCompanyInfo = (data = {}) => {
  return Vue.$axios.$post(API.companyInfo, {
    data
  }, config);
};
// 编辑公司信息 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000705
const editCompanyInfo = (data = {}) => {
  return Vue.$axios.$post(API.editCompanyInfo, {
    data
  }, config);
};
// 获取员工列表 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000700
const getStaffList = (data = {}) => {
  return Vue.$axios.$post(API.staffList, {
    data
  }, config);
};
// 员工修改密码 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000701
const editStaffPassword = (data = {}) => {
  return Vue.$axios.$post(API.editStaffPassword, {
    data
  }, config);
};
// 添加员工 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000699
const addStaff = (data = {}) => {
  return Vue.$axios.$post(API.addStaff, {
    data
  }, config);
};
// 员工信息修改 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000702
const editStaff = (data = {}) => {
  return Vue.$axios.$post(API.editStaff, {
    data
  }, config);
};
// 员工禁用-启用 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000703
const disableStaff = (data = {}) => {
  return Vue.$axios.$post(API.disableStaff, {
    data
  }, config);
};

// 员工禁用-启用 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000672
const getFavoritesList = (data = {}) => {
  return Vue.$axios.$post(API.favoritesList, {
    data
  }, config);
};

// 删除收藏 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000673
const PostDeleteFavorites = (data = {}) => {
  return Vue.$axios.$post(API.deleteFavorites, {
    data
  }, config);
};

// 获取相似产品 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000685
const getProductList = (data = {}) => {
  return Vue.$axios.$post(API.productList, {
    data
  }, config);
};

// 审批单列表 https://www.tapd.cn/62735598/markdown_wikis/show/#1162735598001000707
const getApprovalList = (data = {}) => {
  return Vue.$axios.$post(API.approvalList, {
    data
  }, config);
};

// 审批单撤回
const postWithdrawApproval = (data = {}) => {
  return Vue.$axios.$post(API.withdrawApproval, {
    data
  }, config);
};

export const postWithdrawal = (data) => {
  return Vue.$axios.$post(API.withdrawal, { data });
}

export const getDisWithdrawalList = (data) => {
    return Vue.$axios.$post(API.disWithdrawalList, { data });
}

// 审批单详情
export const getApprovalInfo = (data = {}) =>
  Vue.$axios.$post(API.approvalInfo, {
    data
  }, config);

// 消息列表
export const getMessageList = (data = {}) =>
  Vue.$axios.$post(API.messageList, {
    data
  }, config);

// 消息列表 筛选条件
export const getMessageScreenCondition = (data = {}) =>
  Vue.$axios.$post(API.messageScreenCondition, {
    data
  }, config);

// 消息 读取
export const messageRead = (data = {}) =>
  Vue.$axios.$post(API.messageRead, {
    data
  }, config);

// 消息 读取 all
export const messageReadAll = (data = {}) =>
  Vue.$axios.$post(API.messageReadAll, {
    data
  }, config);

// 消息 删除
export const messageDelete = (data = {}) =>
  Vue.$axios.$post(API.messageReadDelete, {
    data
  }, config);

//店铺管理
//店铺详情
export const getShopInfo = (data = {}) =>
  Vue.$axios.$post(API.shopInfo, { data }, config);

  // 店铺装修跳转类型
export function getShopFitmentJumpType() {
  return Vue.$axios.$post(API.shopFitmentJumpType)
}

// 获取店铺tabbar
export function getShopTabbar(){
  return Vue.$axios.$post('/shop/manage/fitment-bottom-button-info')
}
/**
 *
 * @param {object} data
 * @param { array } data.button
 * @returns {{ Promise<any> }}
 */
export function saveShopTabbar(data){
  return Vue.$axios.$post('/shop/manage/fitment-bottom-button-save', {data})
}

//店铺保存
export const saveShop = (data = {}) =>
  Vue.$axios.$post(API.shopSave, {
    data
  }, config);
//获取店铺产品列表
export const getShopProductList = (data = {}) =>
  Vue.$axios.$post(API.shopProductList, {
    data
  }, config);

export const updateShopProductShelvesStatus = (data = {}) =>
  Vue.$axios.$post(API.shopProductShelves, {
    data
  }, config);

/**
 *
 * @param {object} data
 * @param {string} data.member_id - 用户id
 * @param {string | number} data.contact_view_status - 1展示 0 隐藏
 * @returns
 */
export function updateContactStatus(data = {}) {
  return Vue.$axios.$post(API.contactStatus, {
    data
  })
}
//移除店铺产品
export const removeProduct = (data = {}) =>
  Vue.$axios.$post(API.shopProductRemove, {
    data
  }, config);
//产品访问记录
export const getProductView = (data = {}) =>
  Vue.$axios.$post(API.shopProductView, {
    data
  }, config);
//分享商品
export const shareProduct = (data = {}) =>
  Vue.$axios.$post(API.shareProduct, {
    data
  }, config);
//获取地址
export const getCity = (data = {}) =>
  Vue.$axios.$post(API.cityAddress, {
    data
  }, config);

export function getShopRecorded(data) {
  return Vue.$axios.$post(API.shopRecorded, {
    data
  });
}

export function getShopAgent(data) {
  return Vue.$axios.$post(API.shopAgent, {
    data
  });
}
export function exportApprovalList(data = {}) {
  return Vue.$axios.$post(API.exportApprovalList, {
    data
  });
}
//分销管理员调整收益
export function getAdjustCommission(data = {}) {
  return Vue.$axios.$post(API.adjustCommission, {
    data
  });
}

export function getShopAgentSaleList() {
  return Vue.$axios.$post(API.agentSaleList);
}

export function submitAgentApproval(data) {
  return Vue.$axios.$post(API.agentApproval, {
    data
  });
}

export function relieveUnFreezeRebate(data = {}) {
  return Vue.$axios.$post(API.shopUnFreeze, {
    data
  });
}

export function relieveFreezeRebate(data = {}) {
  return Vue.$axios.$post(API.shopFreeze, {
    data
  });
}

export function getShopOrderCommissionLog(data = {}) {
  return Vue.$axios.$post(API.shopOrderCommissionLog, {
    data
  });
}

export function getWithdrawalList(data = {}) {
  return Vue.$axios.$post(API.withdrawalList, {
    data
  });
}

export function withdrawalProcess(data = {}) {
  return Vue.$axios.$post(API.withdrawalProcess, {
    data
  });
}

export function updateProductTop(data = {}) {
  return Vue.$axios.$post(API.shopProductTop, {
    data
  });
}

// 设置产品价格比例
export function updateProductPrice(data = {}) {
  return Vue.$axios.$post(API.shopProductAdjust, {
    data
  });
}

// 个人中心-优惠券列表
export function getCouponList(data = {}) {
  return Vue.$axios.$post(API.couponList, {
    data
  });
}

// 个人中心-获取公众号菜单
export function getWechatMenu() {
  return Vue.$axios.$post(API.wechatMenu);
}

// 个人中心-编辑公众号菜单
export function updateWechatMenu(button) {
  return Vue.$axios.$post(API.wechatMenuCreate, {
    data: {
      button
    }
  });
}

// 获取公众号素材
export function getWechatMaterial(data = {}) {
  return Vue.$axios.$post(API.wechatMaterial, {
    data
  });
}

// 通过上传路径换取素材id
export function getMediaByUrl(data) {
  return Vue.$axios.$post(API.getMediaByUrl, {
    data
  })
}

// 通过素材id获取素材
export function getUrlByMedia(data) {
  return Vue.$axios.$post(API.getUrlByMedia, {
    data
  })
}

// 获取图文详情
export function getArticleByid(data){
  return Vue.$axios.$post(API.getArticleByid,{
    data
  })
}


// 店铺装修-自定义页面列表
export function getCustomPageList(data = {}) {
  return Vue.$axios.$post(API.customPageList, { data });
}
// 店铺装修-自定义页面信息
export function getCustomPageInfo(data = {}) {
  return Vue.$axios.$post(API.customPageInfo, { data });
}
// 店铺装修-自定义页面列表
export function setDeleteCustomPage(data = {}) {
  return Vue.$axios.$post(API.customPageDelete, { data });
}
// 店铺装修-自定义页面保存
export function setSaveCustomPage(data = {}) {
  return Vue.$axios.$post(API.customPageBase, { data });
}

// 店铺装修-我的导航
export function getMineNavInfo(data = {}) {
  return Vue.$axios.$post(API.MineNavInfo, { data });
}
// 店铺装修-我的导航保存
export function setSaveMineNav(data = {}) {
  return Vue.$axios.$post(API.MineNavSave, { data });
}

// 店铺装修-店铺图标
export function getShopLogo(data = {}) {
  return Vue.$axios.$post(API.ShopIcon, { data });
}

// 店铺装修上传图片
export function setUpload(data = {}) {
  return Vue.$axios({
    method: "post",
    url:API.upload,
    data,
    headers: {
      'Content-Type':'multipart/form-data'
    }
  });
}

// 帮助中心
export function getHelpCenter() {
  return Vue.$axios.$post(API.getHelpCenter);
}


export {
  getWXCode,
  editPssword,
  editUserInfo,
  userBaseInfo,
  getCompanyInfo,
  editCompanyInfo,
  getStaffList,
  editStaffPassword,
  addStaff,
  getfldyQrcode,
  editStaff,
  disableStaff,
  getProductList,
  PostDeleteFavorites,
  getFavoritesList,
  getApprovalList,
  postWithdrawApproval,
};
