<template>
  <el-dialog title="选择产品" :visible.sync="dialogVisible" width="1100px" @close="$emit('close')">
    <div class="dialog__content">
      <div class="search">
        <div>
          <el-form inline>
            <el-form-item label="产品名称" label-width="5em">
              <el-input v-model="form.name" placeholder="请输入产品名称" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="产品分类" label-width="5em">
              <el-select v-model="form.product_type" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-button type="primary" @click="onSearch">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="tableData" style="width: 100%" height="550px">
            <el-table-column prop="name" label="产品名称">
            </el-table-column>
            <el-table-column prop="type_label" label="分类" width="100">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <span class="btn__add" :class="{ 'btn__add--diabled': !enabledAdd}" @click="addProduct(scope)" v-show="_showBtnAdd(scope.row)">添加</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div></div>
      </div>
      <div class="selectedwrapper">
        <h1 class="selectedwrapper__title">已选产品</h1>
        <el-table :data="tableSeletes" style="width: 100%" height="550px">
          <el-table-column prop="name" label="产品名称">
          </el-table-column>
          <el-table-column prop="address" label="操作" width="50">
            <template slot-scope="scope">
              <span class="btn__del" @click="delSeleted(scope.$index)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <el-pagination v-show="form.total_page > 1" background layout="prev, pager, next" :current-page.sync="form.page_index" :page-count="form.total_page" @current-change="_getShopProductList"></el-pagination>
      </div>
      <div>
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { REQUEST_CODES } from "@/constants/const-http.js";

import { getShopProductList, shareProduct } from "@/services/user";

export default {
  name: 'DialogSelectProductMultiple',
  components: {},
  props: {
    default: { // 默认选中的 产品列表
      type: Array,
      default: () => []
    },
    max: { // 最多能添加的产品数量
      type: Number,
      default: 999
    },
    share_url: { // 是否获取分享的H5链接
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        product_type: "",
        name: "",
        page_index: 1,
        total_page: 1,
      },
      options: [
        { value: "1", label: "邮轮" },
        { value: "2", label: "旅游度假" },
      ],
      tableData: [],
      tableSeletes: [],
      dialogVisible: false,
    };
  },
  computed: {
    enabledAdd() {
      return this.tableSeletes.length < this.max
    },
  },
  mounted() {
    this.tableSeletes = this.default || []
    this._getShopProductList()
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    addProduct(scope) {
      if (this.enabledAdd) {
        this.tableSeletes.unshift(scope.row);
      }
    },
    delSeleted(delIndex) {
      this.tableSeletes.splice(delIndex, 1)
    },
    async onConfirm() {
      if (!this.tableSeletes.length) {
        return this.$message.error("请选择产品");
      }
      if (this.share_url) {
        const productNos = this.tableSeletes.map(({ product_no }) => product_no)
        const { code, data, msg } = await shareProduct({product_no: productNos})
        if (code != 10000) return this.$message.error(msg)
        if (code == 10000) {
          this.$emit('confirm', this.tableSeletes, data)
          this.dialogVisible = false;
        }
      } else {
        this.$emit('confirm', this.tableSeletes)
        this.dialogVisible = false;
      }
    },
    onSearch() {
      this._getShopProductList();
    },
    onClose() {
      this.dialogVisible = false;
      this.$emit('cancel');
    },
    _showBtnAdd(row) {
      return !this.tableSeletes.some(item => item.product_no == row.product_no)
    },
    async _getShopProductList() {
      const { code, data, msg } = await getShopProductList({
        ...this.form,
      });
      if (code !== REQUEST_CODES.ERROR_OK) {
        throw new Error(msg);
      }
      this.tableData = data.list;
      this.form.total_page = data.total_page;
    },
  },
};
</script>
<style lang="scss" scoped>

.product {
  padding: 14px;
  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #2b2b36;
    margin-bottom: 12px;
  }
}
.placeholder {
  &__item {
    width: 50%;
    padding: 0 5px;
    display: inline-block;
    vertical-align: top;
    &__img {
      height: 164px;
      background-color: #e9ebf3;
      border-radius: 4px;
      margin-bottom: 13px;
    }
    &__name {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
    &__price {
      color: #b2b2bd;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog__content {
  display: flex;
  .search {
    width: 650px;
  }
}
.selectedwrapper {
  flex: 1;
  margin-left: 10px;
  position: relative;
  padding-top: 62px;
  &__title {
    position: absolute;
    top: -52px;
    font-size: 18px;
    font-weight: 500;
    color: #2b2b36;
  }
}
.btn__add,
.btn__del {
  color: #0c6ede;
  color: 14px;
  user-select: none;
  cursor: pointer;
}
.btn__add{
  &--diabled {
    color: #ccc;
    cursor: default;
  }
}

</style>