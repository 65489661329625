<template>
  <ValidationObserver v-slot="{ handleSubmit }" :tag="tag" :class="formData" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <slot></slot>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "@/libs/veeValidate/index";

export default {
  name: "VForm",
  props: {
    tag: {
      type: String,
      default: () => "div"
    },
    formData: {
      type: String,
      default: () => "formData"
    }
  },
  data() {
    return {};
  },
  methods: {
    getForm() {
      return this.$refs.form;
    },
    onSubmit() {
      this.$emit("submit");
    }
  },
  components: {
    ValidationObserver
  }
};
</script>

<style lang="scss" scoped>



</style>
