export const ERROR_OK = 10000;
export const REQUEST_CODES = {
  /** 未登录 */
  NO_LOGIN: 10002,
  ERROR_OK: 10000
};

export const MESSAGE_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARN: "warn"
};

export const NOTIFY_GROUPS = {
  MSG: "msg"
};

export const SALE_TYPES = {
  CUT: 1, // 切仓
  BULK: 2 // 散卖
};

export const MIME_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

export const CRUISE_PRODUCT_TYPE = {
  PACK:'1',// 打包 (自己录的 例：江山如此多娇)
  API:'2',// api 船票（通过 api 获取）
};
export const CRUISE_CLASS = {
  DOMESTIC:'2',//国内河轮
  OVERSEAS:'3',//境外河轮
  OCEAN:'4',//海洋邮轮
  POLAR:'5',//极地邮轮
}
