// import { http } from "@/libs/axios.js";
import Vue from 'vue'

export const userApi = {
  UserSendCode: "/user/send-code", // 发送短信验证码
  UserCaptcha: "/user/captcha", // 获取图形验证码
  UserRegister: "/user/register", //注册
  UserLogin: "/user/login", // 用户登录
  UserInfo: "/user/info",
  AreaCode: "/user/get-mobile-prefix ", // 区号
};

// let config = {
//   baseURL: "/",
//   headers: { "Content-Type": "application/json" }
// };

// 获取短信验证码
export function getUserSendCode(data) {
  return Vue.$axios.$post(userApi.UserSendCode, { data });
}
// 保存注册信息
export function saveUserRegister(data) {
  return Vue.$axios.$post(userApi.UserRegister, { data });
}

// 获取用户登录
export function getUserLogin(data = {}, axios) {
  if(axios){
    console.log(axios.post)
    return axios.post(userApi.UserLogin, {data});
  }
  return Vue.$axios.$post(userApi.UserLogin, { data });
}

export function getUserInfo() {
  return Vue.$axios.$post(userApi.UserInfo);
}

// 获取区号
export function getAreaCode() {
  return Vue.$axios.$post(userApi.AreaCode);
}

//获取用户图形验证码
// export function getUserCaptcha() {
//   return http.get(userApi.UserCaptcha,config);
// }
