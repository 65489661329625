<template lang="">
    <div>
        <swiper ref="swiperRef" class="swiper-no-swiping" :class="$style.swiperBanner" v-bind="$attrs" v-on="$listeners" :options="swiperOptions">
            <swiper-slide>
              <Register @navigateToLogin="toNext" @success="$emit('register-success', $event)"></Register>
            </swiper-slide>
            <swiper-slide>
              <Login @navigateToSettle="toPrev" @success="$emit('login-success', $event)"></Login>
            </swiper-slide>
          </swiper>
    </div>
</template>
<script>
import {
	defineComponent,
	ref,
	useStore,
	computed
} from "@nuxtjs/composition-api";
import Login from "./Login.vue";
import Register from "./Register.vue";
export default defineComponent({
	name: "LoginSwiper",
	components: {
		Login,
		Register
	},
	emits: ['login-success', 'register-success'],
	setup(props, {emit}) {
		const store = useStore();
		const info = computed(() => {
			return store.state.distributorInfo;
		});
		const swiperRef = ref();
		function toPrev() {
			swiperRef.value.$swiper.slidePrev();
		}
		function toNext() {
			swiperRef.value.$swiper.slideNext();
		}

		const swiperOptions = {
			effect: "fade",
			// cubeEffect: {
			// 	slideShadows: false,
			// 	shadow: false,
			// 	shadowOffset: 0,
			// 	shadowScale: 1
			// }
		};
		return {
			info,

			swiperRef,
			swiperOptions,
			toPrev,
			toNext,
		};
	}
});
</script>
<style lang="scss" module>

.swiperBanner {
	width: 374px;
}

</style>