export const state = () => ({
  selectedUploadOrderData: [] //上传水单数组
});
export const mutations = {
  SETUPLOADORDER: (state, value) => {
    state.selectedUploadOrderData = value;
  }
};
export const actions = {
  setUploadOrder({ commit }, value) {
    commit("SETUPLOADORDER", value);
  }
};

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions
// };
