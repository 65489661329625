<template>
  <div class="app-root">
    <slot />
    <modal-login />
    <!-- <client-only>
            <v-msg />
        </client-only> -->
    <v-msg />
  </div>
</template>
<script>
import ModalLogin from "~/components/Login/index.vue";
import VMsg from "@/plugins/Notifications/VMsg";
export default {
	name: "AppRoot",
	components: {
		ModalLogin,
		VMsg
	},
  setup() {

    return {};
  }

};
</script>
<style lang="">


</style>