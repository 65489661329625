import { createGlobalState } from "@vueuse/shared"
import { reactive, ref, computed } from "@nuxtjs/composition-api";
import { useElementBounding } from "@vueuse/core";

const useLayout = createGlobalState(() => {
    const showAppFooter = ref(true);
    const headerRef = ref(null);
    const {height, width} = useElementBounding(headerRef)
    const headerSize = reactive({
        height,
        width
    })
    return { showAppFooter, headerRef, headerSize }
})
export default useLayout