<template>
    <VFloatButton v-if="visible || type === 'detail'"
      :icon_color="iconColor"
      :icon="icon"
      :title="title"
      @click="onClick"
      :color="color"
      :cursor="visible ? 'cursor-pointer' : 'cursor-not-allowed'"
      :background="background"
      :hover="type === 'detail' && visible ? 'hover:text-[#005EDC]' : 'hover:bg-white hover:bg-opacity-20'"
      :icon_hover="type === 'detail' && visible ? 'hover:text-[#005EDC]' : ''"
      :active="type === 'detail' && visible ? 'active:text-[#005EDC]' : ''"
    />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useWindowScroll } from '@vueuse/core';
import { moveToScrollTopAnimate } from '~/utils/getScrollTop';
import VFloatButton from './Button.vue'

export default defineComponent({
    name: 'VFloatButtonBackTop',
    components: {
        VFloatButton
    },
    props: {
        icon: {
            type: String,
            default: 'el-icon-top'
        },
        title: {
            type: String,
            default: '返回顶部'
        },
        visibilityHeight: {
            type: Number,
            default: 400
        },
        type: {
          type: String,
          default: 'default'
        },
        color: {
          type: String,
          default: "text-white"
        },
        background: {
          type: String,
          default: "bg-primary"
        },
    },
    setup(props) {
        // const [visible, setVisible] = useToggle(false);
        const { y: scrollTop } = useWindowScroll();
        const visible = computed(() => scrollTop.value > props.visibilityHeight)
        const iconColor = computed(() => {
          if(props.type === 'default') {
            return ''
          }else {
            if(visible.value) {
              return 'text-[#595959]'
            }else return 'text-[#C9CBD6]'
          }
        })

        function onClick() {
            console.log('onclick')
            moveToScrollTopAnimate();
        }
        return {
            visible,
            onClick,
            iconColor
        }
    }
})
</script>

<style lang="scss">

</style>
