<template>
    <app-root class="main-layout">
        <nuxt />
        <app-footer />
    </app-root>
</template>

<script>
import {  defineComponent } from "@nuxtjs/composition-api";

import AppRoot from "@/components/layouts/AppRoot.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
export default defineComponent({
    name: 'Topic',
    components: {
        AppRoot,
        AppFooter
    }
})
</script>

<style lang="scss" scoped>

</style>