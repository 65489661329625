var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-footer"},[_c('div',{staticClass:"main-width"},[_c('div',{staticClass:"app-footer-main"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"app-footer-box"},[_c('div',{staticClass:"box-title"},[_vm._v("帮助中心")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('p',[_c('span',{staticClass:"cursor-pointer",on:{"click":_setup.feedback}},[_vm._v("反馈与建议")])]),_vm._v(" "),_c('p',[_c('span',{staticClass:"cursor-pointer",on:{"click":_setup.onOpenChat}},[_vm._v("联系客服")])])]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4)])]),_vm._v(" "),_c('div',{staticClass:"award"}),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('p',{staticClass:"copyright"},[_vm._v("\n    Copyright ©"+_vm._s(_setup.yearDate)+" 上海乐船信息科技有限公司 All Rights Reserved\n    "),_c('span',[_vm._v("沪公网安备 31011302006367号")]),_c('span',[_vm._v("沪ICP备2020034120号-1")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-footer-box"},[_c('div',{staticClass:"box-title"},[_vm._v("加盟合作")]),_vm._v(" "),_c('p',[_vm._v("\n          邮箱："),_c('a',{attrs:{"href":"mailto:general@shangchuanba.com"}},[_vm._v("general@shangchuanba.com")])]),_vm._v(" "),_c('p',[_vm._v("电话：021-52991060（上海）")]),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"/settled"}},[_vm._v("立即成为合作伙伴")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-footer-box"},[_c('div',{staticClass:"box-title"},[_vm._v("关于我们")]),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"/introduce"}},[_vm._v("公司介绍")])]),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"/company/information"}},[_vm._v("邮轮资讯")])]),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"/cruise-map"}},[_vm._v("邮轮地图")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('a',{attrs:{"href":"/helpcenter"}},[_vm._v("注册与登录")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-footer-box"},[_c('div',{staticClass:"box-title"},[_vm._v("公司地址")]),_vm._v(" "),_c('p',[_vm._v("上海市静安区恒丰路610号金岸C栋105-108室")]),_vm._v(" "),_c('p',[_vm._v("北京市丰台区平安幸福中心A座21层")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-footer-qrcodes"},[_c('div',{staticClass:"qr-code text-center"},[_c('img',{staticClass:"code-img mx-auto",attrs:{"src":require("@/assets/img/global/footer/service-code.png"),"alt":""}}),_vm._v(" "),_c('p',{staticClass:"code-name"},[_vm._v("上船吧服务号")])]),_vm._v(" "),_c('div',{staticClass:"qr-code text-center"},[_c('img',{staticClass:"code-img mx-auto",attrs:{"src":require("@/assets/img/global/distribution-miniprogram-code.png"),"alt":""}}),_vm._v(" "),_c('p',{staticClass:"code-name"},[_vm._v("上船吧邮轮服务平台")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"license"},[_c('a',{attrs:{"href":"/partner/content/2"}},[_vm._v("经营许可证：L-SH-CJ00111")])])
}]

export { render, staticRenderFns }