export function upperFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function wrapInArray(v) {
  return v != null ? (Array.isArray(v) ? v : [v]) : [];
}

export const generateId = function() {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

export {
  chunk,
  get,
  set,
  cloneDeep,
  merge,
  groupBy,
  isEmpty,
  random,
  // seq
  lte,
  gt,
  gte,
  noop,
  omit,
  pick,
  // 计算
  sum,
  multiply,
  subtract,
  divide,
  head,
  toNumber,
  times,
  throttle,
  debounce,
  isEqual,
  uniqueId,
  last,
  isError,
  isNumber,
  capitalize,
  toString,
  join,
  mergeWith,
  flattenDeep,
  unset,
  template,
  slice
} from "lodash";


// export { throttle, debounce } from "throttle-debounce";

export * from "./num";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import objectSupport from "dayjs/plugin/objectSupport";
import "dayjs/locale/zh-cn";
// var duration = require("dayjs/plugin/duration");
dayjs.locale("zh-cn");
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(objectSupport);

export { dayjs };

export function getScreenWH() {
  return {
    w:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
    h:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
  };
}



export function isClient() {
  return process.client;
}


/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
