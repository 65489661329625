import { getHelpCenter } from "@/services/user";
import { REQUEST_CODES } from "@/constants/const-http";

export const state = () => ({
  serviceInfo: null, //用户信息
  helpList: null, //帮助中心
});
export const mutations = {
  SET_SERVICEINFO: (state, serviceInfo) => {
    state.serviceInfo = serviceInfo;
  },
  SET_HELPLIST: (state, helpList) => {
    state.helpList = helpList;
  },
};
export const actions = {
  async getHelpCenter({ commit }) {
    let { code, data, msg } = await getHelpCenter();
    if (code !== REQUEST_CODES.ERROR_OK) {
      throw new Error(msg);
    }
    commit("SET_SERVICEINFO", data.service);
    commit("SET_HELPLIST", data.help_list)
    return data;
  },
};
