/* eslint-disable */

/**
 * 浮点数加法
 *
 * @desc
 *   返回结果：arg1 + arg2
 *
 * @param
 *   arg1(Number|String): 加数
 *   arg2(Number|String): 加数
 *
 * @example
 *   var floatAdd = require('./utils/num/floatAdd');
 *   floatAdd(0.1, 0.2);
 *   // 结果为 0.3
 */

export function floatAdd(arg1, arg2) {
  var r1, r2, m;

  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2));
  return (floatMul(arg1, m) + floatMul(arg2, m)) / m;
}

/**
 * 浮点数除法
 *
 * @desc
 *   返回结果：arg1 / arg2
 *
 * @param
 *   arg1(Number|String): 被除数
 *   arg2(Number|String): 除数
 *
 * @example
 *   var floatDiv = require('./utils/num/floatDiv');
 *   floatDiv(0.3, 0.1);
 *   // 结果为 3
 */
export function floatDiv(arg1, arg2) {
  var t1,
    t2,
    a1 = arg1.toString(),
    a2 = arg2.toString();

  try {
    t1 = a1.split(".")[1].length;
  } catch (e) {
    t1 = 0;
  }
  try {
    t2 = a2.split(".")[1].length;
  } catch (e) {
    t2 = 0;
  }

  return (
    (Number(a1.replace(".", "")) / Number(a2.replace(".", ""))) *
    Math.pow(10, t2 - t1)
  );
}

/**
 * 浮点数乘法
 *
 * @desc
 *   JS的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。
 *   例如：Chrome下，1.255*100 结果为 125.4999999...
 *
 * @param
 *   arg1(Number|String): 乘数
 *   arg2(Number|String): 乘数
 *
 * @example
 *   floatMul(0.1, 0.2);
 *   // 结果为 0.02
 */

export function floatMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

/**
 * 浮点数减法
 *
 * @desc
 *   返回结果：arg1 - arg2
 *
 * @param
 *   arg1(Number|String): 被减数
 *   arg2(Number|String): 减数
 *
 * @example
 *   var floatSub = require('./utils/num/floatSub');
 *   floatSub(0.3, 0.1);
 *   // 结果为 0.2
 */
export function floatSub(arg1, arg2) {
  var r1, r2, m;

  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2));

  return (floatMul(arg1, m) - floatMul(arg2, m)) / m;
}

/**
 * 把 Number 四舍五入为指定小数位数的数字
 *
 * @desc
 *   提供正确的小数舍入方法
 *   Chrome下自带的方法，(1.255).toFixed(2) 结果为 1.25
 *
 * @param
 *   num(Number|String): 待舍入的数字
 *   len(Number|String): 小数保留位数
 *
 * @example
 *   var toFixed = require('./utils/num/floatToFixed');
 *   toFixed(1234.1234, 2);
 *   // 结果为 "1234.12"
 *
 */
export function floatToFixed(num, len) {
  if (typeof num === "undefined" || isNaN(Number(num))) {
    return "-";
  }
  if (typeof len === "undefined" || isNaN(Number(num))) {
    len = 0;
  }

  // 四舍五入
  var pow = Math.pow(10, Number(len));
  num = (Math.round(floatMul(Number(num), pow)) / pow).toString();

  // 如精度不足，末尾补零
  var dotIndex = num.indexOf(".");
  var decimals = dotIndex < 0 ? "" : num.substring(dotIndex + 1).length;
  if (decimals === "" && len !== 0) {
    num += ".";
  }
  if (decimals < len) {
    num += Math.pow(10, len - decimals)
      .toString()
      .substring(1);
  }

  return num;
}

/**
 * 数字格式化
 *
 * @desc
 *   整数部分加千分符，小数部分保留精度
 *
 * @param
 *   num(Number|String): 待格式化的数字
 *   len(Number|String): 小数部分保留位数
 *
 * @example
 *   var numFormat = require('./utils/num/format');
 *   numFormat(123456789.123456789, 3);
 *   // 结果为 "123,456,789.123"
 *
 */
export function format(num, len = 2) {
  if (typeof num === "undefined" || isNaN(Number(num))) {
    return "-";
  }
  if (typeof len === "undefined" || isNaN(Number(num))) {
    len = 2; // 默认保留两位小数
  }

  return separate(floatToFixed(Number(num), len), 3);
}

/**
 * 为数字整数部分添加分隔符
 *
 * @param
 *   num(Number|String): 待分隔的数字
 *   len(Number|String): 分隔步长，默认为3个数字
 *   sep(String): 分隔符，默认为半角逗号
 *
 * @example
 *   1. 为数字整数部分添加千分符：
 *      var separateNum = require('./utils/num/separate');
 *      separateNum(12345678);
 *      // 结果为 "12,345,678"
 *   2. 银行卡、手机号每隔4个字符添加一个连字符：
 *      var separateNum = require('./utils/num/separate');
 *      separateNum(18600112233, 4, "-");
 *      // 结果为 "186-0011-2233"
 *
 */
export function separate(num, len, sep) {
  if (typeof num === "undefined" || isNaN(Number(num))) {
    return "-";
  }
  if (typeof len === "undefined" || isNaN(Number(len)) || len < 1) {
    len = 3;
  }
  if (typeof sep === "undefined") {
    sep = ",";
  }

  num = num.toString().split(".");
  num[0] = num[0].replace(
    new RegExp("(\\d)(?=(\\d{" + len + "})+$)", "ig"),
    "$1" + sep
  );
  return num.join(".");
}
