import { ref,  useRoute,  watch } from "@nuxtjs/composition-api";
import { createEventHook } from "@vueuse/shared";

export default function useAppSearch() {
    const route = useRoute()
    const submitHook = createEventHook();
    const focusHook = createEventHook();
    const blurHook = createEventHook();

    const keyword = ref('');

    watch(() => route.value.query, (query = {}) => {
        const { q = '' } = query;
        if (q !== keyword.value) {
            keyword.value = q;
        }
    }, {
        immediate: true
    })

    const submit = (data) => {
        submitHook.trigger(data);
    }

    const focus = (data) => {
        focusHook.trigger(data)
    }

    const blur = (data) => {
        blurHook.trigger(data);
    }
    return {
        keyword,
        submitHook,
        submit,
        onSubmit: submitHook.on,

        focus,
        onFocus: focusHook.on,

        blur,
        onBlur: blurHook.on
    }
}