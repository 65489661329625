import { consola } from 'consola';
// import { random } from "@/utils/helpers";
import Vue from "vue";
import { Plugin } from '@nuxt/types'
import {NuxtAxiosInstance} from "@nuxtjs/axios";

// import { REQUEST_CODES } from "~/constants/const-http";

// function getToken(store){
//   console.log('getToken', store)
// }


export function Token() {}
Token.token = '';

// export class XAxios {
//   static $axios = null;
// }

declare module 'vue/types/vue' {
  interface VueConstructor {
    $axios: NuxtAxiosInstance
  }
}

const axiosPlugin: Plugin = ({ $axios, store, redirect, req,  ...rest }) => {
  Vue.$axios = $axios;
  Token.token = store.getters.token;

  $axios.onRequest((config) => {
  })
  $axios.onResponse(async (response) => {
    return response
  })

  $axios.onError((error) => {
    // @ts-ignore
    const code = parseInt(error.response && error.response.status);
    console.error(error)
    if (code === 400) {
      redirect("/400");
    }
  });
}
export default axiosPlugin
