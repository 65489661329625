import { defineNuxtMiddleware } from "@nuxtjs/composition-api";


export default defineNuxtMiddleware(function authenticated({$auth, redirect,  store, route}) {
    // console.log(route.path)
    // if(store.getters.loggedIn && store.getters.userInfo && !store.getters.isDistributor){
    //     // return redirect('/settled/process')
    //     if(route.path.search('/settled') < 0) {
    //         return redirect('/settled/process')
    //     }
    // }
})