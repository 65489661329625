import { render, staticRenderFns } from "./MaterrialFile.vue?vue&type=template&id=f02a9484&scoped=true&"
import script from "./MaterrialFile.vue?vue&type=script&lang=js&"
export * from "./MaterrialFile.vue?vue&type=script&lang=js&"
import style0 from "./MaterrialFile.vue?vue&type=style&index=0&id=f02a9484&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f02a9484",
  null
  
)

export default component.exports