<template functional>
  <section class="noData">
    <img
      class="block mx-auto"
      src="@/assets/img/global/common/icon-nodata.png"
    />
    <p class="noDataText">暂无数据...</p>
  </section>
</template>
<script>
export default {
  name: "VNoData"
};
</script>
<style lang="scss" scoped>

@import "~@/assets/scss/base/_color.scss";
.noData {
  min-height: 100px;
  text-align: center;
  margin: 30px 0 20px 0;
}
.noData {
  img {
    width: 180px;
  }
}
.noData .noDataText {
  font-size: 14px;
  font-weight: 400;
  color: $color-grey-5;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
}

</style>
