<template><!-- <div> -->
	<el-dialog :custom-class="$style.dialog" :visible="visible" @close="cancel" width="480px" height="auto" destroy-on-close>
		<div :class="$style.wrapper">
			<div>
				<Register v-if="section == 'register'" @navigateToLogin="section = 'login'" @success="onSuccess"></Register>
				<LoginModal @success="onSuccess" @navigateToSettle="section = 'register'" v-else></LoginModal>
			</div>
		</div>
	</el-dialog>
<!-- </div> --></template>
<script>
import useLoginDialog from "~/composables/useLoginDialog";
import LoginModal from "./Login.vue";
import Register from "./Register.vue";

import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
	name: "login-modal",
	components: {
		Register,
		LoginModal
	},
	setup() {
		const { visible, section, hide, cancel } = useLoginDialog();

		const onSuccess = () => {
			hide();
			window.location.reload();
		}
		return {
			section,
			visible,
			cancel,
			onSuccess
		}
	}
});
</script>
<style lang="scss" module>
.dialog {
  box-shadow: 0px 2px 20px 0px rgba(165, 172, 200, 0.43);
}
.dialog :global(.el-dialog__body) {
  padding: 0px;
}
.dialog :global(.el-dialog__header) {
  display: none;
}
</style>
