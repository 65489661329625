<template>
  <label class="v-radio" :class="classes">
    <span class="v-radio__input" :class="inputClasses">
      <span class="v-radio__inner ficon" :class="innerClasses" />
      <input
        class="v-radio__original"
        type="radio"
        :name="name"
        :disabled="disabled"
        v-model="inputValue"
        @focus="focus = true"
        @blur="focus = false"
        @change="handleChange"
      />
    </span>
    <span class="v-radio__label">
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "VRadio",
  props: {
    name: String,
    value: {
      required: true,
      default: null
    },
    val: {},
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    label: String,
    disabled: Boolean
  },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    classes() {
      return {
        "is-disabled": this.disabled,
        "is-checkbed": this.inputValue === this.val
      };
    },
    inputClasses() {
      return {
        "is-disabled": this.disabled,
        "is-checked": this.inputValue === this.val,
        "is-focus": this.focus
      };
    },
    innerClasses() {
      return {
        "ficon-radio-active": this.inputValue === this.val,
        "ficon-radio": this.inputValue !== this.val
      };
    },
    inputValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", this.val);
        return this.val;
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit("change", this.inputValue);
    }
  }
};
</script>

<style>

</style>
