import Axios from "axios";
import Vue from 'vue'

export default function DownloadByUrl(url, filename) {
  return Axios.get(url, {
    responseType: "blob",
    withCredentials: false
  }).then(res => {
    try {
      DownloadFile(res.data, filename);
    } catch (err) {
      return Promise.reject(err);
    }
  });
}
//post下载带token
export function DownloadByStreamingFile(url, data, filename, filetype) {
  return Vue.$axios({
    method: "post",
    url,
    data: { data },
    responseType: "blob",
    headers: {
      token: localStorage.getItem("auth._token.cookie") || ""
    }
  }).then(res => {
    try {
      DownloadFile(res.data, filename, filetype);
    } catch (err) {
      return Promise.reject(err);
    }
  });
}

export function DownloadFile(data, filename, mime, bom) {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}
//下载流文件
export function DownloadStreamingFile(data, file) {
  let url = window.URL.createObjectURL(
    new Blob([data, { type: "application/msword" }])
  );
  let link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
}

export function DownloadBase64File(contentBase64, fileName) {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
}



export function downloadImg(href, name) {
  let eleLink = document.createElement('a')
  eleLink.download = name
  eleLink.href = href
  eleLink.click()
  eleLink.remove()
}

// url http;://ww.data.png;  name a.png
export function downloadByBlob(url, name = Date.now()) {
  let image = new Image()
  image.setAttribute('crossOrigin', 'anonymous')
  image.src = url
  image.onload = () => {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    let ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob((blob) => {
      let url = URL.createObjectURL(blob)
      downloadImg(url, name)
      // 用完释放URL对象
      URL.revokeObjectURL(url)
    })
  }
}


export function downloadVideo(url,name, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.addEventListener("progress", function (obj) {
    if (obj.lengthComputable) {
      var percentComplete = obj.loaded / obj.total;
      console.log((percentComplete * 100).toFixed(2) + "%");
      // 可得到下载进度
      if(percentComplete == 1){
        callback && callback()
      }
    }
  }, false);
  xhr.responseType = 'blob'; // 设置返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let blob = this.response;
      // 转换一个blob链接
      let u = window.URL.createObjectURL(new Blob([blob], {
        type: 'video/mp4'
      }))
      let a = document.createElement('a');
      a.download = name || url; //这里是文件名称，这里暂时用链接代替，可以替换
      a.href = u;
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click();
      a.remove();
    }
  };
  xhr.send()
}

// export function downloadVideoWithOss(url, name) {
//   const OSS = require('ali-oss');

//   const client = new OSS({

//     region: "oss-cn-beijing",
//     accessKeyId: "LTAI5tAfMb4CGYXjccmu2vLq",
//     accessKeySecret: "XqBqx4WofL8trSSeVH4nWsDfGjO5N7",
//     bucket: "scbvideos",
    
//     // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
//     stsToken: 'yoursecurityToken',


//   });

//   // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
//   const filename = url
//   const response = {
//     'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
//   }
//   // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
//   const url = client.signatureUrl('exampleobject.txt', { response });
//   console.log(url);
// }