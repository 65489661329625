import { createGlobalState } from "@vueuse/core";
import { ref, onMounted } from "vue";

const useGQrcodeStore = createGlobalState(() => {
  const isShow = ref(true)
  const className = ref('')
  const elementTop = ref('')

  const getElementTop = (top) => {
    elementTop.value = top
  }

  const onShow = () => {
    if(isShow.value) {
      className.value = 'slide-fade'
      setTimeout(() => {
        isShow.value = !isShow.value
      }, 500);
    }else {
      className.value = ''
      isShow.value = !isShow.value
    }
  }

  return { isShow, onShow, className, getElementTop, elementTop }
})

export { useGQrcodeStore };
