<template>
  <app-root class="main-layout">
    <!-- <AppHeader /> -->
    <div :class="$style.main">
        <nuxt />
    </div>
  </app-root>
</template>

<script>
// import AppHeader from "@/components/layouts/AppHeader.vue";
// import AppLogo from "@/components/layouts/AppLogo.vue";
// import AppFooter from "@/components/layouts/AppFooter.vue";
// import AppMenu from "@/components/layouts/AppMenu.vue";
// import AppBanner from "@/components/layouts/AppBanner.vue";
// import AppWechat from "@/components/layouts/AppWechat.vue";
// import AppUser from "@/components/layouts/AppUser.vue";
import AppRoot from "@/components/layouts/AppRoot.vue";
// import AppDock from "@/components/layouts/AppDock.vue";
// import { getScrollTopPosition } from "@/utils/getScrollTop";

export default {
  name: "Fill",
  components: {
    AppRoot,
  },
};
</script>
<style lang="scss" module>

.main{
    height: 100vh;
}

</style>
