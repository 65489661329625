import { createGlobalState, useConfirmDialog } from '@vueuse/core'
import { ref } from "@nuxtjs/composition-api";



const useLoginDialog = createGlobalState(() => {
    const visible = ref(false);
    const {
        confirm,
        cancel,
        onConfirm,
        onCancel,
        reveal
    } = useConfirmDialog(visible);
    /** @type {import('vue').Ref<'login' |'register' >} */
    const section = ref('register');

    const show = () => reveal();
    const hide = () => cancel();
    
    const showLogin = () => {
        section.value = 'login';
        return reveal();
    }

    const showSettled = () => {
        section.value = 'register';
        return reveal();
    }

    return {
        visible,
        cancel,
        
        section,

        show,
        hide,

        showLogin,
        showSettled,


        onConfirm,
        onCancel
    }
})
export default useLoginDialog;
