<template>
  <div v-if="navs && navs.length" ref="headerRef" class="app-header">
    <div class="main-width">
      <AppTopbar/>
      <AppNav :navs="navs"/>
    </div>
  </div>
</template>
<script>
// import AppLogo from "@/components/layouts/AppLogo.vue";
// import AppMenu from "@/components/layouts/AppMenu.vue";
// import AppUser from "@/components/layouts/AppUser.vue";
import AppTopbar from '@/components/layouts/AppTopbar.vue'
import AppNav from '@/components/layouts/AppNav.vue'
import {computed, defineComponent, useFetch, useStore} from "@nuxtjs/composition-api";
import useLayout from '~/composables/useLayout'

import {useAsyncState} from "@vueuse/core";
import {ERROR_OK} from "~/constants/const-http";
import {getAppNavs} from "~/services/common";

export default defineComponent({
  name: "LayoutAppHeader",
  components: {
    // AppLogo,
    // AppMenu,
    // AppUser,
    AppTopbar,
    AppNav
  },
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    border: Boolean,
    headerClass: [String, Array, Object],
    placeholder: {
      // 是否占位
      type: Boolean,
      default: true
    }
  },
  setup() {
    const {headerRef} = useLayout();
    const store = useStore();
    const navs = computed(() => store.state.layout.navs);
    return {
      headerRef,
      navs
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    isGallery() {
      return this.$route.name == "company-cruise-gallery";
    }
  }
});
</script>
<style lang="scss">
.app-header {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-bottom-width: 1px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 99;
}
</style>
