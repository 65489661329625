<template>
  <notifications group="msg" position="top center" width="380">
    <template #body="{ item }">
      <div class="v-msg" :class="[`v-msg--${item.type}`]">
        <i class="ficon v-msg-icon" :class="[MAP_ICONS[item.type]]"></i>
        <span class="title">
          {{ item.text }}
        </span>
      </div>
    </template>
  </notifications>
</template>

<script>
const MAP_ICONS = {
  error: "ficon-shibai",
  warn: "ficon-tanhao",
  info: "ficon-tongguo",
  success: "ficon-tongguo"
};
export default {
  name: "v-msg",

  data() {
    return {
      MAP_ICONS
    };
  }
};
</script>
