import loadjs from "loadjs";
import { stringify } from "qs";
let isApiSetUp = false;
export default function loadBMapApi(options = {}) {
  if (isApiSetUp) {
    throw new Error("百度地图yi已经被设置过了");
  }
  isApiSetUp = true;
  const params = {
    v: "3.0",
    // type: 'webgl',
    callback: "_initBaiduMap",
    ...options,
  };
  loadjs(`https://api.map.baidu.com/api?${stringify(params)}`);
}
