<template>
  <Player
    theme="dark"
    v-bind="$attrs"
    ref="player"
  >
    <Video
      crossOrigin
      :poster="cover"
    >
      <source 
        :data-src="src" 
        type="video/mp4"
      />
      <!-- <track 
        default 
        kind="subtitles" 
        src="https://media.vimejs.com/subs/english.vtt" 
        srclang="en" 
        label="English" 
      /> -->
    </Video>

    <DefaultUi />
  </Player>
</template>

<script>
import { Player, Video, DefaultUi } from '@vime/vue';

export default {
  components: {
    Player,
    Video,
    DefaultUi,
  },
  props: {
      src: String,
      cover: String
  },
  methods:{
    play(){
      this.$refs.player.play()
    }
  }
};
</script>

<style lang="scss">

@import "@vime/core/themes/default.css";
@import "@vime/core/themes/light.css";

</style>