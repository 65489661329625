<template>
  <el-popover trigger="hover">
    <template #reference>
      <span
        class="ficon ficon-weixin1 icon block w-6 h-6 rounded-full text-2xl leading-none bg-white text-[#00c684]"
      />
    </template>
    <div class="text-center">
      <el-image
        class="code-img w-30 h-30"
        src="~@/assets/img/global/distribution-miniprogram-code.png"
      />
      <p class="text-primary text-center">微信扫一扫</p>
      <p class="text-muted">查看更多热门产品</p>
    </div>
  </el-popover>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>

.trigger {
  display: block;
  width: 26px;
  height: 26px;
  line-height: 1;
  border-radius: 9999px;
  background: #fff;
  font-size: 28px;
  color: #00c684;
  margin-right: 20px;
  cursor: pointer;
}

</style>
