<template>
    <div class="app-user">
        <div ref="userWrapRef" @mouseenter="isHoverUserWrap = true" @mouseleave="isHoverUserWrap = false"
            class="app-user-info">
            <div class="aui-heading">
                <i class="ficon ficon-wode" />
                <span class="aui-name">Hi,{{ userInfo.username }}</span>
                <i class="el-icon-caret-top" v-if="isHoverUserWrap" />
                <i class="el-icon-caret-bottom" v-else />
            </div>

            <Transition name="el-zoom-in-top">
                <div v-if="isHoverUserWrap" class="aui-pop">
                    <div class="aui-row">{{ userInfo.username }}</div>
                    <div class="aui-row mt-1">
                        <span>登陆账号:</span>
                        <span>{{ userInfo.login_name }}</span>
                    </div>
                    <div v-if="isDistributor" class="aui-company">
                        <span class="text-dark">{{ userInfo.brand_name }}</span>
                    </div>
                    <el-button class="w-full mt-5" type="primary" size="small" @click="logout">退出</el-button>
                </div>
            </Transition>
        </div>

        <el-divider v-show="isDistributor" direction="vertical" />

        <!-- 商家服务 -->
        <div v-show="isDistributor" ref="serviceRef" class="app-user-service z-1" @mouseenter="isHoverService = true"
            @mouseleave="isHoverService = false">
            <div class="aus-title">
                <span>商家服务</span>
                <i class="aus-title-arrow el-icon-caret-top" v-if="isHoverService" />
                <i class="aus-title-arrow el-icon-caret-bottom" v-else/>
            </div>
            <Transition name="el-zoom-in-top">
                <div v-if="isHoverService" class="aus-menu">
                    <div class="aus-menu-item">
                        <nuxt-link class="aus-menu-item-link" to="/resource">商家中心</nuxt-link>
                    </div>
                    <div class="aus-menu-item">
                        <nuxt-link class="aus-menu-item-link" to="/resource/order/platform">订单管理</nuxt-link>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { defineComponent, useStore, ref, computed, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'AppUser',
    setup() {
        const { $auth } = useContext();
        async function logout() {
            await $auth.logout();
            window.location.href = '/'
        }


        const isHoverUserWrap = ref(false);
        const isHoverService = ref(false);


        const store = useStore();
        const userInfo = computed(() => store.getters.userInfo || {});
        const isDistributor = computed(() => store.getters.isDistributor);

        return { userInfo, logout, isHoverUserWrap, isHoverService, isDistributor };
    }
})
</script>

<style lang="scss">
.app-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.app-user-info {
  cursor: pointer;
  position: relative;
}
.app-user-info .aui-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 4.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.app-user-info .aui-name {
  margin-left: 0.75rem;
  margin-right: 1rem;
}
.app-user-info .aui-company {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 242, 245, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.25rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
.app-user-info .aui-pop {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: -7.75rem;
  padding: 1.5rem;
  position: absolute;
  left: 50%;
  top: 100%;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  width: 15.5rem;
  z-index: 1;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
}
.app-user-service {
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
}
.app-user-service .aus-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 4.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
.app-user-service .aus-title-arrow {
  margin-left: 0.25rem;
}
.app-user-service .aus-menu {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  margin-left: -4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  left: 50%;
  top: 100%;
  width: 128px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.app-user-service .aus-menu-item-link {
  border-radius: 0.25rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
}
</style>
