<template>
	<div class="pannel animate__animated animate__fadeIn">
		<el-form ref="formRef" :model="formData" :rules="rulesBase" @submit.native.prevent="onLogin">
			<div class="form form--base">
				<el-form-item class="form__item" prop="username">
					<el-input autocomplete="on" placeholder="请输入账号" v-model="formData.username"></el-input>
				</el-form-item>
				<el-form-item class="form__item" prop="password">
					<el-input type="password" autocomplete="on" placeholder="请输入密码" v-model="formData.password"></el-input>
				</el-form-item>
			</div>
			<div class="form__tip">
				没有账号？
				<span @click="toSettle">马上入驻</span>
			</div>
			<el-button class="form__btn" type="primary" native-type="submit">登录</el-button>
		</el-form>

	</div>
</template>
<script>
import { defineComponent, ref, useStore, reactive, watch } from "@nuxtjs/composition-api";
import useMessage from "~/composables/useMessage";
import useLoginDialog from "~/composables/useLoginDialog";

export default defineComponent({
	name: 'LoginAccount',
	auth: "guest",
	emits: ["login-success", 'navigateToSettle', 'success'],

	setup(props, {
		emit
	}) {

		const { visible } = useLoginDialog();

		const formData = reactive({
			username: "", //用户名
			password: "" //密码
		})
		const rulesBase = {
			username: [
				{ required: true, message: "请输入账号", trigger: "blur" },
				{
					min: 6,
					max: 18,
					message: "长度在 6 到 18 个字符",
					trigger: "blur"
				}
			],
			password: [
				{ required: true, message: "请输入密码", trigger: "blur" },
				{
					min: 6,
					max: 18,
					message: "长度在 6 到 18 个字符",
					trigger: "blur"
				}
			]
		}

		const formRef = ref();
		const message = useMessage();
		const store = useStore();
		const onLogin = () => {
			formRef.value.validate(async (valid) => {
				if (!valid) {
					return;
				}
				try {
					const res = await store.dispatch("user/login", formData);
					emit("success", res);
				} catch (error) {
					console.error(error);
					message.error(error.message);
				}
			});
		}
		const resetFields = () => {
			formRef.value.resetFields();
		}
		const toSettle = () => {
			resetFields();
			emit("navigateToSettle");
		}


		watch(visible, (visible) => {
			if (!visible && formRef.value) {
				resetFields();
			}
		})

		return {
			formRef,
			formData,
			rulesBase,

			onLogin,
			resetFields,
			toSettle
		}
	}
});
</script>
<style lang="scss" scoped>

.form {
	&--base {
		padding-top: 40px;
	}

	&__item {
		margin-bottom: 42px;
	}

	&__tip {
		padding-top: 12px;
		color: #495057;
		font-size: 16px;
		text-align: center;
		margin-bottom: 30px;

		span {
			color: $color-primary;
			cursor: pointer;
			user-select: none;
		}
	}

	&__btn {
		width: 100%;
		font-size: 18px;
	}
}

</style>