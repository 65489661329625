
import bezierEasing from "@/utils/bezier-easing";
const bezierEasingValue = "0.25, 0.8, 0.5, 1";

let scrollAnimationFrame = null
const bezierEasingArray = bezierEasingValue.split(",")

/**
 * 获取滚动条顶部位置
 * @returns {number}
 */
export function getScrollTopPosition() {
  return (
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    document.body.scrollTop ||
    window.pageYOffset ||
    0
  );
}

// because it's so fucking difficult to detect the scrolling element, just move them all
export function moveToScrollTop(amount) {
  document.documentElement.scrollTop = amount;
  document.body.parentNode.scrollTop = amount;
  document.body.scrollTop = amount;
}


export function moveToScrollTopAnimate() {
  const scrollTop = getScrollTopPosition()

  let easing = bezierEasing(...bezierEasingArray);
  let startingTime = null;
  let duration = 600;
  const step = (currentTime) => {
    if (!startingTime) startingTime = currentTime;
    let progress = currentTime - startingTime;
    let progressPercentage = progress / duration;
    if (progress >= duration) progress = duration;
    if (progressPercentage >= 1) progressPercentage = 1;

    const perTick = scrollTop - easing(progressPercentage) * scrollTop;
    moveToScrollTop(perTick);
    if (progress < duration) {
      scrollAnimationFrame = window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}


