import { getAppNavs } from "~/services/common";
import {ERROR_OK} from "~/constants/const-http";

export const state = () => ({
    navs: []
})


export const mutations = {
    setNavs(state, navs = []) {
        state.navs = navs;
    }
}


export const actions = {
    async nuxtServerInit({dispatch}) {
        try{
            await dispatch('fetchAppNavs');
        }catch(error) {
            console.error(error);
        }
    },

    async fetchAppNavs({commit}) {
        const {code, data, msg} = await getAppNavs();
        if(code !== ERROR_OK){
            throw new Error(msg);
        }
        commit('setNavs', data);
    }
}