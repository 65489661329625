
import { defineComponent } from "@nuxtjs/composition-api";
// import type { VNode, VNodeChildren } from "@nuxtjs/composition-api";

export default defineComponent({
  //   name: "VFloatButton",
  functional: true,
  props: {
    // 确定是否展示分割线，往往可以确认第一个
    isFirst: Boolean,
    title: String,
    icon: String,
    popoverProps: {
      type: Object,
      default: () => ({}),
    },
    tag: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "text-white"
    },
    background: {
      type: String,
      default: "bg-primary"
    },
    icon_color: {
      type: String,
      default: "text-white"
    },
    cursor: {
      type: String,
      default: "cursor-pointer"
    },
    hover: {
      type: String,
      default: "hover:bg-white hover:bg-opacity-20"
    },
    icon_hover: {
      type: String,
      default: ""
    },
    active: {
      type: String,
      default: ""
    },
    icon_font_size: {
      type: String,
      default: "text-2xl"
    },
    margin_top: {
      type: String,
      default: "mt-1"
    }
  },
  render(h, { props, scopedSlots, data }) {
    const content = (
      <div class={`relative p-1 ${props.color} flex flex-col justify-center items-center rounded ${props.background} transition ${props.hover} ${props.active}`}>
      {/* <div class="relative p-1 text-white flex flex-col justify-center items-center rounded bg-primary transition hover:bg-white hover:bg-opacity-20"> */}
        {scopedSlots.icon ? (
          scopedSlots.icon({})
        ) : (
          <i class={`leading-none ${props.icon_font_size} ${props.icon} ${props.icon_color} ${props.icon_hover} ${props.active}`}></i>
        )}

        <div class={`text-xxs whitespace-nowrap ${props.margin_top}`}>
          {scopedSlots.title ? scopedSlots.title({}) : props.title}
        </div>
      </div>
    );

    const children = scopedSlots.popover ? (
      <el-popover
        class="block"
        props={props.popoverProps}
        scopedSlots={{
          default: scopedSlots.popover,
          reference: () => content,
        }}
      />
    ) : (
      content
    );
    return h(
      props.tag,
      {
        class: `relative ${props.background} ${props.cursor}  p-1 block w-full not-last:after:content-DEFAULT not-last:after:absolute not-last:after:inset-x-3 not-last:after:bottom-0 not-last:after:border-b not-last:after:border-white`,
        ...data,
      },
      [
        // <div class="absolute inset-3"></div>,
        children,

        // 第一个不要分割线
        // !props.isFirst ? (
        //   <div class="absolute inset-x-3 top-0 border-b border-white"></div>
        // ) : null,
      ]
    );
  },
});
