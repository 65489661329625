<template>
    <div class="app-menu-item" :class="{ 'app-menu-submenu': hasChildren, 'app-menu-item-active': isActive }"
        @mouseenter="hovering = true" @mouseleave="hovering = false">
        <div class="app-menu-item-content">
            <AppNavItemLink v-if="path" class="app-menu-item-link" :path="path" :target="target" :title="title">{{ title }}
            </AppNavItemLink>
            <span v-else class="app-menu-item-title">{{ title }}</span>
        </div>
        <transition name="el-zoom-in-top">
            <div v-show="hasChildren && hovering" class="app-menu-submenu-pop">
                <ul class="app-menu-submenu-list">
                    <li class="app-menu-submenu-item" v-for="(item, itemIndex) in children" :key="itemIndex">
                        <AppNavItemLink class="app-menu-submenu-item-link" :class="{
                            'app-menu-submenu-item-link-active': item.path === $route.fullPath
                        }" :path="item.path" :target="item.target"
                            :title="item.title">{{ item.title }}</AppNavItemLink>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, defineComponent, ref, useRouter, useRoute } from "@nuxtjs/composition-api";
import AppNavItemLink from './AppNavItemLink.vue';

export default defineComponent({
    name: 'AppNavItem',
    components: {
        AppNavItemLink
    },
    props: {
        title: String,
        path: String,
        target: String,
        children: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const hasChildren = computed(() => props.children && !!props.children.length)

        const hovering = ref(false);

        const route = useRoute();
        const router = useRouter();
        const isActive = computed(() => {
            const { fullPath: currentPath } = route.value;
            // if(props.path){
            //     return currentPath === props.path
            // }
            return currentPath === props.path || props.children.some((item) => {
                const { resolved } = router.resolve(item.path);
                return resolved.fullPath === currentPath;
            })
        })

        return {
            hasChildren,

            hovering,

            isActive

        }
    }
})
</script>

<style lang="scss">

</style>