import Vue from "vue";

// 获取所有素材分类
export function geMaterialClassify(data = {}) {
  return Vue.$axios.$post('member/material/classify', { data });
}

// 视频素材列表
export function geMaterialVideoList(data = {}) {
  return Vue.$axios.$post('member/material/video-list', { data });
}
// 产品文件素材列表
export function geMaterialFileList(data = {}) {
  return Vue.$axios.$post('member/material/file-list', { data });
}
// 图片素材列表
export function geMaterialImageList(data = {}) {
  return Vue.$axios.$post('member/material/image-list', { data });
}
