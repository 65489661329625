/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}
/**
 * add ￥
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addMoney(val) {
  if (isNaN(Number(val))) {
    return "￥0";
  } else {
    return "￥" + toThousandFilter(val);
  }
}
