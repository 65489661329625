<template>
  <app-root>
    <header :class="$style.appHeader">
      <div class="main-width">
        <div :class="$style.appHeader__inner">
          <app-logo :class="$style.logo" />
          <!-- <nav :class="$style.nav">
            <nuxt-link
              v-for="nav in navs"
              :key="nav.path"
              :class="$style.navItem"
              :exact-active-class="$style.isActive"
              :to="nav.path"
              >{{ nav.name }}</nuxt-link
            >
          </nav> -->
          <app-menu />
          <app-wechat class="ml-auto" />
          <app-user class="ml-10" />
        </div>
      </div>
    </header>
    <div class="flex">
      <div class="w-[128px].5 bg-white border-r">
        <nav :class="$style.nav">
          <nuxt-link v-for="nav in navs" :key="nav.path" :class="$style.navItem" :exact-active-class="$style.isActive"
            :to="nav.path">{{ nav.name }}</nuxt-link>
        </nav>
      </div>
      <div class="flex-1 relative">
        <nuxt />
      </div>
    </div>
  </app-root>
</template>
<script>
import AppRoot from "@/components/layouts/AppRoot.vue";
import AppLogo from "@/components/layouts/AppLogo.vue";
import AppMenu from "@/components/layouts/AppMenu.vue";
import AppWechat from "@/components/layouts/AppWechat.vue";
import AppUser from "@/components/layouts/AppUser.vue";
import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "Designer",
  components: {
    AppRoot,
    AppUser,
    AppLogo,
    AppMenu,
    AppWechat,
    AppUser,
  },
  setup() {
    const navs = [
      {
        name: "店铺首页",
        path: "/designer",
      },
      {
        name: "底部导航",
        path: "/designer/tabbar",
      },
      {
        name: "我的导航",
        path: "/designer/mine",
      },
      {
        name: "公众号信息",
        path: "/designer/offiaccount",
      },
      {
        name: "客户群",
        path: "/designer/customer",
      },
      {
        name: "自定义页面",
        path: "/designer/customization",
      }
    ];

    return {
      navs,
    };
  },
});
</script>
<style lang="scss" module>
.appHeader {
  z-index: 1;
  box-shadow: 0px 4px 6px 0px rgba(215, 215, 215, 0.39);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  position: relative;
}
.appHeader__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 74px;
}
.nav {
  padding: 0.75rem;
  padding-right: 0px;
}
.navItem {
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-width: 1px;
  border-left-width: 4px;
  border-right-width: 0px;
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.navItem.isActive {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgba(0, 94, 220, var(--tw-border-opacity));
  border-top-color: rgba(215, 218, 226, var(--tw-border-opacity));
  border-bottom-color: rgba(215, 218, 226, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.logo {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
</style>