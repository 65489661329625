import useRequest from "~/composables/useRequest";
import { createGlobalState, until, useConfirmDialog } from "@vueuse/core";
import { Ref, ref } from "vue";
// import { ref } from "@nuxtjs/composition-api";

import { getIndexKFList } from "@/services/common";
import { useContext, useStore } from "@nuxtjs/composition-api";



const useContactStore = createGlobalState(() => {

  // 客服列表
  const { state: list, isReady: isReadyList, execute: fetchList, isLoading: isLoadingList } = useRequest(getIndexKFList, [], {
    immediate: false
  });



  // 专属客服
  const visibleList = ref(false);

  // 专属客服
  const visibleExclusive = ref(false);

  // const setVisible = (value: boolean) => {
  //   visibleList.value = value;
  // };


  // 列表打开时
  until(visibleList).toBe(true).then(() => {
    if(!isReadyList.value){
      fetchList(0)
    }
  })



  const {
    store
  } = useContext();
  const open = () => {
    if(store.getters.loggedIn && store.getters.userInfo.sales_wechat_card){
      visibleExclusive.value = true;
    }else {
      visibleList.value = true;
      // openContractList();
    }
  }


  return {
    visibleList,
    // setVisible,
    list,

    visibleExclusive,

    open,

    isReadyList,
    isLoadingList,
    fetchList
  };
});

export { useContactStore };
