<template>
    <form action="/search" ref="searchFormRef" class="app-search" :class="{
        'is-focus': isFocus
    }" @submit.prevent="submit">
        <div class="app-search-input-wrap">
            <div class="app-search-input-icon">
                <i class="el-icon-search" />
            </div>
            <input :value="keyword" name="q" class="app-search-input" placeholder="邮轮｜产品｜资讯｜公司" autocomplete="off"
                @focus="focus" @blur="blur" @input="handleInput" />
            <el-button type="primary" round class="app-search-input-btn flex justify-center" native-type="submit">搜索</el-button>
        </div>
        <transition name="el-zoom-in-top">
            <div v-if="showPopover && suggestions.length && keyword" class="app-search-suggest">
                <el-scrollbar view-class="app-search-suggest-scroller">
                    <ul class="app-search-suggest-list">
                        <li v-for="(item, itemIndex) in suggestions" :key="itemIndex" class="app-search-suggest-item"
                            @click="onItemClick(item)">
                            <nuxt-link :to="item.url">{{ item.name }}</nuxt-link>
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
        </transition>
</form>
</template>

<script>
import { defineComponent, ref, useRouter } from "@nuxtjs/composition-api";
import { onClickOutside, refThrottled } from '@vueuse/core'
import { ERROR_OK } from "~/constants/const-http";
import { getSearchSuggestions } from "~/services/common";

import useAppSearch from '~/composables/useAppSearch';
import { throttle } from "lodash";

export default defineComponent({
    name: 'AppSearch',
    setup() {
        const router = useRouter();
        const {
            keyword,
            onSubmit,
            submit,

            focus,
            onFocus,

            blur,
            onBlur
        } = useAppSearch();

        // ======================= Focus =======================
        const isFocus = ref(false);
        onFocus(() => isFocus.value = true);
        onBlur(() => isFocus.value = false)

        // ======================= Suggestions =======================
        const suggestions = ref([]);
        const isLoading = ref(false);
        async function fetchSearchSuggestions(keyword) {
            isLoading.value = true;
            try {
                let { code, data, msg } = await getSearchSuggestions({ keyword });
                if (code !== ERROR_OK) {
                    throw new Error(msg)
                }

                suggestions.value = data;
            } catch (error) {
                console.error(error);
                suggestions.value = []
            } finally {
                isLoading.value = false;
            }
        }
   
        const throttleFetchSearchSuggestions = throttle(fetchSearchSuggestions, 300)
        const handleInput = (event) => {
            keyword.value = event.target.value;
            event.target.value && throttleFetchSearchSuggestions(keyword.value)
        }

        onFocus(() => {
            keyword.value && fetchSearchSuggestions(keyword.value);
        })


        // ======================= Popover =======================
        const showPopover = ref(false);
        onFocus(() => showPopover.value = true);
        const searchFormRef = ref();
        onClickOutside(searchFormRef, () => {
            showPopover.value = false;
        })


        // ======================= Search ======================= 
        onSubmit(() => {
            showPopover.value = false;
            if(!keyword.value){
                return
            }
            router.push({
                path: '/search',
                query: {
                    q: keyword.value
                }
            })
        })


        const onItemClick = (item) => {
            showPopover.value = false;
            keyword.value = item.keyword
            item.url && router.push(item.url);
        }
        return {
            keyword,

            handleInput,
            suggestions,
            onItemClick,

            isFocus,

            onFocus,
            onBlur,

            showPopover,
            searchFormRef,

            focus,
            submit,

            blur
        }
    }
})
</script>

<style lang="scss">
:root {
  --app-search-input-btn-width: 68px;
}
.app-search {
  position: relative;
  width: 35rem;
  z-index: 1;
}
.app-search-input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  height: 100%;
}
.app-search-input-wrap {
  border-radius: 9999px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 2.5rem;
  padding-right: 5rem;
  position: relative;
}
.app-search-input-wrap:after {
  content: '';
  border-radius: 9999px;
  border-width: 1px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
}
.app-search-input-icon {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-right: 0.5rem;
  margin-left: 0.75rem;
}
.app-search-input-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 68px;
  z-index: 1;
}
.app-search-suggest {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.app-search-suggest-scroller {
  max-height: 17.5rem;
}
.app-search-suggest-list {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.app-search-suggest-item {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
}
.app-search-suggest-item:last-child {
  margin-bottom: 0px;
}
.app-search-suggest-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.app-search-suggest-item:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.app-search.is-focus .app-search-input-wrap::after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
}
</style>